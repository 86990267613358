#purchaseorder {
    width: 80%;
    margin: auto;
    margin-top: 8rem;
    padding-bottom: 3rem;
}
.pagehead {
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
}
.subhead {
    margin-top: 2rem;
    line-height: 1.4rem;
}
.subhead p {
    color: black;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 2rem;
}
.mainsec {
    padding-left: 3%;
}
.fwbold {
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
}
.first {
    font-size: 0.9rem;
    margin-bottom: 20px;
    line-height: 1.4rem;
}
ol {
    padding-left: 7%;
}
li {
    margin-bottom: 10px;
}
