.testimonial-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 200px));
  column-gap: 20px;
  row-gap: 16px;
  grid-template-areas: "img details" "text text";
}

.testimonial-card__img {
  grid-area: img;
  display: block;
  width: 100%;
  min-width: 100px;
  height: auto;
  min-height: 100px;
  border-radius: 30px;
  object-fit: cover;
}

.testimonial-card__auth {
  grid-area: details;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial-card__auth h5 {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #212429;
  margin-bottom: 8px;
}

.testimonial-card__auth p {
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #495057;
  width: 7rem;
  word-break: break-word;
}

.testimonial-card__text {
  grid-area: text;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #495057;
  width: 27rem;
  word-break: break-word;
}

.testimonial-card__text::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  background-image: url("../../assets/vector/greyquotes.svg");
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media screen and (max-width: 600px) {
  .testimonial-card__text {
    width: 275px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 800px) {
  .testimonial-card {
    column-gap: 30px;
    row-gap: 32px;
    grid-template-areas: "img text" "img details";
    grid-template-columns: 2fr 3fr;
    margin-bottom: 42px;
  }

  .testimonial-card__img {
    grid-area: img;
  }

  .testimonial-card__auth {
    grid-area: details;
  }

  .testimonial-card__auth h5 {
    font-size: 28px;
    line-height: 30px;
  }

  .testimonial-card__auth p {
    font-size: 16px;
    line-height: 30px;
    width: 15rem;
    word-break: break-word;
  }

  .testimonial-card__text {
    grid-area: text;
    font-size: 18px;
    line-height: 30px;
  }

  .testimonial-card__-text::before {
    width: 30px;
    height: 30px;
  }
}
