.section-2 {
  padding: 50px 30px;
}

.section-2 .wrapper {
  /* max-width: 362px; */
  /* margin: 0 auto; */
  width: 100%;
}

.section-2__heading {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  color: #050302;
}

.section-2 .img-container {
  position: relative;
  overflow: hidden;
}

.section-2__img {
  width: 100%;
}

.section-2__img.mob {
  max-height: 260px;
}

.section-2__img.desk {
  display: block;
}

.section-2 .fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    #fff,
    hsla(0, 0%, 100%, 0) 20% 80%,
    #fff
  );
}

@media screen and (min-width: 576px) {
  .section-2 .wrapper {
    /* max-width: 765px; */
    max-height: 558px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
  }

  .section-2__heading,
  .section-2 .img-container {
    flex: 1;
  }

  .section-2__img.mob {
    display: none;
  }

  .section-2__img.desk {
    display: block;
  }

  .section-2 .fade {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .section-2 .wrapper {
    /* max-width: 1260px; */
    padding: 0 130px;
  }

  .section-2__heading {
    max-width: 500px;
    font-size: 40px;
    line-height: 130%;
    margin-bottom: 30px;
  }

  .section-2__img.mob {
    display: none;
  }

  .section-2__img.desk {
    display: block;
  }

  .section-2 .img-container {
    max-width: 550px;
  }
}
