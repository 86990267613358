.newsandmedia .card {
    width: 100% !important;
    text-align: left;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0,0,0, .3);
    overflow: hidden;
    transition: transform .3s cubic-bezier(.155,1.105,.295,1.12),box-shadow .3s,-webkit-transform .3s cubic-bezier(.155,1.105,.295,1.12);
}
.newsandmedia .card:hover {
    transform: scale3d(1.2,1.2,1.2);
}
.card-info {
    padding: 20px 30px;
}
.newsandmedia .card-info .heading-tertiary {
    margin-top: 20px;
    margin-bottom: 30px;
}
.newsandmedia .card img {
    width: 100%;
    background-size: cover;
    background-position: center;
}
.tag {
    display: inline-block;
    font-size: 14px;
    color: #777;
    background-color: #eee;
    border-radius: 7px;
    padding: 5px 10px;
}