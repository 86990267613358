/* First Section Start  */

.first-section {
  padding: 100px 135px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}
.highme {
  font-weight : 900;
}
.fontc {
  font-size: 22px !important;
  line-height: 130% !important;
}
.fontd {
  font-size: 15px !important;
  line-height: 160% !important;
}
.primary-section{
  display: flex;
  justify-content: space-between;
  gap: 95px;
}

.prod-detial-pad{
  padding-bottom: 20px !important;
}

.first-section .primary-section .left-md {
  max-width: 50%;
}

.first-section .center-md{
  max-width: 100%;
}

.first-section .primary-section .left-md h1, .first-section .center-md h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0em;
  margin-bottom: 20px;
}
.first-section .primary-section .left-md h2, .first-section .center-md h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  margin-bottom: 10px;
}
.first-section .primary-section .left-md h1 span, .first-section .center-md h1 span {
  color: rgba(34, 187, 102, 1);
}

.first-section .primary-section .left-md p, .first-section .center-md p {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  color: rgba(71, 74, 84, 1);
  margin-bottom: 25px;
  white-space: pre-line;
}
.first-section .primary-section .left-md .casestudy, .first-section .center-md .casestudy {
  font-weight: bold;
  font-size: 18px;
  line-height: 160%;
  margin-top: 20px;
  color: #474a54;
}

.first-section .primary-section .left-md .links, .first-section .center-md .links {
  display: flex;
  gap: 20px;
}

.first-section .primary-section .left-md .links a, .first-section .center-md .links a {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  border: 2px solid #22bb66;
  padding: 15px 25px;
  color: rgba(34, 187, 102, 1);
  border-radius: 8px;
  text-decoration: none !important;
}

.first-section .primary-section .left-md .links a.fill, .first-section .center-md .links a.fill {
  background-color: #22bb66;
  color: #fff;
}

.first-section .primary-section .right-md {
  max-width: 50%;
}
.first-section .primary-section .left-md img {
  width: 100%;
}
.first-section .primary-section .right-md img {
  width: 100%;
}

.quotes{
  position: relative;
}

.quotes::before{
  content: url('../../assets/vector/black-quotes.svg');
  position: absolute;
  top: -50px;
  left: 0;
}

.videoClass{
  height: 450px;
  width: 560px;
}

.community-image{
  display: flex !important;
  /* justify-content: center; */
  align-items: center;
}

.w-22ch{
  width: 22ch;
}

.w-15ch{
  width: 15ch;
}

.m-bottom-40{
  margin-bottom: 40px !important;
}

@media screen and (max-width: 1365px) {
  .first-section .primary-section .left-md .links a, .first-section .center-md .links a {
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 1023px) {
  /* .first-section .left-md img {
    margin-bottom: 30px;
  } */
  .first-section{
    padding: 100px 20px;
  }
  .prod-width{
    max-width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .mt-60{
    margin-top: 60px;
  }
  .order-1{
    order: 1;
  }
  .order-2{
    order: 2;
  }
  .order-3{
    order: 3;
  }
  .order-4{
    order: 4;
  }
  .order-5{
    order: 5;
  }
  .order-6{
    order: 6;
  }
  .first-section .primary-section .left-md h1, .first-section .center-md h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .first-section .primary-section .left-md p, .first-section .center-md p {
    font-size: 14px;
    line-height: 20px;
  }
  .first-section .primary-section .left-md,
  .first-section .primary-section .right-md,
  .first-section .center-md
  {
    max-width: 100%;
  }
  .first-section .primary-section .left-md h1, .first-section .center-md h1 {
    font-size: 25px;
    line-height: 35px;
  }
  .videoClass{
    height: 400px;
    width: 400px;
  }
}

@media screen and (max-width: 767px) {
  .first-section{
    padding: 100px 20px;
  }

  .first-section .primary-section .left-md, .first-section .center-md{
    display: flex;
    flex-direction: column;
  }
  

  .first-section .primary-section .left-md .links, .first-section .center-md .links{
    display: flex;
    flex-direction: row;
    order: 4;
  }

  .first-section .primary-section .left-md .links a, .first-section .center-md .links a{
    font-size: 14.4px;
    line-height: 16px;
    border-radius: 6.4px;
  }

  .quotes::before{
  content: url('../../assets/vector/black-quotes-down.svg');
  position: absolute;
  top: -50px;
  left: 0;
}

  .partners-heading{
    font-size: 20px !important;
    line-height: 32px !important;
    order: 2;
    margin-top: 50px;
  }

  .partners-image{
    order: 1;
  }

  .partners-desc{
    order: 3;
  }

  .videoClass{
    width: 100%;
    height: 100%;
  }
  .community-image{
    display: none !important;
  }

  .w-22ch{
  width: 100%;
}
}
