.switch-page {
    display: flex;
    max-width: 100%;
}
.pages {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 0 auto;
}
.page {
    font-size: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid #aaa;
    color: #aaa;
    margin: 0 10px;
}
.page-active {
    border: 3px solid var(--color-green);
    color: var(--color-green);
}
