/* #region  Core Team Section */
.section16 {
  padding: 40px 0;
  display: flex;
  /* overflow: hidden;
  white-space: nowrap; */
  align-items: center;
  justify-content: center;
}
.section16 .wrapper {
  display: flex;
  padding: 0 0px;
  width: 100%;
}
.section16 .investor_img {
  width: 20%;
  height: 25%;
  margin: auto;
}

.section16 .wrapper .row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}
.section16 .wrapper .row h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  display: flex;
  align-items: center;
  color: #050302;
}
.section16 .wrapper .row .hr {
  width: 56px;
  height: 2px;

  background: #22bb66;
  margin-top: 16px;
  margin-bottom: 25px;
}
.section16 .wrapper .row p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  white-space: pre-line;
  width: 50%;
  /* or 167% */

  text-align: justify;

  color: #474a54;
}
.section16 .wrapper .row .row1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.section16 .wrapper .row .row1 .col-md-2 {
  /* width: 16%; */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 215px;
  max-height: 200px;
}

.padding-t-60{
  padding-top: 60px;
}

.section16 .wrapper .row .row1 .col-md-2 img {
  width: 100%;
}

.investor-carousel{
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.padding-lr-88{
  padding: 0 88px;
}

.gap-20{
  gap: 20px;
}

.gap-30{
  gap: 30px;
}

.fl-justify-center{
  justify-content: center !important;
}

/* #endregion */

@media screen and (max-width: 1599px) {
  .investor-link{
    margin-top: 100px;
    background: #22BB66;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    border-radius: 8px;
    padding: 16px 40px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1023px) {
  .section16 .wrapper{
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .section16 .wrapper .row .row1 .col-md-2 {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scroll-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .padding-lr-88{
  padding: 0;
}
}

@media screen and (max-width: 574px) {
  .section16 {
    padding: 40px 20px;
  }
  .section16 .wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  .section16 .wrapper .row h1 {
    font-size: 30px;
  }
  .section16 .wrapper .row p {
    font-size: 12px;
  }
  .section16 .wrapper .row .row1 .col-md-2 img {
    margin-top: 20px;
  }
  .investor-link{
    background: #22BB66;
    color: #FFFFFF;
    font-size: 14.4px;
    line-height: 16px;
    font-weight: 600;
   border-radius: 6.4px;
    padding: 12px 22px;
    cursor: pointer;
  }

}

@media screen and (max-width: 479px) {
}
