.info {
    padding: 60px 135px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-justify-between {
    justify-content: space-between !important;
}

.flex-align-start{
  align-items: flex-start !important;
}

.info-odd,
.info:nth-child(odd) {
    background-color: #f4f5fa;
}

.info-primary {
    text-align: center;
    padding: 0 100px;
}

.padding-0{
  padding: 0;
}

.margin-tb-0{
  margin: 0;
}

.margin-t-20{
  margin: 20px 0 0;
}

.info-sec-primary {
    margin-right: 150px;
    width: 45%;
}

.info-grid {
    display: grid;
    width: 50%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}

.info-img {
    width: 50px;
    height: 50px;
    background-color: var(--color-grey-light);
    border-radius: 50%;
}