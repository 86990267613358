
/* 
Job row Start */
.job-row {width: 100%; background-color: #fff; box-shadow: 0px 6px 13px 4px rgba(71, 74, 84, 0.1); border-radius: 10px; padding: 30px; margin-bottom: 20px;}
.job-row span {font-size: 16px; font-weight: 700; line-height: 28px; color: rgba(34, 187, 102, 1);}
.job-row h3 {font-size: 20px; font-weight: 700; line-height: 25px; color: rgba(5, 3, 2, 1); margin-bottom: 20px;}
.job-row p {font-size: 16px; font-weight: 400; line-height: 28px; color: rgba(73, 80, 87, 1);}


/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width:767px) {
    .job-row h3 {margin-bottom: 10px;}
}
@media screen and (max-width:479px) {
    .job-row {padding: 15px; border-radius: 5px;}
    .job-row span, .job-row p {font-size: 14px; line-height: 18px;}
    .job-row h3 {font-size: 16px; line-height: 20px;}
}