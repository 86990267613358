@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.blogscontainer {
    padding-top: 2rem;
}
.blogscontainer .exl {
    font-family: 'Poppins',sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
    text-align: center;
}
.main2 {
    padding: 80px 80px 0px 80px;
}
.blogbox {
    padding: 0px 80px 40px 80px;
    background-color: rgba(34, 187, 102, 0.1);
}
.line3 {
    width: 80px;
    height: 5px;
    background-color: #22bb66;
    margin: auto;
    margin-bottom: 40px;
}
.sliderboxr {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
/* carousel */
#resource-section__carouselr {
    display: block;
  }
  
  .resource-section__carousel__containerr > * {
    margin-right: 30px;
  }
  
  .resource-section__carousel__prev-controlr,
  .resource-section__carousel__next-controlr {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
  }
  
  .resource-section__carousel__prev-controlr {
    left: 0;
  }
  
  .resource-section__carousel__next-controlr {
    right: 0;
  }
@media (max-width: 992px) {
    .resource-section__carousel__prev-controlr,
    .resource-section__carousel__next-controlr {
        position: absolute;
        width: 60px;
        height: 238px;
        top: -234px;
        z-index: 8;
    }
}
@media (max-width: 1500px) {
    .main2{
        padding: 50px;
    }
    .blogbox {
        padding: 0px 50px 40px 50px;
    }
}
@media (max-width: 1300px) {
    .main2{
        padding: 100px 80px;
    }
    .blogbox {
        padding: 0px 80px 40px 80px;
    }
}
@media (max-width: 1100px) {
    .main2{
        padding: 70px 40px;
    }
    .blogbox {
        padding: 0px 40px 40px 40px;
    }
}

@media (max-width: 1000px) {
    .main2{
        padding: 50px;
    }
    .blogbox {
        padding: 0px 30px 40px 30px;
    }
}
@media (max-width: 750px) {
    .main2{
        padding: 20px 50px;
    }
   
}
@media (max-width: 306px) {
    .main2 {
        padding: 20px 30px;
    }
   
}