.work-value {
  padding: 100px 0;
  background-color: #f4f5fa;
}

.work-value .wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 135px;
}

.work-value .wrapper .dot-grid-1,
.work-value .wrapper .dot-grid-2 {
  width: 94px;
  height: 82px;
  position: absolute;
  z-index: 1;
  background-image: url("../../assets/vector/dot-grid.svg");
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.work-value .wrapper:nth-child(6) {
  margin-left: auto;
}
.work-value .wrapper .dot-grid-1 {
  left: 520px;
  top: -20px;
}

.work-value .wrapper .dot-grid-2 {
  bottom: -20px;
  right: 113px;
}

.work-value .col-4 {
  max-width: 33.33%;
  padding: 15px;
  z-index: 1;
}

.work-value .box {
  background-color: #fff;
  padding: 30px 25px;
  box-shadow: 0px 0px 13px 4px rgba(144, 144, 144, 0.1);
  border-radius: 8px;
  margin-top: 0px;
  border-bottom: 10px solid rgba(34, 187, 102, 1);
}

.work-value h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  margin-bottom: 20px;
}

.work-value .line {
  width: 55px;
  height: 2px;
  background-color: rgba(34, 187, 102, 1);
  margin: 0px;
  margin-bottom: 24px;
}

.work-value .col-4 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}

.work-value h1 span {
  color: rgba(34, 187, 102, 1);
  display: block;
}

.work-value .box span {
  display: inline-block;
  width: 48px;
  height: 48px;
  background: #474a54;
  border-radius: 50%;
}

.work-value .box h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 10px 0;
}

.work-value .box p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(73, 80, 87, 1);
  margin-bottom: 10px;
}

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
  .work-value h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .work-value .box h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .work-value .box p,
  .work-value p {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1023px) {
  .work-value h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .work-value {
    padding: 50px 20px;
  }
  .work-value .line {
    margin-bottom: 15px;
  }
  .work-value .col-4 {
    max-width: 50%;
  }
  .work-value .wrapper {
    padding: 0;
  }
  .work-value .wrapper .dot-img1 {
    left: calc(50% - 20px);
    width: 100px;
    top: -15px;
  }
  .work-value .wrapper .dot-img2 {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .work-value p {
    display: none;
  }
  .work-value .box p {
    display: block;
  }
  .work-value .col-4 {
    max-width: 100%;
  }
  .work-value {
    padding: 50px 100px;
  }
  .work-value .box {
    width: 90%;
  }
  .work-value .col-4:nth-child(odd) .box {
    margin-left: auto;
  }
  .work-value .wrapper .dot-img1 {
    left: auto;
    right: 0;
    top: 100px;
  }
  .wrapper.third-section {
    padding: 50px 100px;
  }
}

@media screen and (max-width: 574px) {
  .work-value {
    padding: 40px 30px;
  }

  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
  .work-value .wrapper .dot-grid-1 {
    left: -25px;
    top: 115px;
  }
}

@media screen and (max-width: 479px) {
}
