.nav__menu{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 0 30px;
}

.nav__link {
  margin: 0 0.5rem;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: var(--gray-100);
  opacity: 0.8;
  text-decoration: none;
  position: relative;
  width: 100%;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

.nav_arrow::after {
  content: url('../../../assets/vector/nav_expand_arrow.svg');
  position: absolute;
  right: 0;
}

.rotate::after{
  content: url('../../../assets/vector/nav_expand_arrow.svg');
  position: absolute;
  right: 0;
  transform: rotate(180deg);
}

.nav__link.selected,
.nav__link:hover {
  opacity: 1;
  border-bottom: 2px solid white;
}

@media screen and (min-width: 768px) {
  .nav__menu{
    padding: 0 200px;
  }
}

/* nav desktop menu */
@media screen and (min-width: 1024px) {
  .nav__menu {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  margin-right: 16px;
  gap: 0;
  padding: 0;
}

.nav__link {
  margin: 0 0.5rem;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: var(--gray-100);
  opacity: 0.8;
  text-decoration: none;
  text-align: center;
  width: fit-content;
}

.nav__link.selected,
.nav__link:hover {
  opacity: 1;
}

.nav_arrow::after{
  display: none;
}
.rotate::after{
  display: none;
}
}
