.box {
    height: 155vh;
    padding: 10rem;
    padding-top: 2rem;
}
.name {
    background-color: rgba(34, 187, 102, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-bottom: 4rem;
    padding: 2rem;
    gap: 1rem;
}
.name img {
    height: 34px;
    width: 34px;
}
.name p {
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    color: #000;
}
@media screen and (max-width:1240px) {
   .box {
       padding: 4rem;
       height: 200vh;
   }
}
@media screen and (max-width:469px) {
    .box {
        height: 188vh;
    }
    .name p {
        font-size: 1rem;
    }
}
@media screen and (max-width:750px) {
    .name p {
        font-size: 1rem;
    }
}
@media screen and (max-width:350px) {
    .box {
       height: 195vh;
    }
}
@media screen and (max-width:420px) {
    .box {
       padding: 2rem;
    }
}