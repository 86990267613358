.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}
.st {
    font-weight: bold;
    color: black;
}