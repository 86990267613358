.comment {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 20px;
    padding: 25px 0;
}
.comment:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.comment--user__img {
    width: 60px;
    height: 60px;
    background-color: var(--color-grey-light);
    border-radius: 50%;
}

.comment--user__name {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}
.comment--date {
    font-size: 16px;
    font-weight: 400;
    color: #999;
    margin: 0 0 10px 0;
}
.comment--text {
    font-size: 18px;
    color: var(--color-grey);
}

@media (max-width: 500px) {
    .comment--user__img {
        width: 50px;
        height: 50px;
    }
}