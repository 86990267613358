/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap"); /* Mulish */
@import url("https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap"); /* Asap */

.page {
    padding: 4rem 2rem 0 0;
}
.page2 {
    padding: 2rem 2rem 0 0;
}
.heading {
    width: 80%;
    margin: auto;
    margin-bottom: 2rem;
}
.content {
   width: 80%;
   margin : auto;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}
.card {
    background-color: white;
    box-shadow: 0px 0px 9.26365px 2.85036px rgb(71 74 84 / 10%);
    border-radius: 5.70071px;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 45%;
    margin-right: 3rem;
    display: flex;
    flex-direction: row;
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}
.card:hover {
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}
.box {
    display: flex;
    flex-direction: column;
}
.logobox {
    /* width: 60%; */
    margin-right: 2vw;
    height: 100%;
    display: flex;
    align-items: center;
}
.carddes {
    margin-bottom: 1rem;
    font-size: 1rem;
}
.cardtitle {
    margin-bottom: 1rem;
    font-size: 1.2rem;
}
.cardlink {
    height: 0.6rem;
    margin-left: 1rem;
}
.logo {
    height: 17vh;
}
@media screen and (max-width : 1200px){
    .content {
        display: flex;
        flex-direction: column;
    }
    .card {
        width: 100% !important;
    }
}
@media screen and (max-width:714px) {
    .card {
        display: flex;
        flex-direction: column;
    }
    .logobox {
        margin-right: 0vw;
        margin-bottom: 4vh;
    }
}