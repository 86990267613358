.nav--bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    padding: 0 300px;
    box-shadow: 0 5px 20px rgba(0,0,0,.1);
}
.nav--item {
    font-size: 16px;
    color: var(--color-grey-light);
    text-align: center;
    text-decoration: none;
    padding: 20px 0;
    cursor: pointer;
}

.active,
.nav--item:hover {
    color: var(--color-grey);
}