.dataandsecurity .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 235px;
    text-align: center;
}
.dataandsecurity .home-img {
    width: 100%;
    height: 500px;
    /* height: 680px; */
    /* background-color: var(--color-grey-light); */
    border-radius: 20px;
    margin: 60px 0;
}
.dataandsecurity .home-img img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.dataandsecurity .home-logos {
    width: 500px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0;
}
.dataandsecurity .home-logo {
    height: 100%;
}