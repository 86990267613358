/* #region  Last Section */

.last-section {
  padding: 100px 0;
  display: flex;
}
.last-section .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 135px;
}
.last-section .wrapper .col-md-6 {
  width: 50%;
  padding: 15px;
  text-align: center;
}

.last-section .wrapper .box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding: 60px 50px;
  border-radius: 10px;
}

.last-section .wrapper .box.img1 {
  background-image: url(../../assets/Logos_Pictures/Check_out_our_Work_culture_1.png);
  background-position: center;
}

.last-section .wrapper .box.img2 {
  background-image: url(../../assets/Logos_Pictures/Know_more_about_artivatic_1.png);
  background-position: center;
}

.last-section .box h2 {
  font-size: 38px;
  font-weight: 700;
  line-height: 49px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
}

.black-text{
  color: #000 !important;
}

.last-section .box a {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  border: 2px solid #fff;
  padding: 15px 25px;
  color: #000;
  border-radius: 8px;
  text-decoration: none !important;
  background-color: #fff;
  display: inline-block;
  margin-top: 20px;
}

.last-section .box .line {
  width: 55px;
  height: 2px;
  background-color: #fff;
  margin: 20px auto;
}
/* #endregion */

@media screen and (max-width: 1024px) {
  .last-section .wrapper{
    padding: 0 20px;
  }
  .last-section .wrapper .col-md-6{
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .last-section {
    padding: 0px 20px 45px;
  }

  .inv-sec{
    padding: 0px 20px 90px !important;
  }
  .last-section .wrapper {
    padding: 0px;
    grid-gap: 20px;
  }
  .last-section .wrapper .box.img1, .last-section .wrapper .box.img2{
    background-position: center;
  }
  .last-section .wrapper .box.img2{
    margin: 0;
  }
  .last-section .box a {
    font-size: 14.4px;
    line-height: 16px;
    padding: 12px 12px;
  }
  .last-section .wrapper .col-md-6 {
    padding: 0px;
  }

  .inv-img{
    margin-top: 60px !important;
  }
}

@media screen and (max-width: 574px) {
  
  .last-section .box h2 {
    font-size: 24px;
    line-height: 26px;
  }
  .last-section .wrapper .col-md-6 {
    width: 100%;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
}
