/* #region  First Section */
.first-section {
  padding: 100px 20px;
  gap: 20px;
  justify-content: space-between;
}
.first-section .wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.first-section .wrapper .row {
  width: 100%;
}
.first-section .wrapper .left-md {
  max-width: 50%;
}

.first-section .wrapper .left-md h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0em;
  margin-bottom: 20px;
}

.first-section .wrapper .left-md h1 span {
  color: rgba(34, 187, 102, 1);
}

.first-section .wrapper .left-md p {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  color: rgba(71, 74, 84, 1);
  margin-bottom: 25px;
}

.first-section .wrapper .left-md .links {
  display: flex;
  gap: 20px;
}

.first-section .wrapper .left-md .links a {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  border: 2px solid #22bb66;
  padding: 15px 25px;
  color: rgba(34, 187, 102, 1);
  border-radius: 8px;
  text-decoration: none !important;
}

.first-section .wrapper .left-md .links a.fill {
  background-color: #22bb66;
  color: #fff;
}

.first-section .wrapper .right-md {
  max-width: 50%;
  padding-left: 75px;
}
/* #endregion */

/* #region  Third Section */
.third-section {
  padding: 100px 0px;
}

.third-section .row {
  display: flex;
  flex-wrap: wrap;
}

.third-section .wrapper{
  padding: 0 135px;
}

.third-section .col {
  padding: 15px;
}

.third-section .col.md-5 {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: flex-end;
}

.third-section .col.md-7 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.third-section .col.md-7 .height {
  height: 30px;
  width: 100%;
}

.third-section h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 80px;
}

.third-section .col img {
  width: 100%;
}

.third-section h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}

.third-section h3 span {
  color: rgba(34, 187, 102, 1);
}

.third-section .col.md-12 {
  width: 100%;
  padding-top: 80px;
}
/* #endregion */

/* #region  Benifits Section */
.benefits {
  padding: 100px 0px;
  background-color: #f4f5fa;
}

.benefits .wrapper{
  padding: 0 135px;
}

.benefits .row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  justify-content: center;
}

.benefits h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  margin-bottom: 20px;
  text-align: center;
}

.benefits .line {
  width: 55px;
  height: 2px;
  background-color: rgba(34, 187, 102, 1);
  margin: auto;
  margin-bottom: 30px;
}

.benefits p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: rgba(71, 74, 84, 1);
  max-width: 800px;
  margin: auto;
}

.benefits .col.md-4 {
  width: 33.33%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
}

.benefits .col.md-4 span {
  background: #22bb66;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.benefits .col.md-4 h3 {
  color: rgba(5, 3, 2, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.benefits .col.md-4 p {
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  color: rgba(71, 74, 84, 1);
}

.core-team .wrapper .col-md-4{
  display: inline-flex;
}

.core-team .wrapper .card{
  min-height: 356px;
}

.work-value .box{
  min-height: 395px;
}
/* #endregion */

/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
  .first-section .left-md h1,
  .work-value h1,
  .third-section h1,
  .benefits h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .first-section .left-md p {
    font-size: 16px;
    line-height: 25px;
  }
  .first-section .left-md .links a {
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 4px;
  }
  .first-section {
    align-items: center;
  }

  .third-section h3 {
    font-size: 25px;
    line-height: 34px;
  }
  .benefits .col.md-4 span {
    width: 70px;
    height: 70px;
  }
  .benefits .col.md-4 p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .first-section .left-md h1,
  .work-value h1,
  .third-section h1,
  .benefits h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .first-section.wrapper {
    padding: 50px 20px;
  }
  .first-section .right-md img {
    width: 100%;
  }
  .first-section .left-md p {
    font-size: 14px;
    line-height: 20px;
  }
  .first-section .left-md .links {
    flex-direction: row;
    align-items: flex-start;
  }
  .work-value {
    padding: 50px 20px;
  }
  .work-value .line {
    margin-bottom: 15px;
  }
  .work-value .col-4 {
    max-width: 50%;
  }
  .work-value .wrapper {
    padding: 0;
  }
  .work-value .wrapper .dot-img1 {
    left: calc(50% - 20px);
    width: 100px;
    top: -15px;
  }
  .work-value .wrapper .dot-img2 {
    width: 100px;
  }
  .third-section .wrapper{
    padding: 0 20px;
  }
  .third-section h3 {
    font-size: 20px;
    line-height: 27px;
  }
  .third-section .col.md-12 {
    padding-top: 40px;
  }
  .benefits .wrapper{
    padding: 0 20px;
  }
  .benefits .col.md-4 {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .first-section .wrapper {
    flex-direction: column;
    padding: 50px 100px;
    gap: 60px;
  }
  .first-section .wrapper .left-md,
  .first-section .wrapper .right-md {
    max-width: 100%;
  }
  .work-value p {
    display: none;
  }
  .work-value .box p {
    display: block;
  }
  .work-value .col-4 {
    max-width: 100%;
  }
  .work-value {
    padding: 50px 100px;
  }
  .work-value .box {
    width: 90%;
  }
  .work-value .col-4:nth-child(odd) .box {
    margin-left: auto;
  }
  .work-value .wrapper .dot-img1 {
    left: auto;
    right: 0;
    top: 100px;
  }
  .wrapper.third-section {
    padding: 50px 100px;
  }
  .third-section .row {
    flex-direction: column;
  }
  .third-section .col.md-5,
  .third-section .col.md-7 {
    width: 100%;
  }
  .third-section .col.md-7 .height {
    height: 0;
  }
  .benefits {
    padding: 50px 100px;
  }
  .benefits .col.md-4 {
    width: 100%;
  }
  .benefits p {
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (max-width: 574px) {
  .first-section {
    padding: 30px;
  }
  .first-section .wrapper {
    padding: 10px 0px;
  }
  .first-section .wrapper .left-md h1 {
    font-size: 30px;
    line-height: 130%;
  }
  .first-section .wrapper .right-md {
    padding-left: 0px;
    margin-bottom: 20px;
  }
  .work-value {
    padding: 40px 30px;
  }
  .wrapper.third-section {
    padding: 20px 30px;
  }
  .benefits {
    padding: 20px 30px;
  }

  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
  .work-value .wrapper .dot-grid-1 {
    left: -25px;
    top: 115px;
  }
  .core-team .wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  .core-team__carousel__container > * {
    margin-right: 0px;
  }

  .core-team__carousel__prev-control,
  .core-team__carousel__next-control {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
    transform: translateY(-150px);
  }

  .core-team__carousel__prev-control {
    left: 0;
  }

  .core-team__carousel__next-control {
    right: 0;
  }

  .core-team .wrapper .col-md-4 {
    width: 80%;
  }
  .core-team .wrapper .col-md-4 .card {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 479px) {
}
