.row {
    display: flex;
    flex-wrap: wrap;
}
a {text-decoration: none;}


.job-details {width: 100%; padding: 100px 335px;}
.job-details h1 {font-size: 40px; font-weight: 700; line-height: 52px; margin-bottom: 20px; text-align: center;}
.job-details .line {width: 60px; height: 2px; background: rgba(172, 181, 189, 1);}

.box {padding: 40px; margin-top: 50px; border: 1px solid #ACB5BD; border-radius: 12px;}
.box h6 {font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(0, 0, 0, 1); margin-top: 20px;}
.box .col-md {padding: 15px 0;}
.box label {font-size: 16px; font-weight: 700; line-height: 26px; color: rgba(0, 0, 0, 1); display: block; margin-bottom: 10px;}
.box input {padding: 15px;border: 1px solid #C4C4C4; border-radius: 12px; width: 100%;}
.box textarea {padding: 15px; border: 1px solid #C4C4C4; border-radius: 12px; width: 100%;}

.upload-btn-wrapper {position: relative; overflow: hidden; display: inline-block;}
.upload-btn-wrapper button {padding: 10px 15px;border: 1px solid #C4C4C4; border-radius: 12px; width: 100%; color: rgba(73, 80, 87, 1); display: flex; gap: 10px;  align-items: center;}
.upload-btn-wrapper input[type=file] {font-size: 100px; position: absolute; left: 0; top: 0; opacity: 0;}

.applyjob2{text-align: center;}
.applyjob2 a {font-size: 18px; font-weight: 600; line-height: 20px; letter-spacing: 0em; border: 2px solid #22BB66; padding: 15px 25px; background-color: rgba(34, 187, 102, 1); color: #fff; border-radius: 8px;    text-decoration: none !important; display: inline-block;  margin-top: 30px;}
/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width:1365px) {}

@media screen and (max-width:1023px) {
    .job-details h1 {font-size: 35px; line-height: 45px;}
    .job-details {padding: 40px;}
}

@media screen and (max-width:767px) {
    .box {padding: 30px;}
    .box input {padding: 8px; border-radius: 8px;}
}

@media screen and (max-width:574px) {
    .job-details h1  {font-size: 30px; line-height: 35px;}
    .applyjob2 a {font-size: 16px; padding: 12px 20px;}
}

@media screen and (max-width:479px) {
    .job-details {padding: 20px;}
    .box { padding: 20px;}
    .box h6 {margin-top: 10px;}
    .box .col-md { padding: 8px 0;}
}