.section13 .section-13 {
  background-color: #e9f8f0;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 574px) {
  .section13 .section-13 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
