.nav__sub-grid{
  display: none;
}

.nav__sub-grid .container{
  display: none;
}

.nav__sub-grid .content{
  display: none;
}

@media screen and (min-width: 768px) {
  .nav__sub-grid {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 60px;
  display: block;
}

.nav__sub-grid .container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  margin-top: 10px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: var(--vl-blue);
}

.nav__sub-grid .content {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  column-gap: 20px;
  row-gap: 10px;
  padding: 10px 0;
}

}