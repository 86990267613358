.logo-marquee-container{
  width: 100%;
  /* height: 300px; */
  overflow: hidden;
  /* margin: 40px 0 0; */
}
.logo-marquee-wrapper{
  display: flex;
  flex-flow: row;
  /* position: relative;
  left: -700px;
  right: -700px;
  height: 200px;
  width: 150%; */
  align-items: center;
}

.logo-box{
  /* position: absolute; */
  /* height: 200px; */
  /* z-index: 0; */
  height: 80px;
  width: 200px;
}
.marquee-container {
  overflow-x: unset !important;
}

.logo-box img{
  width: 100%;
  height: 100%;
}

.logo-boxes{
  position: relative;
  left: -700px;
  height: 200px;
  width: 0%;
  display: flex;
  justify-content: center;
  gap: 0;
}

.logo-marquee .marquee{
  gap: 100px;
}

.horizontal-logo{
  flex-direction: row;
}

.vertical-logo{
  flex-direction: column;
}

.marquee-margin-top-40{
  margin: 40px 0 0;
}

.marquee-margin-bottom-40{
  margin: 0 0 40px;
}