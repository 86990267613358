.boxcard .grid-card {
  display: none;
  position: relative;
  z-index: 2;
  max-width: 350px;
  margin-bottom: 20px;
  padding: 22.84px 22.83px;
  background-color: white;
  box-shadow: 0px 0px 9.26365px 2.85036px rgba(71, 74, 84, 0.1);
  border-radius: 5.70071px;
}

.boxcard .grid-card.mob {
  display: block;
}

.boxcard .grid-card img {
  margin-bottom: 6.42px;
  width: 34.25px;
  height: 34.26px;
}

.boxcard .grid-card h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 17.1021px;
  line-height: 110%;
  color: var(--text-dark);
}

.boxcard .grid-card p {
  margin-bottom: 8.57px;
  font-weight: normal;
  font-size: 11.4014px;
  line-height: 175%;
  color: var(--text-lightGray);
}

.boxcard .grid-card button {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 11.4014px;
  line-height: 175%;
  color: var(--primary);
  background-color: transparent;
}
.boxcard .contactno-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.boxcard .contactno {
  display: flex;
  width: 100%;
  font-size: 11px;
  line-height: 20px;
  align-items: center;

  color: #495057;

  mix-blend-mode: normal;
}
.boxcard .grid-card button::after {
  content: "";
  display: block;
  width: 10.09px;
  height: 6.42px;
  margin-left: 6px;
  background-image: url("../../assets/vector/green-arrow-right.svg");
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 500px) {
  .boxcard .marginleft {
    margin-left: 60px !important;
  }
  .boxcard .marginright {
    margin-right: 60px !important;
  }
}
@media screen and (min-width: 768px) {
  .boxcard {
    display: flex;
    justify-content: center;
  }

  .boxcard .grid-card {
    display: block;
    margin: 0;
  }
}

@media screen and (min-width: 1050px) {
  .boxcard .grid-card {
    max-width: 570px;
    height: 390px;
    padding: 32px;
  }

  .boxcard .grid-card img {
    width: 48px;
    height: 48px;
    margin-bottom: 9px;
  }

  .boxcard .grid-card h2 {
    font-size: 24px;
    line-height: 110%;
    margin-bottom: 8px;
  }

  .boxcard .grid-card p {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .boxcard .grid-card button {
    font-size: 16px;
    margin-top: 24px;
  }

  .boxcard .grid-card button::after {
    width: 14.14px;
    height: 9px;
    margin-left: 8px;
  }
  .boxcard .contactno {
    width: 50%;
    margin-top: 15px;
    font-size: 16px;
    line-height: 28px;
  }
}
