.resource-section {
  padding: 50px 30px;
  background-color: rgba(34, 187, 102, 0.1);
  overflow: hidden;
}

.resource-section .wrapper {
  /* max-width: 700px; */
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resource-section .heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  color: var(--text-dark);
  margin-bottom: 16px;
}

.resource-section .hr-underline {
  background-color: var(--text-dark);
  margin-bottom: 32px;
}

.resource-section .main-card {
  /* max-width: 300px; */
  width: 100%;
  text-align: left;
  margin-bottom: 28px;
  border-radius: 8px;
  overflow: hidden;
}

.resource-section .main-card .main-card-img {
  display: block;
  width: 100%;
  height: 330px;
}

.resource-section .main-card .text {
  padding: 14px 16px 12px;
  background-color: white;
  border-radius: 0 0 8px 8px;
}

.resource-section .main-card .tag {
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary);
  margin-bottom: 8px;
}

.resource-section .main-card h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: var(--text-dark);
  margin-bottom: 12px;
}

.resource-section .main-card .details {
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: var(--text-lightGray);
  margin-bottom: 16px;
}

.resource-section .main-card .date {
  font-weight: normal;
  font-size: 8px;
  line-height: 30px;
  color: va(--text-lightGray);
}

.resource-section__carousel {
  margin-bottom: 28px;
}

.resource-section .btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
}

.resource-section__view-more-btn {
  display: none !important;
  margin-top: 30px;
}

@media screen and (min-width: 576px) {
  .resource-section .main-card {
    display: flex;
    /* max-width: 1100px; */
  }

  .resource-section .main-card .img-container,
  .resource-section .main-card .text {
    flex-basis: 100%;
  }

  .resource-section .main-card .main-card-img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }

  .resource-section .main-card .img-container {
    order: 2;
  }

  .resource-section .main-card .text {
    order: 1;
    padding: 32px 40px;
  }
}

@media screen and (min-width: 992px) {
  .resource-section {
    padding: 100px 0;
  }

 

  .resource-section .heading {
    font-size: 36px;
    margin-bottom: 24px;
  }

  .resource-section .hr-underline {
    margin-bottom: 32px;
  }

  .resource-section .main-card {
    margin-bottom: 50px;
  }

  .resource-section .main-card .img-container {
    order: 2;
    border-radius: 0 8px 8px 0;
    overflow: hidden;
  }

  .resource-section .main-card .text {
    border-radius: 8px 0 0 8px;
    order: 1;
    padding: 32px 40px;
  }

  .resource-section .main-card .tag {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .resource-section .main-card h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 10px;
  }

  .resource-section .main-card .details {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .resource-section .main-card .date {
    font-size: 15px;
    line-height: 30px;
  }

  .resource-section__view-more-btn {
    display: inline-flex !important;
    margin-top: 40px;
  }
}

/* carousel */
#resource-section__carousel {
  display: block;
}

.resource-section__carousel__container > * {
  margin-right: 30px;
}

.resource-section__carousel__prev-control,
.resource-section__carousel__next-control {
  position: absolute;
  width: 60px;
  height: 100%;
  top: 0;
}

.resource-section__carousel__prev-control {
  left: 0;
}

.resource-section__carousel__next-control {
  right: 0;
}

@media screen and (min-width: 1240px){
  .resource-section .wrapper{
    width: 100%;
    padding: 0 135px;
  }
}