.section-7 {
  padding: 50px 30px;
  overflow: hidden;
}

.custom_box {
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
}

.section-7 .wrapper {
  /* max-width: 500px; */
  width: 100%;
}

.section-7 .heading {
  margin-bottom: 68px;
}

.section-7 .heading h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  color: var(--text-dark);
}

.section-7 .grid-card {
  display: none;
  position: relative;
  z-index: 2;
  max-width: 264px;
  margin-bottom: 20px;
  padding: 22.84px 22.83px;
  background-color: white;
  box-shadow: 0px 0px 9.26365px 2.85036px rgba(71, 74, 84, 0.1);
  border-radius: 5.70071px;
}

.section-7 .grid-card.mob {
  display: block;
  width: 45%;
}

.section-7 .grid-card img {
  margin-bottom: 6.42px;
  width: 34.25px;
  height: 34.26px;
}

.section-7 .grid-card h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 17.1021px;
  line-height: 110%;
  color: var(--text-dark);
}

.section-7 .grid-card p {
  margin-bottom: 8.57px;
  font-weight: normal;
  font-size: 11.4014px;
  line-height: 175%;
  color: var(--text-lightGray);
}

.section-7 .grid-card a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 11.4014px;
  line-height: 175%;
  color: var(--primary);
  background-color: transparent;
}

.section-7 .grid-card a::after {
  content: "";
  display: block;
  width: 10.09px;
  height: 6.42px;
  margin-left: 6px;
  background-image: url("../../assets/vector/green-arrow-right.svg");
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section-7 .grid-card:nth-child(odd) {
  /* margin-left: auto; */
  margin-right: 30px;
}

.section-7 .wrapper .dot-grid-1,
.section-7 .wrapper .dot-grid-2 {
  width: 94px;
  height: 82px;
  position: absolute;
  z-index: 1;
  background-image: url("../../assets/vector/dot-grid.svg");
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section-7 .wrapper .dot-grid-1 {
  top: 148px;
  right: 30px;
}

.section-7 .wrapper .dot-grid-2 {
  bottom: -30px;
  left: 30px;
}

@media screen and ( max-width : 1240px) {
  .section-7 .heading h1 {
    font-size: 25px !important;
  }
}

@media screen and (min-width: 768px) {
  .section-7 {
    display: flex;
    padding: 80px 30px;
    justify-content: center;
  }

  .section-7 .wrapper {
    /* max-width: 1260px; */
    width: 100%;
    display: flex;
 
  }

  .section-7 .grid-card {
    display: block;
    margin: 0;
  }

  .section-7 .heading {
    display: inline-flex;
    /* align-items: center; */
    margin-bottom: 0;
  }

  .section-7 .wrapper .dot-grid-1 {
    top: -40px;
    left: 280px;
  }

  .section-7 .wrapper .dot-grid-2 {
    bottom: -30px;
    left: auto;
    right: 0;
  }
}
@media screen and (max-width:820px) {
  .wrapper {
    flex-direction: column;
  }
  .custom_box {
    margin-top: 4vh;
    width: 100vw;
  }
}
@media screen and (max-width:500px) {
  .custom_box {
    flex-direction: column;
  }
  .section-7 .grid-card.mob {
    width: 88vw;
    max-width: 88vw;
  }
}

@media screen and (min-width: 1050px) {
  .section-7 {
    padding: 100px 30px;
  }

  

  .section-7 .heading h1 {
    font-size: 40px;
    line-height: 130%;
  }

  .section-7 .grid-card {
    max-width: 370px;
    height: 297px;
    padding: 32px;
    margin-bottom: 30px;
  }

  .section-7 .grid-card img {
    width: 48px;
    height: 48px;
    margin-bottom: 9px;
  }

  .section-7 .grid-card h2 {
    font-size: 24px;
    line-height: 110%;
    margin-bottom: 8px;
  }

  .section-7 .grid-card p {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .section-7 .grid-card a {
    font-size: 16px;
  }

  .section-7 .grid-card a::after {
    width: 14.14px;
    height: 9px;
    margin-left: 8px;
  }

  .section-7 .wrapper .dot-grid-1,
  .section-7 .wrapper .dot-grid-2 {
    width: 131.58px;
    height: 115px;
  }

  .section-7 .wrapper .dot-grid-1 {
    top: -70px;
    left: 380px;
  }

  .section-7 .wrapper .dot-grid-2 {
    bottom: -50px;
    right: -20px;
  }
}
@media screen and (min-width: 1240px) {
  .section-7 .wrapper{
    padding: 0 80px;
    padding-right: 0px;
    width: 100%;
    display: flex;
  }
  .section-7 .wrapper .dot-grid-1 {
    top: -70px;
    left: 390px;
  }

  .section-7 .wrapper .dot-grid-2 {
    bottom: -40px;
    right: 0px;
  }
}
/* my code  */
@media screen and (max-width:596px) {
  .section-7 .grid-card:nth-child(odd) {
    /* margin-left: auto; */
    margin-right: 0px;
  }
}