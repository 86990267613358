.section-5 {
  padding: 50px 30px;
  background-color: var(--vl-blue);
}

.section-5 .wrapper {
  /* max-width: 360px; */
  width: 100%;
}

.section-5__content {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  margin-bottom: 32px;
}

.section-5__content h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 125%;
  margin-bottom: 8px;
}

.section-5__content .hr-underline {
  margin-left: 0;
  margin-bottom: 16px;
  background-color: var(--primary);
  opacity: 1;
}

.section-5__content p {
  margin-bottom: 5px;
}

.section-5__content li {
  margin-bottom: 5px;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
}

.section-5__content li::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-image: url("../../assets/vector/green-tick.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-5 .img-container {
  position: relative;
  height: 72px;
  overflow: hidden;
}

.section-5 .img-container .fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    var(--vl-blue),
    transparent 20% 80%,
    var(--vl-blue)
  );
}

.section-5__img.mob {
  height: 100%;
  width: auto;
  transform: translate(-10%, 0);
}

.section-5__img.desk {
  display: none;
}

.integrations-image-row-1{
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
}

@media screen and (min-width: 700px) {
  .section-5 {
    padding: 30px 30px;
  }

  .section-5 .wrapper {
    display: flex;
    align-items: center;
    /* max-width: 700px; */
    column-gap: 32px;
  }

  .section-5__content,
  .section-5 .img-container {
    flex: 1;
  }

  .section-5__content {
    margin-bottom: 0;
  }

  .section-5 .img-container {
    height: auto;
  }

  .section-5__img.mob {
    display: none;
  }

  .section-5__img.desk {
    display: block;
    width: 100%;
    height: auto;
    max-height: 495px;
  }

  .section-5 .img-container .fade {
    background-image: linear-gradient(
      var(--vl-blue),
      transparent 20% 80%,
      var(--vl-blue)
    );
  }

  .integration-img-width{
    max-width: 70px !important;
  }
}

@media screen and (min-width: 1024px) {
  .section-5 .wrapper {
    padding: 0 135px;
  }

  .section-5__content {
    font-size: 18px;
    line-height: 167%;
    padding: 100px 0;
  }

  .section-5__content h1 {
    font-size: 40px;
    margin-bottom: 16px;
  }

  .section-5__content p {
    margin-bottom: 16px;
  }

  .section-5__content li {
    margin-bottom: 8px;
  }

  .section-5__content li::before {
    width: 20px;
    height: 20px;
  }

  .integrations-image-row-1{
  gap: 20px;
  flex-flow: row wrap;
  width: 100%;
  max-height: 500px;
}

.integration-img-width{
    max-width: 80px !important;
  }
}

@media screen and (min-width:1240px) {
  .section-5 .wrapper{
    padding: 0 135px;
    width: 100%;
  }

  .integration-img-width{
    max-width: 90px !important;
  }
}

@media screen and (min-width: 1400px) {
  .integration-img-width{
    max-width: 120px !important;
  }
}