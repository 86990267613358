/* navbar */
.nav {
  padding: 14px 0;
  background-color: var(--primary);
  width: 100%;
  justify-content: center;
  position: fixed;
  top: 36px;
  z-index: 5000000;
  border-bottom: 2px solid white;
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 1239px) {
  .nav {
    top: 0px;
  }
  .generalmargintop {
    margin-top: 64px;
  } 
}

.expand{
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  z-index: 100;
  transition: all 0.4s ease-in-out;
}

/* nav wrapper */
.nav .wrapper {
  /* max-width: 1400px; */
  padding: 0 30px;
  width: 87%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary);
}

.wrap-expand{
  position: absolute !important;
  width: 100%;
  justify-content: flex-end !important;
  /* padding-top: 8px; */
}

/* nav logo */
.nav__logo {
  width: 45px;
  height: 32px;
  margin-right: 16px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.nav_hidden{
  display: none;
}

/* nav logo light */
.nav__logo--light {
  background-image: url("../../../assets/logo-light.svg");
}

/* nav Hamburger */
.nav__hamburger {
  width: 26px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  cursor: pointer;
}

.nav__hamburger span {
  width: 100%;
  height: 0.15rem;
  background-color: white;
  transition: all 0.4s ease ;
}

.toggle span:nth-of-type(1){
  transform: rotate(-45deg) translate(-4px, 6px);
}

.toggle span:nth-of-type(2){
  display: none;
}

.toggle span:nth-of-type(3){
  transform: rotate(45deg) translate(-4px, -6px);
}

/* nav desktop */
.nav__desktop {
  display: none;
  font-family: Asap;
  font-weight: 600;
  margin-right: 20px;
}

/* nav auth login */
.nav__auth {
  display: none;
  padding: 9px 15px;
  background: var(--primary-dk);
}

/* nav login button */
.nav__login::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 14px;
  background-image: url("../../../assets/person-vector.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.desk-nav-container {
  display: none;
}

.deskNavContainer{
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  height: 100%;
  width: 100%;
}

.nav_mob_btns{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 30px;
}

.nav_mob_btn{
  padding: 12px 20px !important;
  font-weight: 600 !important;
font-size: 14.4px !important;
line-height: 16px !important;
}
/* my addition  */
@media screen and (max-width: 820px) {
  .nav .wrapper {
    flex-direction: row !important;
   
  }
}
@media screen and (max-width: 1345px) {
  .nav .wrapper {
    width: 92%;
  }
}
@media screen and (max-width: 1280px) {
  .nav .wrapper {
    width: 94%;
  }
}
@media screen and (max-width: 1249px) {
  .fix1 {
    padding: 12px 30px !important;
    border-radius: 0.5rem !important;
    font-size: 0.8rem !important;
    line-height: 100% !important;
  }
  .fix2 {
    padding: 10px 30px !important;
    border-radius: 0.5rem !important;
    font-size: 0.8rem !important;
    line-height: 100% !important;
  }
  .nav__login::before {
    width: 14px !important;
    height: 14px !important;
    margin-right: 8px !important;
  }
}

@media screen and (min-width: 1180px) {
  .expand{
    top: 0;
  }

  .nav {
    padding: 0 20px;
    background-image: linear-gradient(
      to right,
      var(--primary) 50%,
      var(--primary-dk) 50% 100%
    );
    height: 4rem;
  }

  .nav__desktop {
    display: inline-block;
  }

  .nav .wrapper {
    padding: 0;
    margin: 0 auto;
    height: 100%;
  }

  .nav__logo {
    width: 70px;
    height: 46.67px;
  }

  .nav__hamburger {
    display: none;
  }

  .nav_mob_btns{
    display: none;
  }

  .nav__auth {
    display: flex;
    gap: 20px;
    height: 100%;
  }

  .desk-nav-container {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
