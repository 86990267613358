.carousel {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "slide slide slide" "prev nav next";
  row-gap: 20px;
}

.carousel__container {
  grid-area: slide;
  display: flex;
  gap: 1rem;
  overflow: hidden;
  scroll-behavior: smooth;
}

.product-carousel{
  padding: 60px 0;
}

.carousel__container > * {
  flex: none;
}

.carousel__control-prev,
.carousel__control-next {
  display: flex;
  align-items: center;
}

.carousel__control-prev {
  grid-area: prev;
  left: 0;
  justify-content: flex-start;
}

.carousel__control-next {
  grid-area: next;
  right: 0;
  justify-content: flex-end;
}

.carousel__control-prev-svg,
.carousel__control-next-svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.carousel__nav {
  grid-area: nav;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10.67px;
}

.carousel__nav-btn {
  width: 5.33px;
  height: 5.33px;
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
}

.carousel__nav-btn.select {
  width: 8px;
  height: 8px;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  display: flex;
  align-items: center;
}

.card-carousel-container{
  display: block !important;
  position: relative;
  overflow: hidden;
}

.card-carousel{
  position: relative;
  display: flex !important;
  gap: 20px;
  margin-bottom: 40px;
}

.card-carousel .card:first-of-type{
  margin-left: 22px;
}

.card-carousel .card:last-of-type{
  margin-right: 22px;
}

.card-carousel-btns{
  width: 60px;
  position: absolute;
  padding: 0;
  top: 0;
  height: 100%;
}

.card-nav-prev-btn{
  left: 0;
  
}

.card-nav-next-btn{
  right: 0;
}

@media screen and (min-width: 992px) {
  .carousel {
    grid-template-columns: auto;
    grid-template-areas: "prev slide next" "nav nav nav";
  }

  .carousel__container {
    grid-area: slide;
  }

  .carousel__control-prev {
    grid-area: prev;
  }

  .carousel__control-next {
    grid-area: next;
  }

  .carousel__control-prev,
  .carousel__control-next {
    width: 50px;
  }

  .carousel__control-prev-svg,
  .carousel__control-next-svg {
    width: 32px;
    height: 32px;
  }

  .carousel__nav {
    grid-area: nav;
    column-gap: 16px;
  }

  .carousel__nav-btn {
    width: 8px;
    height: 8px;
  }

  .carousel__nav-btn.select {
    width: 12px;
    height: 12px;
  }

  .card-carousel{
    gap: 30px;
  }

  .card-carousel-btns{
    width: 140px !important;
    padding-right: 20px;
  }

  .partner-carousel{
    display: grid !important;
  }
}
