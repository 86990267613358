.details {
    font-size: 20px;
    line-height: 1.8;
    padding: 100px 0;
}
.details .wrapper{
  padding: 0 135px;
}

.details-img {
    border-radius: 10px;
    max-width: 100%;
}
.details-info > *{
    margin: 30px 0;
}
.details .heading-primary {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 30px;
}

.details-info {
    text-align: left;
    width: 60%;
    margin: 50px auto;
}

.list-item {
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 0 20px;
    line-height: 1.8;
}
.bullet {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    margin-right: 5px;
    text-align: center;
}
.green {
    color: var(--color-green);
}
.underline {
    text-decoration: underline;
}
.text-center {
    text-align: center;
}
.details hr {
    width: 100%;
    background-color: var(--color-grey-light);
    height: 1px;
    margin: 30px 0;
}

@media (max-width: 1100px) {
    .details > * {
        margin: 50px auto 0 auto;
    }
    .details .heading-primary {
        font-size: 30px;
    }
    .details p, .list-item {
        font-size: 18px;
    }
    .details-info {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .details-info {
        width: 90%;
    }
}

@media screen and (max-width: 750px) {
  .details{
    padding: 30px 30px;
  }

  .details .wrapper{
    padding: 0;
  }
}

@media (max-width: 500px) {
    /* .details > * {
        margin: 30px auto 0 auto;
    } */
    .details-info {
        width: 100%;
        margin: 0 auto;
    }
    .details .heading-primary {
        font-size: 24px;
    }
    .details .heading-tertiary {
        font-size: 20px;
    }
    .details p {
        font-size: 16px;
    }
    .details-info {
        width: 100%;
    }
    .list-item {
        align-items: baseline;
        font-size: 16px;
        padding: 0 5px;
    }
}
