.reactangle99 {
  height: 470px;
  width: 470px !important;
}
.vission {
  padding: 100px 135px;
  background-color: #f4f5fa;
}
.vission .wrapper h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  /* or 52px */
  text-align: center;
  color: #050302;
}
.vission .wrapper h1 span {
  color: #22bb66;
}
@media screen and (max-width: 767px) {
  .first-section .left-md h1{
    font-size: 20px;
    line-height: 32px;
  }
  .vission {
    max-width: 100%;
  }
  .vission {
    padding: 50px 0px;
    background-color: #f4f5fa;
  }
  .vission .wrapper{
    padding: 0 30px;
  }
  .vission .wrapper h1 {
    font-size: 30px;
  }
  .reactangle99 {
    height: 470px;
    width: 100% !important;
  }
}

@media screen and (max-width: 479px) {
  .first-section .left-md h1 {
    font-size: 25px;
    line-height: 35px;
  }
}
