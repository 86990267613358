@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

#solutions {
    overflow: hidden;
    width: 100%;
}
.my_flex {
    display: flex;
    flex-wrap: wrap;
}
.my_width {
   width: max-content;
   margin-right: 1rem;
   align-items: center !important;
}
.boldme {
    font-weight: bold;
}
.mar6 {
    margin-right: 6px;
}
.introbox {
    padding: 4rem;
    background-color: hsl(147, 69%, 43%);
    padding-top: 1rem;
}
.wrapper {
    display:flex;
    justify-content: space-between;
    gap: 2rem;
}
.left {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 4vw;
    justify-content: center;
}
.introimgmob {
    display: none;
}
.right {
    width: 44%;
    display: flex;
    justify-content: center;
}
.right img {
    /* height : 64vh; */
    width: 75%;
}
.p1 {
    color: white;
    font-family: 'Poppins',sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 4rem;
    font-weight: 400;
}
.p2 {
    color: white;
    font-family: 'Poppins',sans-serif;
    font-size: 1.2rem;
    margin-bottom: 3rem;
}
.introbox button {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    color: hsl(147, 69%, 43%);
    font-weight: 700;
    font-size: 1.2rem;
    font-family: Poppins;
    width: 45%;
}
/* introbox ends  */

.productsol {
    padding: 4rem;
    width: 94%;
    margin: auto;
}
.productsol .head {
    color: #5745c6;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.line3 {
    width: 138px;
    height: 5px;
    background-color: #22bb66;
    margin: auto;
    margin-bottom: 10px;
}
.box1 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0rem;
}
.leftbox {
    display: flex;
    flex-direction: column;
    width: 65%;
    justify-content: center;
}
.pimgmob {
    display : none;
}
.boxp1 {
    color: #000;
    font-size: 1rem;
    font-family: 'Poppins',sans-serif;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 2rem;
}
.rightbox{
    width: 80%;
    padding-left: 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.pimg {
    height: 90vh;
    margin-left: auto;
}
.pimg2 {
    height: 80vh;
    width: 80%;
    margin-left: auto;
}
.in {
    display: flex;
    margin-bottom: 8px;
    align-items: flex-start;
}
.in p {
    line-height: 4vh;
    color: #000;
    font-size: 0.9rem;
    font-family: 'Poppins',sans-serif;
    line-height: 1.4;
}
.green_tick_used_for_listing_img {
    height: 27px;
    width: 20px;
    margin-right: 5px;
}
.special_section {
    padding-top: 5rem;
}
.sechead,
.special_section .head {
    color: #000;
    font-size: 1.5rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.sechead {
    margin-top: 4rem;
    color: #5745c6;
}
.ourjourney .subhead {
    width: 50%;
    margin: auto;
    font-size: 0.9rem !important;
    margin-bottom: 2rem !important;
}
.productsol .subhead {
    margin-bottom: 2rem !important;
    font-size: 1rem !important;
    margin-top: 2rem;
}
.ourjourney .subhead,
.productsol .subhead,
.special_section .subhead {
    color: #000;
    font-size: 0.8rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0rem;
}
@media screen and (max-width:845px) {
  .special_section {
      padding-top: 1.8rem;
  }
  .special_section .subhead {
      margin-bottom: 2rem;
  }
}
@media screen and (max-width:992px) {
    .introbox button {
        width: 72%;
    }
}
@media screen and (max-width:845px) {
    .box1 {
       flex-direction: column;
       padding-bottom: 0px;
    }
    .sechead {
        margin-top: 0px;
        margin-bottom: 2rem;
    }
    .ourjourney .subhead {
        width: unset;
    }
    .pimg {
        display: none;
    }
    .pimg2 {
        display: none;
    }
    .rightbox {
        margin-bottom: 2rem;
    }
    .pimgmob {
        display: block;
        width: 65%;
        height: 500px;
        margin: auto;
    }
    .leftbox {
        width: 100%;
    }
    .boxp1 {
        margin-bottom: 2rem;
    }
    .rightbox {
        width: 100%;
        padding-left: 0px;
    }
}
@media screen and (max-width:700px) {
    .wrapper {
        flex-direction: column;
    }
    .introbox {
        padding-top: 3rem;
        padding-bottom: 0rem;
    }
    .introimg {
        display: none;
    }
    .introimgmob {
        display: block;
    }
    .left {
        width: 100%;
        margin-bottom: 0rem;
    }
    .p1 {
         margin-bottom: 0rem;
         font-size: 1.5rem;
         line-height: 2rem;
    }
    .introbox button {
        width: 40%
    }
    .right {
        width: 100%;
    }
    .p2 {
        font-size: 0.9rem;
        margin-bottom: 2rem;
    }
    .pimgmob {
        display: block;
        width: 65%;
        height: 400px;
        margin: auto;
    }
}
@media screen and (max-width:400px) {
   .introimgmob {
       height: 45vh;
   }
}
@media screen and (max-width:580px) {
    .pimgmob {
        display: block;
        width: 65%;
        height: 280px;
        margin: auto;
    }
}
@media screen and (max-width:380px) {
    .pimgmob {
        display: block;
        width: 90%;
        height: 280px;
        margin: auto;
    }
}
@media screen and (max-width:600px) {
    .introbox button {
        width: 55%;
    }
}
@media screen and (max-width:475px) {
    .introbox button {
        width: 100%;
    }
    .productsol {
        padding: 2rem;
    }
    .productsol .head {
        font-size: 1rem;
    }
    .line3 {
        margin-bottom: 25px;
    }
    .boxp1 {
        font-size: 1rem;
    }
}

.impactper {
    background-color: hsl(147, 69%, 43%);
    padding: 4rem;
    display: flex;
    flex-direction: column;
}
.ip{
    color: white;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.cardbox {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}
.card {
    background-color: white;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 28vh;
    width: 32%;
    opacity: 0.8;
    padding: 2rem;
}
.line4{
    width: 138px;
    height: 5px;
    background-color: white;
    margin: auto;
    margin-bottom: 80px;
}
.card p {
    color: #050589;
    font-size: 3rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
}
.card span {
    font-size: 1rem;
    font-family: 'Poppins';
    text-align: center;
}
.ourjourney {
    padding: 4rem 4rem 0rem 4rem;
}
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.ourjourney h1 {
    text-align: center;
    margin-bottom: 2rem;
}
.ourjourney .leftmd {
    display: flex;
    gap: 50px;
    padding-top: 10px;
}
.journeyrow {
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 2px dashed #c4c4c4;
    padding: 40px 0px 40px 0; /*padding: 40px 65px 40px 0*/
}
  
.journeyrow:not(:first-of-type){
    padding: 40px 0px; /*padding: 40px 65px*/
}
  
.journeyrow .year {
    /* width: 130px; */
    position: relative;
    margin-bottom: 16px;
}
  
.journeyrow .year::before{
    content: "";
    width: 20px;
    height: 20px;
    background: #22bb66;
    border-radius: 5px;
    transform: rotate(45deg);
    position: absolute;
    top: -52px;
}
.head2 {
    color: #5745c6;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.journeyrow .year h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: #050589;
}
  
.journeyrow .journey-image{
    max-width: 370px;
    max-height: 230px;
}
.journeyrow .desc {
    position: relative;
    flex: 1;
    margin-bottom: 66px;
    max-width: 345px;
}
.journeyrow .desc p {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(71, 74, 84, 1);
}
@media screen and (max-width: 1023px) {
    .ourjourney{
      padding: 100px 0 100px 20px ;
    }
    .ourjourney h1 {
      font-size: 30px;
      line-height: 35px;
    }
   .journeyrow .year h6 {
      font-size: 17px;
      line-height: 20px;
    }
    .journeyrow .desc p,
    .our-customer .left-md p {
      font-size: 13px;
      line-height: 18px;
    }
    .ourjourney .row{
      justify-content: center;
    }
    .ourjourney .left-md{
      flex-direction: column;
      gap: 0;
    }
    .journeyrow{
      padding: 0 20px 40px;
      border-top: none;
      border-left: 2px dashed #c4c4c4;
    }
  
    .journeyrow:not(:first-of-type){
      /* display: none; */
      padding: 40px 20px;
    }
  
    .journeyrow .year::before{
      top: 0;
      left: -30px;
    }
}

@media screen and (max-width: 767px) {
    .ourjourney {
      padding: 60px 30px 60px 30px;
      position: relative;
      flex-direction: column;
    }
    .ourjourney {
      overflow: hidden;
    }
    .ourjourney .leftmd {
      width: 100%;
      flex-direction: column;
      gap: 0;
      padding: 2rem;
    }
    .ourjourney .right-md {
      width: 70%;
      margin: auto;
    }
    .journeyrow{
      padding: 0 20px 40px;
      border-top: none;
      border-left: 2px dashed #c4c4c4;
    }
  
    .journeyrow:not(:first-of-type){
      /* display: none; */
      padding: 40px 20px;
    }
  
    .journeyrow .year::before{
      top: 0;
      left: -30px;
    }
    
    .journeyrow .desc{
      margin-bottom: 24px;
      
    }
    .journeyrow .desc p {
      font-size: 16px;
      line-height: 20.8px;
    }
}
@media screen and (max-width: 479px) {
  .ourjourney h1 {
      font-size: 25px;
      line-height: 30px;
    }
    .ourjourney .leftmd {
        flex-direction: column;
        gap: 3px;
    }
    .journeyrow .year h6 {
      font-size: 18px;
      line-height: 24px;
    }
}
@media screen and (max-width:475px) {
    .ip {
        font-size: 1rem;
    }
    .line4 {
        width: 66px;
        height: 2px;
        margin-bottom: 25px;
    }
    .head2 {
        font-size: 1rem;
    }
    .line3 {
        width: 75px;
        height: 3px;
    }
}
@media screen and (max-width: 790px) {
  .card {
      height: 38vh;
  }
}
@media screen and (max-width: 535px) {
    .cardbox{
       flex-direction: column;
    }
    .card {
        width: 100%;
        margin-bottom: 2rem;
    }
}
@media screen and (max-width: 1072px) {
    .left {
        padding-left: 0px;
    }
}

.cardbox2 {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}
.card2 {
    background-color: white;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 8%);
    border-radius: 20px;
    box-sizing: border-box;
    border: none;
    display: flex;
    flex-direction: column;
    width: 32%;
    opacity: 1;
    padding: 2rem;
}
.impactper2 {
    background-color: #fbf2ee;
    padding: 4rem;
    display: flex;
    flex-direction: column;
}
.plogoimg {
    height: 5rem;
    width: 12rem;
}
.card2p1 {
    font-family: 'Poppins',sans-serif;
    font-size: 1.2rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 1rem;
    height: 4rem;
    width: 88%;
}
.card2p2 {
    color: #050589;
    font-size: 3rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    margin-bottom: 1rem;
}
.pboximg {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
} 
.fdp {
    display: flex;
    align-items: center;
    gap: 15px;
}
.pbox {
    display: flex;
    flex-direction: column;
}
.fdptext {
    margin-bottom: 2rem;
}
.pbox span {
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
    color: #000;
}
@media screen and (max-width: 885px) {
   .cardbox2{
       flex-direction: column;
       gap: 20px;
   }
   .card2 {
       width: 100%;
   }
   .card2p1 {
       height: unset;
   }

}

.resources {
    background: linear-gradient(180deg,rgba(239,254,180,0.4) 0%,rgba(148,233,148,0.4) 100%);
    padding: 4rem;
    display: flex;
    flex-direction: column;
    border-top: 2px solid #22bb66;
}
.cardboxr {
    display: flex;
    /* justify-content: space-between; */
    gap: 20px;
    justify-content: center;
}
.card3 {
    background-color: white;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 8%);
    border-radius: 20px;
    box-sizing: border-box;
    border: none;
    display: flex;
    flex-direction: column;
    width: 32%;
    opacity: 1;
    padding: 2rem;
}
.blogimg {
    width: 100%;
    background-size: cover;
    background-position: center;
}
.tag {
    display: inline-block;
    font-size: 0.8rem;
    color: #fff;
    background-color: #5745c6;
    border-radius: 7px;
    padding: 5px 10px;
}
.card3textdes {
    font-family: 'Poppins',sans-serif;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 30px;
}
.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-family: Asap;
    transition-property: background-color, color;
    transition: 300ms ease-in-out;
    text-decoration: none;
    cursor: pointer;
    border: none;
    outline: none;
    color: #22bb66;
    font-size: 16px;
    background-color: transparent;
}
.ip2 {
    color: #000;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.line5 {
    width: 65px;
    height: 5px;
    background-color: #22bb66;
    margin: auto;
    margin-bottom: 80px;
}
.card3info {
    padding-top: 1rem;
}
@media screen and (max-width: 885px) {
    .cardboxr{
        flex-direction: column;
        gap: 20px;
    }
    .card3 {
        width: 100%;
    }
}