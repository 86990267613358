:root {
    --color-grey: #555;
    --color-grey-light: #bbb;
    --color-green: #22bb66;
}

/*Base*/
*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: all 0.2s ease-in;
}

body {
    font-family: Mulish;
    font-weight: 600;
}

.main {
    padding: 100px 135px;
}

/*Typography*/
.newsandmedia .heading-primary {
    font-size: 25px;
    font-weight: bold;
}
.newsandmedia .heading-secondary {
    font-size: 30px;
    font-weight: 600;
}
.newsandmedia .heading-tertiary {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 20px;
}
.newsandmedia .heading-tertiary--green {
    text-transform: uppercase;
    font-weight: 700;
    color: var(--color-green);
}

.text-primary {
    font-size: 18px;
    color: var(--color-grey);
    margin: 20px 0;
    line-height: 1.8;
    font-weight: 600;
}
.text-secondary {
    font-size: 16px;
    color: var(--color-grey);
    line-height: 1.8;
    font-weight: 600;
}

/** BUTTONS */
.btn {
    border: none;
    outline: none;
    color: var(--color-green);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    font-weight: inherit;
    font-family: Mulish;
}
.btn svg, .btn svg path {
    width: 30px;
    fill: currentColor;
    margin: 0 10px;
}
.btn-outlined {
    margin: 0;
    border-radius: 7px;
    padding: 10px 15px;
    border: 2px solid var(--color-green);
    width: 150px;
}
.btn-outlined:hover {
    background-color: var(--color-green);
    color: #fff;
}
.btn-outlined-white {
    border: 2px solid #fff;
    color: #fff;
    width: auto;
}
.btn-outlined-white:hover {
    background-color: #fff;
    color: #000;
}
.btn-filled {
    display: inline-block;
    border-radius: 7px;
    padding: 10px 15px;
    background-color: #fff;
    color: #000;
    border: 2px solid #fff;
}

.only-mobile {
    display: none;
}

/** MEDIA QUERIES */

@media (max-width: 1500px) {
    .main {
        padding: 50px;
    }
}
@media (max-width: 1300px) {
    .news {
        grid-template-columns: repeat(3, 1fr);
    }
    .newsandmedia .home-img {
        min-width: 500px;
    }
    .main {
        padding: 100px 80px;
    }
}

@media (max-width: 1200px) {
    .newsandmedia .home {
        flex-direction: column;
    }
    .newsandmedia .home-img {
        min-width: 100%;
        margin: 0;
        margin-bottom: 50px;
        width: 500px;
    }
    .only-mobile {
        display: block;
        text-align: center;
        margin: 30px 0;
    }

    .switch-page {
        display: none;
    }
    .cta-input {
        flex-direction: column;
    }
    .input-group {
        min-width: 100%;
        margin-top: 30px;
    }
}
@media (max-width: 1100px) {
    .main {
        padding: 70px 40px;
    }
}

@media (max-width: 1000px) {
    .news {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    .main {
        padding: 50px;
    }
    .newsandmedia .home {
        padding: 0 50px;
    }
}

@media (max-width: 900px) {
    .newsandmedia .heading-primary {
        font-size: 22px;
    }
    .newsandmedia .heading-secondary {
        font-size: 20px;
        margin: 0;
    }
    .newsandmedia .heading-tertiary {
        font-size: 18px;
        margin: 10px 0;
    }
    .text-primary {
        font-size: 15px;
        margin: 10px 0;
    }
    .text-secondary {
        font-size: 13px;
    }
}

@media (max-width: 750px) {
    .newsandmedia .home {
        padding: 0 20px;
    }
    .main {
        padding: 20px 50px;
    }
    .news {
        margin: 30px 0;
        grid-template-columns: repeat(2, 1fr);
    }
    .nav {
        width: 100%;
        justify-content: space-between;
    }
    .nav-link {
        margin: 0;
    }

    .cta {
        padding: 30px;
    }

    .card {
        border-radius: 7px;
    }
    .card-info {
        padding: 15px;
    }
    .newsandmedia .card-info .heading-tertiary {
        margin-bottom: 25px;
    }
    .balls {
        margin: 40px auto;
    }
    .btn svg{
      transform: scale(0.6);
      margin: 0 0 0 8px;
    }
}

@media (max-width: 500px) {
    .newsandmedia .home {
        padding: 0 10px;
    }
    .newsandmedia .home-img {
        width: 100%;
    }
    .news {
        grid-template-columns: repeat(1, 1fr);
    }
    .nav {
        overflow-x: scroll;
    }
    .nav::-webkit-scrollbar {
        display: none;
    }
    .nav-link {
        font-size: 11px;
    }
    .cta-buttons {
        width: 100%;
    }
    .cta-buttons button {
        font-size: 14px;
    }
}
@media (max-width: 525px) {
    .newsandmedia .home-img {
        width: 450px;
    }
}
@media (max-width: 475px) {
    .newsandmedia .home-img {
        width: 400px;
        margin-bottom: 25px;
    }
}
@media (max-width: 425px) {
    .newsandmedia .home-img {
        width: 370px;
    }
}
@media (max-width: 388px) {
    .newsandmedia .home-img {
        width: 295px;
    }
}
@media (max-width: 306px) {
    .newsandmedia .home-img {
        width: 235px;
    }
    .main {
        padding: 20px 30px;
    }
}