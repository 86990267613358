:root {
    --color-grey: #555;
    --color-grey-light: #bbb;
    --color-green: #22BB66;
}

/*Base*/
*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: all .2s ease-in;
}
 
body {
    font-family: Mulish;
    font-weight: 500;
}

/*Typography*/
.dataandsecurity .heading-primary {
    font-size: 40px;
    font-weight: 600;
}
.dataandsecurity .heading-secondary {
    font-size: 30px;
    font-weight: 600;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.dataandsecurity .heading-tertiary {
    font-size: 20px;
    margin: 20px 0;
    font-weight: 600;
}

.text-primary {
    font-size: 18px;
    color: var(--color-grey);
    /* margin-top: 30px; */
    line-height: 1.8;
    font-weight: 600;
}

.margin-t-40{
  margin: 40px 0 0;
}

.text-secondary {
    font-size: 16px;
    color: var(--color-grey);
    line-height: 1.8;
    font-weight: 600;
}

/*HR*/
hr {
    width: 70px;
    height: 4px;
    background-color: var(--color-green);
    border: none;
    outline: none;
    margin: 30px 0;
}

.text-date{
      display: block;
    }

/*** MEDIA QUERIES ***/
@media (max-width: 1400px) {
  .text-date{
      display: block;
    }
    .info {
        padding: 150px 120px;
    }
    .info-primary {
        margin-right: 150px;
    }
}

@media (max-width: 1300px) {
    .dataandsecurity .home{
        padding: 0 150px;
    }
    .nav--bottom {
        padding: 0 150px;
    }
    .info {
        padding: 120px 100px;
    }
    .info-primary {
        margin-right: 100px;
    }
    
    .dataandsecurity .heading-primary {
        font-size: 30px;
    }
    .dataandsecurity .heading-secondary {
        font-size: 20px;
    }
    .dataandsecurity .heading-tertiary {
        font-size: 18px;
    }
    .text-primary {
        font-size: 16px;
    }
    .text-secondary {
        font-size: 14px;
    }
}

@media (max-width: 1100px) {
    .note, .info {
        padding: 50px;
    }
}
@media (max-width: 1000px) {
    .dataandsecurity .home{
        padding: 0 70px;
    }
    .nav--bottom {
        padding: 0 70px;
    }
    .info {
        padding: 80px 30px;
    }
}

@media (max-width: 900px) {
    hr {
        margin: 20px;
    }
    .dataandsecurity .home{
        padding: 0 30px;
    }
    .dataandsecurity .home-img {
        width: 90%;
        height: 50vw;
    }
    .nav--bottom {
        padding: 0 10px;
    }
    .info {
        flex-direction: column;
        padding: 80px 30px;
    }
    .info-primary, .info-grid {
        width: 100%;
        margin: 20px 0;
    }
    .note {
        padding: 50px 20px;
    }
    .dataandsecurity .home-logos {
        width: 400px;
        height: 70px;
    }
}

@media (max-width: 700px) {
    .dataandsecurity .heading-primary {
        font-size: 22px;
    }
    .dataandsecurity .heading-secondary {
        font-size: 20px;
        margin: 0;
    }
    .dataandsecurity .heading-tertiary {
        font-size: 18px;
        margin: 10px 0;
    }
    .text-primary {
        font-size: 12px;
        line-height: 19.2px;
        margin: 10px 0;
    }
    .text-date{
      display: none;
    }
    .news-view-btn{
      font-size: 12px;
      line-height: 19.2px;
    }
    .text-secondary {
        font-size: 13px;
    }
    .nav--item {
        font-size: 13px;
    }
    .note {
        padding: 10px 20px;
    }
}

@media (max-width: 600px) {
    hr {
        height: 2px;
    }
    .dataandsecurity .home {
        padding: 0 10px;
    }
    .dataandsecurity .home-img {
        width: 95%; 
        margin: 20px;   
    }
    .nav--bottom {
        margin-top: 0;
        overflow-x: scroll;
    }
    .nav--bottom::-webkit-scrollbar {
        display: none;
    }

    .nav--item {
        font-size: 11px;
        margin: 0 10px;
    }
    .info{
        padding: 10px 20px;
    }
}

@media (max-width: 500px) {
    .dataandsecurity .home-logos {
        width: 90%;
        height: 50px;
    }
    
}
