footer {
  padding: 50px 30px;
  background-color: var(--primary);
  color: white;
}

#footer .wrapper {
  position: relative;
}

#footer .about {
  margin-bottom: 28px;
}

#footer .about .text .logo {
  width: 60px;
  height: 42px;
  margin-bottom: 12px;
}
#footer .about .text .logor {
  width: 150px;
  height: 65px;
  margin-bottom: 12px;
}
#footer .about .text .para {
  max-width: 305px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #f8f9fa;
  margin-bottom: 19px;
}

#footer .connect {
  width: 100%;
  max-width: 305px;
  margin-bottom: 26px;
}

#footer .connect h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

#footer .connect .icon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

#footer .connect .FB-icon {
  background-image: url("../../assets/icons/Facebook_white.svg");
}

#footer .connect .LD-icon {
  background-image: url("../../assets/icons/LinkedIN_white.svg");
}

#footer .connect .YT-icon {
  background-image: url("../../assets/icons/Youtube_white.svg");
}

#footer .connect .IG-icon {
  background-image: url("../../assets/icons/Instagram_white.svg");
}

#footer .connect .TW-icon {
  background-image: url("../../assets/icons/Twitter_white.svg");
}

#footer .connect .VC-icon {
  background-image: url("../../assets/icons/victory_white.svg");
}

#footer .about .imgs {
  width: 100%;
  max-width: 305px;
}

#footer .about .imgs .ssl {
  width: 103.45px;
  height: 51.69px;
}

#footer .about .imgs .iso {
  width: 52.87px;
  height: 52.84px;
}

#footer .about .imgs .hippa {
  width: 97.7px;
  height: 43.07px;
}

#footer .grid {
  display: flex;
  column-gap: 20px;
}

#footer .grid .col-1,
#footer .grid .col-2 {
  flex-basis: 100%;
  gap: 16px;
}

#footer .links {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 16px; */
}
#footer .links a {
  display: flex;
  align-items: center;
}
#footer .links a span,#footer .links a img{
  cursor: pointer;
}

#footer .links h2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}

#footer .links a {
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-decoration: none;
  color: white;
  margin-bottom: 4px;
}

@media screen and (min-width: 700px) {
  #footer .wrapper {
    display: flex;
    column-gap: 20px;
  }

  #footer .about,
  #footer .grid {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 992px) {
  #footer .about .text .logo {
    width: 75px;
    height: 52px;
    margin-bottom: 24px;
  }
  #footer .about .text .logor {
    width: 150px;
    height: 65px;
    margin-bottom: 24px;
  }

  #footer .about .text .para {
    max-width: 370px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  #footer .about .connect {
    max-width: 370px;
    font-size: 20px;
  }

  #footer .about .connect .icon {
    width: 32px;
    height: 32px;
  }

  #footer .about .imgs {
    max-width: 370px;
  }

  #footer .about .imgs .ssl {
    width: 127.59px;
    height: 63.75px;
  }

  #footer .about .imgs .iso {
    width: 65.21px;
    height: 65.17px;
  }

  #footer .about .imgs .hippa {
    width: 120.5px;
    height: 53.12px;
  }

  /* #footer .links {
    margin-bottom: 40px;
  } */

  #footer .links h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  #footer .links a {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (min-width: 1240px) {
  footer{
    padding: 100px 0;
  }
  #footer .wrapper{
    width: 100%;
    padding: 0 135px;
  }
  #footer .grid .col-1{
    /* justify-content: space-between; */
    gap: 40px;
  }
  
  #footer .grid .col-2{
    /* padding: 0 0 80px; */
    gap: 40px;
  }
}