.demo .contactdetails {
    width: 100%;
    margin-top: 50px;
    padding: 30px;
}

.demo .contactdetails .row .inputField {
    background: #ffffff;
    border: 1px solid rgba(71, 74, 84, 0.3);
    box-sizing: border-box;
    border-radius: 12px;
    width: calc(100% - 24px);
    line-height: 20px;
    padding: 15px 20px;
    font-size: 16px;
    margin: 12px;
}

.demo .contactdetails .row .err {
    margin-left: 20px;
    color: red;
}

.demo .contactdetails .row .heading {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 130%;
    color: #050302;
    margin: 12px;
    text-align: center;
}

.demo .contactdetails .row .greenbutton {
    padding: 16px 40px;
    width: 136px;
    height: 52px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    background: #22bb66;
    border-radius: 8px;
    margin: 12px;
}

.demo {
    background: #f4f5fa;
}

.demo .banner {
    padding: 0px;
}

.demo .contact-detail-img {
    width: 100%;
}

.w-25ch {
    width: 25ch;
}

@media screen and (min-width: 768px) {
    .demo .contact-detail-img {
        width: 40%;
        object-fit: cover;

    }

    .demo .contactdetails {
        width: 60%;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 60px;
    }

    .demo .contactdetails .row {
        width: 100%;
    }

    .demo .contactdetails .row .heading {
        font-size: 28px;
        text-align: left;
    }
}

@media screen and (min-width: 1024px) {

    .demo .contactdetails {
        padding: 20px;
        padding-left: 30px;
        padding-right: 60px;
        margin-top: 0px;
    }

    .demo .contactdetails .row .heading {
        font-size: 32px;
    }

    .demo .contactdetails .row .heading {
        margin: 0 0 10px 12px;
    }

    .demo .contactdetails .row .inputField {
        margin: 10px;
    }

    .demo .contactdetails .row .greenbutton {
        margin: 0px 10px 10px;
    }
}

@media screen and (min-width: 1400px) {

    .demo .contactdetails {
        padding-left: 60px;
        padding-right: 135px;
    }

    .demo .contactdetails .row .heading {
        font-size: 40px;
    }
}
