.section-3 {
  padding: 40px 0 50px;
  background-color: var(--vl-blue);
}

.section-3 .wrapper {
  margin: 0 auto;
}

.section-3__heading {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: var(--text-dark);
}

.section-3 .underline {
  margin: 0 auto 8px;
  width: 30px;
  height: 2px;
  background: #050302;
  opacity: 0.5;
  border-radius: 5px;
}

.section-3 .img-container {
  text-align: center;
}

.section-3__img {
  width: 71.5px;
  height: 55px;
  margin: 24px 10px 0;
}

@media screen and (min-width: 780px) {
  .section-3 {
    padding: 60px 30px;
  }

  .section-3__heading {
    margin-bottom: 24px;
    font-size: 36px;
  }

  .section-3 .underline {
    width: 56px;
    margin-bottom: 40px;
  }

  .section-3 .img-container {
    /* max-width: 1050px; */
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
  }

  .section-3__img {
    margin: 0;
    /* width: 130px;
    height: 100px; */
    width: 142.60px;
    height: 115px;
  }
}

@media screen and (min-width: 1024px) {
  .section-3 .wrapper{
    padding: 0 135px;
  }

  .section-3 .img-container{
    gap: 20px;
  }

}

@media screen and (min-width: 1240px) {
  .section-3 .wrapper{
    padding: 0 135px;
  }
  .section-3 .img-container {
    gap: 20px;
  }
}
