.balls {
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: space-between;
    margin: 70px auto;
    text-align: center;
}
.ball {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--color-green);
    opacity: .4;
    border-radius: 50%;
}
.ball-active {
    width: 15px;
    height: 15px;
    background-color: var(--color-green);
    opacity: 1;
}