.navBanner__title{
  display: none;
}
@media screen and (min-width: 1024px) {
  .navBanner {
  width: 100%;
  max-width: 2000px;
  position: absolute;
  top: 70px;
  left: 0%;
  z-index: 10;
  /* transform: translate(-50%, 0); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  background-color: var(--gray-100);
}

.navBanner__row-1,
.navBanner__row-2 {
  width: 100%;
  padding: 20px 40px;
}

/* .navBanner__row-1 {
  margin-bottom: 30px;
} */

.navBanner__row-2 {
  background-color: #e9f8f0;
}

.navBanner__title {
  width: 90%;
  max-width: 1030px;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray-500);
}

.navBanner__grid {
  width: 100%;
  max-width: 2000px;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  column-gap: 5px;
  row-gap: 5px;
}
.navBanner a {
  text-decoration: none;
}
}
