.section-13 {
  padding: 50px 0;
  background-color: #F4F5FA;
  overflow: hidden;
}
/* my addition  */
.product_list_key_feature_data {
  list-style: square inside url("../../assets/vector/green-tick.svg");
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.in {
  display: flex;
  margin-bottom: 20px;
}
.product_page_product_name {
  margin-left: 20px;
}
@media only screen and (max-width : 768px) {
  .product_page_product_name {
    margin-left: 8px !important;
  }
}
.in p {
  line-height: 4vh;
}
.green_tick_used_for_listing_img {
  height: 27px;
  width: 20px;
  margin-right: 5px;
}
.green-back{
  background-color: #E9F8F0 !important;
}

.grey-back{
  background-color: #F4F5FA !important;
}

.section-13 .wrapper {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 135px;
}

.section-13 .wrapper .headDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 450px;
}
.section-13 .wrapper .headDiv h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #050302;
  text-align: left;
  white-space: pre-line;
  /* tex-align: ; */
}
.section-13 .wrapper .headDiv span {
  color: #22bb66;
}
.section-13 .wrapper .headDiv p {
  margin-top: 16px;
  font-size: 16px;
  line-height: 160%;

  display: flex;
  text-align: left;
  color: #474a54;
}
.section-13 .wrapper .row {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 60px;
}
.section-13 .wrapper .row2{
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
  grid-gap: 25px;
}
.row3 {
  margin-top: 20px !important;
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
  grid-gap: 25px;
}
.mar3 {
  margin-right: 3px;
}
.fwb {
  font-weight: 900;
}
.section-13 .wrapper .row .leftDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  grid-gap: 20px;
  padding-right: 70px;
  order: 1;
  padding-left: 20px;
  height: fit-content;
}
.section-13 .wrapper .row .leftDiv .card {
  background: #ffffff;
  box-shadow: 0px 4px 13px 4px rgba(71, 74, 84, 0.1);
  border-radius: 8px;
  padding: 30px;
  width: 100% !important;
}
.section-13 .wrapper .row .leftDiv .card h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  color: #050302;
}
.section-13 .wrapper .row .leftDiv .card p {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #474a54;
  margin-top: 15px;
}
.section-13 .wrapper .row .rightDiv {
  width: 50%;
  display: flex;
  order: 2;
  justify-content: center;
 
}

.section-13 .wrapper .row .rightDiv .sec-13-image-desk {
  display: block;
  height: 55vh;
}
.eh {
  height: 100vh !important;
  justify-content: flex-start !important;
}

.section-13 .wrapper .row .rightDiv .sec-13-image-mob{
  display: none;
}

.section-13 .wrapper .row2 .button-fill {
  padding: 16px 40px;
  background: #22bb66;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #22bb66;
  color: white;
}
.section-13 .wrapper .row2 .button-border {
  padding: 16px 40px;
  background: white;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #22bb66;
  border: 2px solid #22bb66;
}

.extra-info{
  display: flex;
  align-items: center;
}

.extra-info p{
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #474A54;
  margin-right: 16px;
}

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1023px) {
  .section-13 .wrapper{
    padding: 30px;
  }
  .section-13 .wrapper .headDiv {
    padding-right: 0px;
  }

}

@media screen and (max-width: 767px) {
  .section-13 .wrapper{
    padding: 30px;
  }
.section-13 .wrapper .row .leftDiv .card h1{
  font-size: 12px;
  line-height: 230%;
  text-align: left;
}

.section-13 .wrapper .row .leftDiv .card p{
  font-size: 8px;
  line-height: 160%;
  text-align: left;
}

.section-13 .wrapper .row .leftDiv .card{
  padding: 12px;
}
.section-13 .wrapper .row2 {
    margin-top: 40px;
    grid-gap: 15px;
  }
  .section-13 .wrapper .row2 .button-border,
  .section-13 .wrapper .row2 .button-fill {
    padding: 12px 12px;
    font-size: 14.4px;
    line-height: 16px;
  }

  .section-13 .wrapper .headDiv {
    padding-right: 0px;
  }
  .section-13 .wrapper .headDiv h1 {
    font-size: 18px;
    line-height: 23px;
  }

  .section-13 .wrapper .headDiv p {
    margin-top: 10px;
    font-size: 8px;
    line-height: 160%;
  }
  .section-13 .wrapper .row {
    flex-direction: column;
    margin: 0;
  }
  .section-13 .wrapper .row .leftDiv {
    width: 100%;
    padding-right: 0px;
    order: 2;
  }
  .section-13 .wrapper .row .rightDiv {
    margin: 20px 0;
    width: 100%;
    order: 1;
    height: 45vh;
  }

  .section-13 .wrapper .row .rightDiv .sec-13-image-desk{
  display: none;
}

  .section-13 .wrapper .row .rightDiv .sec-13-image-mob{
    display: block;
    width: 80%;
    margin: auto;
    height: 95%;
  }

  .extra-info p{
    font-size: 12px;
    line-height: 160%;
    margin-right: 8px;
  }

  .extra-info .arrow-icon{
    transform: scale(0.7);
  }
}

@media screen and (max-width: 479px) {
}

.btn-transparent{
  background-color: transparent !important;
}


