.input-form {
    position: relative;
}
.input {
    border-radius: 10px;
    background-color: #fff;
    padding: 15px 20px;
    font-size: 18px;
    width: 100%;
    border: 2px solid #eee;
}
.input-form svg {
    position: absolute;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}
.input:focus {
    border: 2px solid var(--primary);
}


@media (max-width: 500px) {
    .input {
        padding: 10px;
        font-size: 16px;
    }
}