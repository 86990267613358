.intro-banner {
  padding: 50px 30px;
  height: 310px;
  background-image: url(../../assets/png/product-banner.png);
  background-size: cover;
}
.intro-banner .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0px;
  padding-right: 0px;
}
.intro-banner .wrapper .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 0px 40px;
}
.intro-banner .wrapper .hr {
  width: 56px;
  height: 2px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 30px;
}
.intro-banner .wrapper .button {
  padding: 16px 40px;
  background-color: #ffffff;
  border-radius: 8px;

  font-size: 14.4px;
  line-height: 16px;
  color: #000000;
  font-weight: bold;
}

.lh-50{
  line-height: 50px !important;
}

.w-33ch{
  width: 33ch;
}

@media screen and (min-width: 768px) {
  .intro-banner .wrapper .title {
    font-weight: 600;
    font-size: 38px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
  .intro-banner .wrapper .hr {
    width: 56px;
    height: 2px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 25px;
    margin-bottom: 30px;
  }
  .intro-banner .wrapper .button {
    font-size: 18px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1050px) {
}
