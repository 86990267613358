.services {
  padding: 40px 135px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  align-items: center;
  justify-content: space-between;
}

.services__nav{
  display: flex;
  align-items: center;
  gap: 40px;
}

.services .lifeLabel {
  display: flex;
  align-items: center;
  color: #474a54;
  padding: 2px 16px;
  font-size: 18px;
  line-height: 28px;
  background-color: white;
  height: 30px;
}
.services .selected {
  border: 2px solid #22bb66;
  border-radius: 8px;
  color: #22bb66;
}

.services .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 20px;
  color: #050302;
}
.services .demo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 20px;
  background: #22bb66;
  border-radius: 6.4px;
  color: white;
  margin-left: auto;
  margin-bottom: 20px;
  font-size: 14.4px;
  line-height: 16px;
}
.services .carousel {
  width: 100%;
  height: 30px;
  display: block;
}
.services__carousel__container {
  height: 30px;
}
.services__carousel__container > * {
  margin-right: 40px;
}
.services__carousel__prev-control,
.services__carousel__next-control {
  color: #050302;
  background: none !important;
}
.services__carousel__prev-control {
  display: none;
}

.services__carousel__next-control {
  display: none;
}

.services .title {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 0px;
}
.services .demo {
  padding: 16px 40px;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0px;
}
.services .carousel {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .services {
    padding: 50px 30px 0;
  }

  .vission {
    max-width: 100%;
  }

  .services .title {
    font-size: 18px;
    line-height: 23px;
    margin-right: 130px;
  }
  .services .demo {
    padding: 12px 20px;
    font-size: 14.4px;
    line-height: 16px;
  }
  .services .carousel {
    width: 100%;
    margin-top: 15px;
  }

  .services .lifeLabel{
    font-size: 12px;
    line-height: 15px;
  }

  .services__carousel__container > * {
    margin-right: 14px;
  }
}

@media screen and (max-width: 767px) {
  .vission {
    max-width: 100%;
  }

  .services .title {
    font-size: 18px;
    line-height: 23px;
    margin-right: 8px;
  }
  .services .demo {
    padding: 12px 20px;
    font-size: 14.4px;
    line-height: 16px;
  }
  .services .carousel {
    width: 100%;
    margin-top: 15px;
  }

  .services .lifeLabel{
    font-size: 12px;
    line-height: 15px;
  }

  .services__carousel__container > * {
    margin-right: 14px;
  }
}

@media screen and (max-width: 479px) {
  .first-section .left-md h1 {
    font-size: 25px;
    line-height: 35px;
  }
}
