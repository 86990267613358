  .navBanner__grid__card {
  position: relative;
  top: 0;
  cursor: pointer;
  border-radius: 8px;
}

.navBanner__grid__card .container {
  display: flex;
  padding: 12px 20px;
  border-radius: 8px;
}

.navBanner__grid__card__h4 {
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

  color: #fff;
}

.navBanner__grid__card__p {
  display: none;
}

.green-dot{
  display: none;
}

.mob-white-dot{
  display: block;
  margin-right: 10px;
}
.sub_grid_top_60{
  top: 60px;
}

@media screen and (min-width: 1024px) {
  .navBanner__grid__card {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
}

.bg-vl-blue {
  background-color: var(--vl-blue);
}

.navBanner__grid__card .container {
  display: flex;
  padding: 12px 20px;
  border-radius: 8px;
}

.green-dot {
  display: block;
  width: 34px;
  height: 34px;
  margin-right: 12px;
}

.mob-white-dot{
  display: none;
}

.navBanner__grid__card__h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--gray-700);
}

.navBanner__grid__card__p {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--gray-500);
}
}