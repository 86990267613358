.margin-left {
  margin-left: auto !important;
}
.productExperience .news-letter {
  background-image: url(../../assets/png/product-banner.png);
}
@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 574px) {
}

@media screen and (max-width: 479px) {
}
