.section-4 {
  padding: 50px 30px;
  text-align: center;
}
.imgh {
  height: 46rem;
}
.section-4 .wrapper {
  position: relative;
  /* max-width: 500px; */
}

.section-4 .heading-1,
.section-4 .heading-2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
}

.section-4 .heading-1 {
  margin-bottom: 10px;
}

.section-4 .heading-2 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}

.section-4 hr {
  background-color: var(--primary);
  margin-bottom: 48px;
}

.section-4 .img {
  width: 100%;
  height: auto;
  max-width: 1000px;
  margin-bottom: 20px;
}

@media screen and (max-width : 1100px) {
  .imgh {
    height: 38rem;
  }
}
@media screen and (max-width : 750px) {
  .imgh {
    height: 32rem;
  }
}
@media screen and (max-width : 620px) {
  .imgh {
    height: 26rem;
  }
}
@media screen and (max-width : 500px) {
  .imgh {
    height: 21rem;
  }
}
@media screen and (max-width : 400px) {
  .imgh {
    height: 17rem;
  }
}
@media screen and (max-width : 320px) {
  .imgh {
    height: 14rem;
  }
}
@media screen and (max-width : 270px) {
  .imgh {
    height: 12rem;
  }
}

@media screen and (min-width: 768px) {
  .section-4 {
    padding: 60px 0;
  }

  .section-4 .wrapper {
    /* max-width: 1260px; */
    padding: 0 40px;
  }

  .section-4 .heading-1,
  .section-4 .heading-2 {
    font-size: 40px;
  }

  .section-4 .heading-1 {
    margin-bottom: 24px;
  }

  .section-4 .heading-2 {
    margin-bottom: 40px;
  }

  .section-4 .hr {
    margin-bottom: 50px;
  }

  .section-4 .btn {
    width: 186px;
    height: 52px;
    font-size: 18px;
  }

  .company-subheading{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .company-subheading p{
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    color: #474A54;
    text-align: center;
    max-width: 82%;
  }
}

@media screen and (min-width: 1024px) {
  .section-4 .wrapper{
    padding: 0 135px;
  }
}

@media screen and (min-width:1240px) {
  .section-4 .wrapper{
    padding: 0 135px;
    width: 100%;
  }
}