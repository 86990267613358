.section-8 {
  padding: 50px 0 0;
}

.section-8 .wrapper {
  position: relative;
  padding: 0 30px;
}

.section-8 .heading {
  display: inline;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  padding: 0;
}

.section-8 .demo-btn {
  position: absolute;
  top: 0;
  right: 30px;
  width: 100px;
}

.section-8__nav {
  margin-top: 20px;
  margin-bottom: 16px;
  background-color: transparent;
  overflow: hidden;
  padding-left: 30px;
  display: flex;
}

.section-8 .btn-nav {
  opacity: 1;
  /* margin-right: 14px; */
  /* margin-bottom: 8px; */
  padding: 4px 16px;
  border: 0;
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  color: #474a54;
  opacity: 0.6;
  border-radius: 4px;
}

.section-8 .btn-nav:hover {
  background-color: var(--primary);
  color: var(--gray-100);
}

.section-8 .btn-nav.select {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.section-8 .btn-nav.select:hover {
  color: var(--gray-100);
}

.product-slide .wrapper{
  padding: 0 30px;
}

.section_8__carousel{
  order: 2;
  width: 100%;
  margin: 20px 0;
}

.section_8__carousel__container{
  gap: 2px;
}

.section_8__carousel .section_8__carousel__prev-control, .section_8__carousel .section_8__carousel__next-control{
  transform: translateY(-25px);
  /* background: none !important ; */
  position: absolute;
}

.section_8__carousel__prev-control{
  left: -10px;
}

.section_8__carousel__next-control{
  right: -10px;
}

/* .section_8__carousel__container{
  align-items: center;
} */

@media screen and (min-width: 768px) {
  
  .section-8 .wrapper {
    /* max-width: 1260px;
    margin: 0 auto; */
    padding: 50px 30px;
    width: 100%;
  }

  .section-8 > .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .section-8 .heading {
    order: 1;
    font-size: 32px;
    padding: 0;
  }

  .section-8 .demo-btn {
    position: relative;
    right: 0;

    width: 175px;
    height: 52px;

    order: 3;
    font-weight: 600;
    font-size: 18px;
  }

  .section-8__nav {
    margin: 0;
    order: 2;
  }

  .section-8 .btn-nav {
    border-radius: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }

  .section-8 .btn-nav.select {
    font-weight: 500;
    border: 2px solid var(--primary);
  }
}

@media screen and (min-width: 1033px) {
  .section-8 > .wrapper {
    align-items: center;
  }

  .section-8 .heading{
    padding: 0;
  }

  .section_8__carousel__container{
  gap: 20px;
}
.section_8__carousel .section_8__carousel__prev-control, .section_8__carousel .section_8__carousel__next-control{
  transform: translateY(0);
}

.section_8__carousel__prev-control{
  left: -35px;
}

.section_8__carousel__next-control{
  right: -35px;
}
.section_8__carousel{
  width: 50%;
  margin: 20px 40px 20px 35px;
}
}

@media screen and (min-width: 1240px) {
  .section-8 .wrapper{
    padding: 0 135px;
    width: 100%;
  }

}

@media screen and (min-width: 1400px) {
  .section-8 .heading{
    padding: 0 30px 0 0;
  }

  .section_8__carousel{
    margin: 20px 20px;
  }
  
}