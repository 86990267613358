.box {
    width: 60%;
    margin: auto;
    margin-top: 10rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 4rem;
    align-items: center;
}
.box img {
    width: 100%;
}
h1 {
    font-size: 1.5rem;
}
.box button {
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 1rem;
    line-height: 125%;
    height: 52px;
    background-color: hsl(147, 69%, 43%);
    color: white;
}
.box button a {
    color: white;
}
@media screen and (max-width: 465px) {
   .box {
       margin-top: 5rem;
       width: 75%;
    }
    h1 {
        font-size: 1rem;
    }
}
