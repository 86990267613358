.row {
    display: flex;
    flex-wrap: wrap;
}
a {text-decoration: none;}

/* First Section Start  */
.career-section {
  padding: 100px 135px;
}
.career-section .row {gap: 30px; margin-bottom: 50px;}
.career-section h1 {font-size: 40px; font-weight: 700; line-height: 52px; margin-bottom: 30px;}
.search {width: 50%;background: #FFFFFF; border: 1px solid rgba(71, 74, 84, 0.3); border-radius: 12px; padding: 10px 15px; display: flex; align-items: center; gap: 15px;}
.search input {flex: 1;}
.search img {cursor: pointer;}
.career-section select {flex: 1; background: #FFFFFF; border: 1px solid rgba(71, 74, 84, 0.3); border-radius: 12px; padding: 10px 15px; }

.load-more {font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(5, 3, 2, 1); text-decoration: none;; display: block; text-align: center; margin-top: 40px;}



/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width:1365px) {
    .career-section {
    padding:50px;
}
}

@media screen and (max-width:1023px) {
    .career-section h1 {font-size: 35px; line-height: 45px;}
    .search, .career-section select {border-radius: 8px;}
}

@media screen and (max-width:767px) {
    .search {width: 100%;}
}

@media screen and (max-width:574px) {
    .career-section {padding: 30px 20px;}
    .career-section h1 {font-size: 30px; line-height: 35px;}
    .load-more {font-size: 16px;}
}

@media screen and (max-width:479px) {
    .career-section .row {gap: 10px; margin-bottom: 20px;}
    .search, .career-section select {border-radius: 4px;}
}