.section-11 {
  padding: 50px 30px;
  background-color: var(--vl-blue);
}

.section-11 .wrapper {
  /* max-width: 360px; */
  width: 100%;
}

.sec11spanreadmore {
  font-weight: bold;
  margin-left: 2px;
  text-decoration: underline;
}

.section-11__content {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  margin-bottom: 32px;
}

.section-11__content h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 125%;
  margin-bottom: 8px;
}

.section-11__content .hr-underline {
  margin-left: 0;
  margin-bottom: 16px;
  background-color: var(--primary);
  opacity: 1;
}

.section-11__content p {
  margin-bottom: 5px;
}

.section-11__content p span a {
  cursor: pointer;
  color: var(--primary);
}

.section-11__content li {
  margin-bottom: 5px;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
}

.section-11__content li::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-image: url("../../assets/vector/green-tick.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-11 .img-container {
  position: relative;
  max-height: 140px;
  overflow: hidden;
  display: flex;
  gap: 25px;
  justify-content: center;
}

.client-image-row-1,
.client-image-row-2,
.client-image-row-3 {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  flex-flow: row wrap;
  width: 100%;
}

.section-11 .img-container .fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right,
      var(--vl-blue),
      transparent 20% 80%,
      var(--vl-blue));
}

.section-11__img.mob {
  height: 100%;
  width: auto;
  transform: translate(-10%, 0);
}

.section-11__img.desk {
  display: none;
}

.client_image {
  max-width: 90px;
  max-height: 40px;
}

.mx-height-30 {
  max-height: 30px;
}

.client_image img {
  width: 100%;
  height: 100%;
}

/* added */
.section-11 .img-container-new-added {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  max-height: max-content;
  gap: 25px;
  width: 45%;
  height: max-content;
  align-items: center;
  justify-content: center;
}

.client-image-mywrap {
  max-height: 180px;
  height: 180px;
  width: 180px;
  padding: 16px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clientRectImg {
  height: 48%;
}
.sbiclient {
  height: 35%;
}
.clientSquareImg {
  height: 85%;
}

/* .client-image-mywrap img {
  height: 40%;
  width: 82%;
} */
@media screen and (max-width: 826px) {
  .section-11 .img-container-new-added {
    width: 100%;
  }
}

@media screen and (min-width: 650px) {
  .section-11 {
    padding: 30px 30px;
  }

  .section-11 .wrapper {
    display: flex;
    align-items: center;
    /* max-width: 700px; */
    column-gap: 32px;
  }

  .section-11__content,
  .section-11 .img-container {
    flex: 1;
  }

  .section-11__content {
    margin-bottom: 0;
  }

  .section-11__img.mob {
    display: none;
  }

  .section-11__img.desk {
    display: block;
    width: 100%;
    height: auto;
    max-height: 495px;
  }

  .section-11 .img-container .fade {
    background-image: linear-gradient(var(--vl-blue),
        transparent 20% 80%,
        var(--vl-blue));
  }

  .client-image-row-1 {
    justify-content: center;
  }

  .mx-width-50 {
    max-width: 80px;
  }
}

@media screen and (min-width: 992px) {
  .section-11 .wrapper {
    /* max-width: 1260px; */
    width: 100%;
  }

  .section-11__content {
    font-size: 18px;
    line-height: 167%;
    padding: 100px 0;
  }

  .section-11__content h1 {
    font-size: 40px;
    margin-bottom: 16px;
  }

  .section-11__content p {
    margin-bottom: 16px;
  }

  .section-11__content li {
    margin-bottom: 8px;
  }

  .section-11__content li::before {
    width: 20px;
    height: 20px;
  }

  .section-11 .img-container {
    max-height: max-content;
    gap: 50px;
  }

  .client-image-row-1,
  .client-image-row-2,
  .client-image-row-3 {
    gap: 20px;
    flex-flow: column wrap;
    width: 100%;
    max-height: 500px;
  }

  .client_image {
    max-width: 200px;
    max-height: 150px;
  }

  /* .client_image:nth-child(n + 11){
  display: none;
} */

  .mx-width-80 {
    max-width: 80px;
  }

  .mx-width-100 {
    max-width: 100px;
  }



  .mx-width-175 {
    max-width: 175px;
  }
}

@media screen and (min-width: 1240px) {
  .section-11 .wrapper {
    padding: 0 135px;
    padding-right: 0px;
  }

  .mx-width-90 {
    max-width: 90px;
  }
}

@media screen and (min-width: 1400px) {
  .client_image:nth-child(n + 11) {
    display: block;
  }

  .mx-width-120 {
    max-width: 120px;
  }

  .mx-width-150 {
    max-width: 150px;
  }
}