.row {
    display: flex;
    flex-wrap: wrap;
}
a {text-decoration: none;}


.job-details {width: 100%; margin: auto; padding: 80px 20px;}
.job-details h1 {font-size: 40px; font-weight: 700; line-height: 52px; margin-bottom: 20px; text-align: center;}
.job-details .line {width: 60px; height: 2px; background: rgba(172, 181, 189, 1); margin: auto;}
.first-line {margin-top: 40px; display: flex; justify-content: space-between; align-items: center;}
.first-line h2 {font-size: 32px; font-weight: 600; line-height: 40px; margin-bottom: 10px;}
.first-line span {font-size: 16px; font-weight: 700; line-height: 28px; color: rgba(34, 187, 102, 1);}
.job-details span {font-size: 13px; line-height: 16px; color: rgba(42, 42, 42, 1); display: inline-block; margin-bottom: 40px;}
.job-details p {font-size: 15px; line-height: 23px; color: #2A2A2A;  margin-bottom: 20px;}
.job-details h6 {font-size: 16px; font-weight: 700; line-height: 23px; color: #2A2A2A;  margin-bottom: 5px;}
.job-details ul {margin-left: 15px; margin-bottom: 20px;}
.job-details li {font-size: 15px; line-height: 23px; color: #2A2A2A;}
.applyjob{text-align: center;}
.applyjob span a {color: white}
.applyjob span {font-size: 18px; font-weight: 600; line-height: 20px; letter-spacing: 0em; border: 2px solid #22BB66; padding: 15px 25px; background-color: rgba(34, 187, 102, 1); color: #fff; border-radius: 8px;    text-decoration: none !important; display: inline-block;  margin-top: 30px;}
/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width:1365px) {}

@media screen and (max-width:1023px) {
    .job-details h1 {font-size: 35px; line-height: 45px;}
    .job-details {padding: 40px;}
}

@media screen and (max-width:767px) {
    .first-line h2 {font-size: 26px; line-height: 30px;}
    .job-details span {margin-bottom: 20px;}
}

@media screen and (max-width:574px) {
    .job-details h1  {font-size: 30px; line-height: 35px;}
    .applyjob span {font-size: 16px; padding: 12px 20px;}
}

@media screen and (max-width:479px) {
    .job-details {padding: 20px;}
    .first-line {margin-top: 20px;}
    .first-line {flex-direction: column-reverse; align-items: flex-start;}
    .first-line span {margin-left: auto;}
}