/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap"); /* Mulish */
@import url("https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap"); /* Asap */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.wrap {
    padding: 4rem 2rem 1rem 6rem;
}
.sec {
    margin-bottom: 3rem !important;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 85%;
    margin: auto;
}
.box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.card {
    height: 18vh;
    width: 16vw;
    padding: 1.25rem;
    margin-right: 12px;
    margin-bottom: 4px;
    border-radius: 12px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: 5px solid rgba(34, 187, 102, 1);
}
.catname {
    font-size: 1.5rem;
    font-weight: 700;
    color: rgba(34, 187, 102, 1);
    text-align: center;
}
.name {
    font-size: .9rem;
    font-weight: 600;
    color: #1956b4;
    margin-bottom: 12px;
    font-family: 'Poppins',sans-serif;
}
.des {
    font-size: .8rem;
    white-space: pre-wrap;
    margin-bottom: 0;
    line-height: 16px;
    font-family: 'Poppins',sans-serif;
}
.line {
    width: 55px;
    height: 2px;
    background-color: rgba(34, 187, 102, 1);
    margin : auto;
    margin-bottom: 24px !important;
}
.line2{
    width: 73%;
    height: 2px;
    background-color: rgba(34, 187, 102, 1);
    margin: auto;
    margin-bottom: 2px;
}
.exploretext {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 12px;
}
.topsec {
    margin: auto;
    width: 80%;
    text-align: center;
}
.line3 {
    width: 94px;
    height: 5px;
    background-color: rgba(34, 187, 102, 1);
    margin: auto;
    margin-bottom: 80px;
}
.btnbox {
    width: 13%;
    margin: auto;
}
.brbtn {
    margin-bottom: 3rem;
    transition: all .2s ease-in;
}
.brbtn {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    border: 2px solid #22bb66;
    padding: 15px 25px;
    background-color: white;
    border-radius: 8px;
    text-decoration: none !important;
}
.brbtn:hover {
    background-color: #22bb66;
}
.brbtn:hover.brbtn a {
    color: white;
}
.brbtn a {
    color: rgba(34, 187, 102, 1);
}
@media screen and (max-width:1292px) {
    .card {
        width: 22vw;
    }
}
@media screen and (max-width:1260px) {
    .card {
        width: 27vw;
    }
}
@media screen and (max-width:1170px) {
    .card {
        width: 25vw;
    }
}
@media screen and (max-width:1170px) {
    .card {
        height: 20vh;
    }
}
@media screen and (max-width:808px) {
    .card {
        width: 38vw;
    }
}
@media screen and (max-width:710px) {
    .card {
        width: 72vw;
    }
}
@media screen and (max-width:1390px) {
    .btnbox {
        width: 20%;
    }
}
@media screen and (max-width:950px) {
    .btnbox {
        width: 28%;
    }
}
@media screen and (max-width:720px) {
    .btnbox {
        width: 45%;
    }
}
@media screen and (max-width:500px) {
    .btnbox {
        width: 80%;
    }
}