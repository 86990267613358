/* #region  Core Team Section */
.core-team {
  padding: 80px 0;
  display: flex;
  background: #e9f8f0;
}
.core-team .wrapper {
  display: flex;
  padding-left: 6rem;
  width: 100%;
}
.core-team .wrapper h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  text-align: center;
  color: #050302;
  margin-bottom: 60px;
}
.left-md.desktop {
  display: block ;
}
.desktop {
  display: flex ;
}
.mobile {
  display: none ;
}
.core-team .wrapper .card {
  background: #ffffff;
  box-shadow: 0px 0px 13px 4px rgba(71, 74, 84, 0.1);
  border-radius: 8px;
  margin: 10px;
}
.core-team .wrapper .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.core-team .wrapper .col-md-4 {
  width: 18%;
}
.core-team .wrapper .card .profilePic {
  width: 100%;
}
.core-team .wrapper .card .pad24 {
  padding: 24px;
}
.core-team .wrapper .card .pad24 .title {
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #050302;
  width: 100%;
}
.core-team .wrapper .card .pad24 .designation {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;

  color: #22bb66;
}

.images_wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team_image{
  max-height: 200px;
  overflow: hidden;
}
/* #endregion */

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1023px) {
  .core-team .wrapper{
    padding:0 20px;
  }
  .mobile{
    display: flex ;
    margin-bottom: 20px;
  }
  .desktop{
    display: none ;
  }

  .core-team .wrapper .col-md-4{
    width: 33%;
  }

  .core-team .wrapper .card{
    width: 210px;
  }
}

@media screen and (max-width: 767px) {
  .core-team .wrapper h1{
    font-size: 30px;
    line-height: 39px;
  }
}

@media screen and (max-width: 574px) {
  .core-team .wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  .core-team__carousel__container > * {
    margin-right: 0px;
  }

  .core-team__carousel__prev-control,
  .core-team__carousel__next-control {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
    transform: translateY(-150px);
  }

  .core-team__carousel__prev-control {
    left: 0;
  }

  .core-team__carousel__next-control {
    right: 0;
  }

  .core-team .wrapper .col-md-4 {
    width: 80%;
  }

  .team_cards{
    width: calc(100% - 20px) !important; 
  }
}

@media screen and (max-width: 479px) {
}
