.product-slide {
  padding: 30px 0;
  background-color: rgba(34, 187, 102, 0.1);
}

.product-slide__cover-img {
  /* margin-bottom: 8px; */
  width: 100%;
  max-height: 100px;
  object-fit: cover;
  object-position: center center;
  /* max-height: 500px; */
}

.product-slide__text h5 {
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  color: var(--primary);
}

.product-slide__text h3 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: var(--text-dark);
}

.product-slide__text p {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: var(--text-lightGray);
}

.product-slide__more-btn {
  margin-bottom: 24px;
  /* width: 150px;
  height: 30px; */
  padding: 0.25rem 0.781rem;
  border-radius: 4px;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.product-slide__more-btn::after {
  display: block;
  /* width: 12px; */
  /* height: 8px; */
  margin-left: 0.5rem;
  line-height: 16px;
  font-size: 12px;
  content: url("../../assets/vector/white-arrow-right-mob.svg");
}

.product-slide__related-btns {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 12px;
  margin-bottom: 32px;
}

.product-slide__related-btns .btn {
  flex: none;
  padding: 2px 10px;
  max-height: 24px;
  border: 1px solid var(--text-dark);
  border-radius: 4px;
  background-color: transparent;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-dark);
}

.product-slide__related-resources__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}

@media screen and (min-width: 460px) {
  .product-slide {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 720px) {
  .product-slide {
    padding: 60px 0;
  }

  .product-slide__details {
    display: flex;
    column-gap: 30px;
    align-items: flex-start;
  }
  .product-slide__cover-img,
  .product-slide__text {
    flex-basis: 100%;
  }

  .product-slide__cover-img {
    order: 2;
    min-width: 300px;
    max-width: 570px;
    /* height: 368px; */
    max-height: 100%;
    border-radius: 8px;
  }

  .product-slide__text {
    order: 1;
    margin-bottom: 24px;
  }

  .product-slide__text h5 {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .product-slide__text h3 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .product-slide__text p {
    font-size: 16px;
  }

  .product-slide__more-btn {
    /* width: 200px;
    height: 40px; */
    padding: 0.625rem 1.6rem;
    font-size: 16px;
    margin-bottom: 32px;
  }

  .product-slide__more-btn::after{
    margin-left: 0.68rem;
  }

  .product-slide__related-btns {
    margin-bottom: 49px;
  }

  .product-slide__related-btns .btn {
    padding: 5px 10px;
    max-height: 40px;
    font-size: 14px;
  }

  .product-slide__related-resources h3 {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .product-slide {
    padding: 100px 0;
  }

  .product-slide__text h5 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .product-slide__text h3 {
    font-size: 40px;
    margin-bottom: 24px;
  }

  .product-slide__text p {
    font-size: 20px;
  }

  .product-slide__more-btn {
    /* width: 256px;
    height: 52px; */
    padding: 1rem 2.5rem;
    font-size: 1.125rem !important;
    margin-bottom: 1.25rem;
    line-height: 20px;
  }

  .product-slide__more-btn::after{
    margin-left: 0.875rem;
    content: url("../../assets/vector/white-arrow-right-new.svg");
  }

  .product-slide__related-btns {
    margin-bottom: 49px;
  }

  .product-slide__related-btns .btn {
    padding: 10px 20px;
    max-height: 40px;
    font-size: 16px;
  }

  .product-slide__related-resources h3 {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

/* carousel */
.product-slide__carousel {
  display: block;
}

.product-slide__carousel__container > * {
  margin-right: 30px;
}

.product-slide__carousel__prev-control,
.product-slide__carousel__next-control {
  position: absolute;
  width: 60px;
  height: 100%;
  top: 0;
}

.product-slide__carousel__prev-control {
  left: 0;
}

.product-slide__carousel__next-control {
  right: 0;
}
