@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
.contact-strip {
  padding: 7px 135px;
  display: none;
  /* z-index: 1; */
  z-index: 500000000;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

/* wrapper */
.contact-strip .wrapper {
  display: flex;
  justify-content: space-between;
}

/* search bar */
.contact-strip__search-bar {
  display: inline-flex;
  align-items: center;
}

.contact-strip__search-bar::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background-image: url(/static/media/search.81db22da.svg);
  background-position: center center;
}

.contact-strip__search-bar input {
  width: 300px;
  padding: 2px 7px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.contact-strip__search-bar input:focus {
  border-color: var(--gray-400);
}

.contact-strip__search-bar input::placeholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: var(--gray-500);
}

/* details */
.contact-strip__details {
  display: inline-flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: var(--gray-500);
}

.contact-strip__details .number,
.contact-strip__details .email {
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
}

.contact-strip__details .number::before,
.contact-strip__details .email::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.contact-strip__details .number::before {
  background-image: url(/static/media/phone.473dde3e.svg);
}

.contact-strip__details .email::before {
  background-image: url(/static/media/mail1.c37c3488.svg);
}

.contact-strip__details .contact,
.contact-strip__details .support {
  margin-right: 24px;
  background: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: var(--gray-500);
}

.contact-strip__details .language {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: var(--gray-500);
  cursor: pointer;
}

@media screen and (min-width: 1240px) {
  .contact-strip {
    display: block;
  }
}

.nav__sub-grid__card__li{
  display: none;
}

@media screen and (min-width: 768px) {
  .nav__sub-grid__card__h4 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #474a54;
}

.nav__sub-grid__card__ul {
  list-style: none;
}

.nav__sub-grid__card__li {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #050302;
  margin-bottom: 20px;
}

.nav__sub-grid__card__li::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin-left: 22px;
  background-image: url(/static/media/green-chevron-right.8b3eb72d.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
}

.nav__sub-grid{
  display: none;
}

.nav__sub-grid .container{
  display: none;
}

.nav__sub-grid .content{
  display: none;
}

@media screen and (min-width: 768px) {
  .nav__sub-grid {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 60px;
  display: block;
}

.nav__sub-grid .container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  margin-top: 10px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: var(--vl-blue);
}

.nav__sub-grid .content {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding: 10px 0;
}

}
  .navBanner__grid__card {
  position: relative;
  top: 0;
  cursor: pointer;
  border-radius: 8px;
}

.navBanner__grid__card .container {
  display: flex;
  padding: 12px 20px;
  border-radius: 8px;
}

.navBanner__grid__card__h4 {
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

  color: #fff;
}

.navBanner__grid__card__p {
  display: none;
}

.green-dot{
  display: none;
}

.mob-white-dot{
  display: block;
  margin-right: 10px;
}
.sub_grid_top_60{
  top: 60px;
}

@media screen and (min-width: 1024px) {
  .navBanner__grid__card {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
}

.bg-vl-blue {
  background-color: var(--vl-blue);
}

.navBanner__grid__card .container {
  display: flex;
  padding: 12px 20px;
  border-radius: 8px;
}

.green-dot {
  display: block;
  width: 34px;
  height: 34px;
  margin-right: 12px;
}

.mob-white-dot{
  display: none;
}

.navBanner__grid__card__h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--gray-700);
}

.navBanner__grid__card__p {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--gray-500);
}
}
.navBanner__title{
  display: none;
}
@media screen and (min-width: 1024px) {
  .navBanner {
  width: 100%;
  max-width: 2000px;
  position: absolute;
  top: 70px;
  left: 0%;
  z-index: 10;
  /* transform: translate(-50%, 0); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  background-color: var(--gray-100);
}

.navBanner__row-1,
.navBanner__row-2 {
  width: 100%;
  padding: 20px 40px;
}

/* .navBanner__row-1 {
  margin-bottom: 30px;
} */

.navBanner__row-2 {
  background-color: #e9f8f0;
}

.navBanner__title {
  width: 90%;
  max-width: 1030px;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray-500);
}

.navBanner__grid {
  width: 100%;
  max-width: 2000px;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-column-gap: 5px;
  column-gap: 5px;
  grid-row-gap: 5px;
  row-gap: 5px;
}
.navBanner a {
  text-decoration: none;
}
}

.nav__menu{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 0 30px;
}

.nav__link {
  margin: 0 0.5rem;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: var(--gray-100);
  opacity: 0.8;
  text-decoration: none;
  position: relative;
  width: 100%;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

.nav_arrow::after {
  content: url(/static/media/nav_expand_arrow.c4e7c4dd.svg);
  position: absolute;
  right: 0;
}

.rotate::after{
  content: url(/static/media/nav_expand_arrow.c4e7c4dd.svg);
  position: absolute;
  right: 0;
  transform: rotate(180deg);
}

.nav__link.selected,
.nav__link:hover {
  opacity: 1;
  border-bottom: 2px solid white;
}

@media screen and (min-width: 768px) {
  .nav__menu{
    padding: 0 200px;
  }
}

/* nav desktop menu */
@media screen and (min-width: 1024px) {
  .nav__menu {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  margin-right: 16px;
  grid-gap: 0;
  gap: 0;
  padding: 0;
}

.nav__link {
  margin: 0 0.5rem;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: var(--gray-100);
  opacity: 0.8;
  text-decoration: none;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.nav__link.selected,
.nav__link:hover {
  opacity: 1;
}

.nav_arrow::after{
  display: none;
}
.rotate::after{
  display: none;
}
}

/* navbar */
.nav {
  padding: 14px 0;
  background-color: var(--primary);
  width: 100%;
  justify-content: center;
  position: fixed;
  top: 36px;
  z-index: 5000000;
  border-bottom: 2px solid white;
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 1239px) {
  .nav {
    top: 0px;
  }
  .generalmargintop {
    margin-top: 64px;
  } 
}

.expand{
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  z-index: 100;
  transition: all 0.4s ease-in-out;
}

/* nav wrapper */
.nav .wrapper {
  /* max-width: 1400px; */
  padding: 0 30px;
  width: 87%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary);
}

.wrap-expand{
  position: absolute !important;
  width: 100%;
  justify-content: flex-end !important;
  /* padding-top: 8px; */
}

/* nav logo */
.nav__logo {
  width: 45px;
  height: 32px;
  margin-right: 16px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.nav_hidden{
  display: none;
}

/* nav logo light */
.nav__logo--light {
  background-image: url(/static/media/logo-light.e9f866f5.svg);
}

/* nav Hamburger */
.nav__hamburger {
  width: 26px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  cursor: pointer;
}

.nav__hamburger span {
  width: 100%;
  height: 0.15rem;
  background-color: white;
  transition: all 0.4s ease ;
}

.toggle span:nth-of-type(1){
  transform: rotate(-45deg) translate(-4px, 6px);
}

.toggle span:nth-of-type(2){
  display: none;
}

.toggle span:nth-of-type(3){
  transform: rotate(45deg) translate(-4px, -6px);
}

/* nav desktop */
.nav__desktop {
  display: none;
  font-family: Asap;
  font-weight: 600;
  margin-right: 20px;
}

/* nav auth login */
.nav__auth {
  display: none;
  padding: 9px 15px;
  background: var(--primary-dk);
}

/* nav login button */
.nav__login::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 14px;
  background-image: url(/static/media/person-vector.aa51075c.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.desk-nav-container {
  display: none;
}

.deskNavContainer{
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  height: 100%;
  width: 100%;
}

.nav_mob_btns{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 32px;
  gap: 32px;
  margin-top: 30px;
}

.nav_mob_btn{
  padding: 12px 20px !important;
  font-weight: 600 !important;
font-size: 14.4px !important;
line-height: 16px !important;
}
/* my addition  */
@media screen and (max-width: 820px) {
  .nav .wrapper {
    flex-direction: row !important;
   
  }
}
@media screen and (max-width: 1345px) {
  .nav .wrapper {
    width: 92%;
  }
}
@media screen and (max-width: 1280px) {
  .nav .wrapper {
    width: 94%;
  }
}
@media screen and (max-width: 1249px) {
  .fix1 {
    padding: 12px 30px !important;
    border-radius: 0.5rem !important;
    font-size: 0.8rem !important;
    line-height: 100% !important;
  }
  .fix2 {
    padding: 10px 30px !important;
    border-radius: 0.5rem !important;
    font-size: 0.8rem !important;
    line-height: 100% !important;
  }
  .nav__login::before {
    width: 14px !important;
    height: 14px !important;
    margin-right: 8px !important;
  }
}

@media screen and (min-width: 1180px) {
  .expand{
    top: 0;
  }

  .nav {
    padding: 0 20px;
    background-image: linear-gradient(
      to right,
      var(--primary) 50%,
      var(--primary-dk) 50% 100%
    );
    height: 4rem;
  }

  .nav__desktop {
    display: inline-block;
  }

  .nav .wrapper {
    padding: 0;
    margin: 0 auto;
    height: 100%;
  }

  .nav__logo {
    width: 70px;
    height: 46.67px;
  }

  .nav__hamburger {
    display: none;
  }

  .nav_mob_btns{
    display: none;
  }

  .nav__auth {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    height: 100%;
  }

  .desk-nav-container {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

footer {
  padding: 50px 30px;
  background-color: var(--primary);
  color: white;
}

#footer .wrapper {
  position: relative;
}

#footer .about {
  margin-bottom: 28px;
}

#footer .about .text .logo {
  width: 60px;
  height: 42px;
  margin-bottom: 12px;
}
#footer .about .text .logor {
  width: 150px;
  height: 65px;
  margin-bottom: 12px;
}
#footer .about .text .para {
  max-width: 305px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #f8f9fa;
  margin-bottom: 19px;
}

#footer .connect {
  width: 100%;
  max-width: 305px;
  margin-bottom: 26px;
}

#footer .connect h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

#footer .connect .icon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

#footer .connect .FB-icon {
  background-image: url(/static/media/Facebook_white.50c0478d.svg);
}

#footer .connect .LD-icon {
  background-image: url(/static/media/LinkedIN_white.8ede853e.svg);
}

#footer .connect .YT-icon {
  background-image: url(/static/media/Youtube_white.478dfeec.svg);
}

#footer .connect .IG-icon {
  background-image: url(/static/media/Instagram_white.a00b1f9e.svg);
}

#footer .connect .TW-icon {
  background-image: url(/static/media/Twitter_white.f77221f8.svg);
}

#footer .connect .VC-icon {
  background-image: url(/static/media/victory_white.650e613b.svg);
}

#footer .about .imgs {
  width: 100%;
  max-width: 305px;
}

#footer .about .imgs .ssl {
  width: 103.45px;
  height: 51.69px;
}

#footer .about .imgs .iso {
  width: 52.87px;
  height: 52.84px;
}

#footer .about .imgs .hippa {
  width: 97.7px;
  height: 43.07px;
}

#footer .grid {
  display: flex;
  grid-column-gap: 20px;
  column-gap: 20px;
}

#footer .grid .col-1,
#footer .grid .col-2 {
  flex-basis: 100%;
  grid-gap: 16px;
  gap: 16px;
}

#footer .links {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 16px; */
}
#footer .links a {
  display: flex;
  align-items: center;
}
#footer .links a span,#footer .links a img{
  cursor: pointer;
}

#footer .links h2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}

#footer .links a {
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-decoration: none;
  color: white;
  margin-bottom: 4px;
}

@media screen and (min-width: 700px) {
  #footer .wrapper {
    display: flex;
    grid-column-gap: 20px;
    column-gap: 20px;
  }

  #footer .about,
  #footer .grid {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 992px) {
  #footer .about .text .logo {
    width: 75px;
    height: 52px;
    margin-bottom: 24px;
  }
  #footer .about .text .logor {
    width: 150px;
    height: 65px;
    margin-bottom: 24px;
  }

  #footer .about .text .para {
    max-width: 370px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  #footer .about .connect {
    max-width: 370px;
    font-size: 20px;
  }

  #footer .about .connect .icon {
    width: 32px;
    height: 32px;
  }

  #footer .about .imgs {
    max-width: 370px;
  }

  #footer .about .imgs .ssl {
    width: 127.59px;
    height: 63.75px;
  }

  #footer .about .imgs .iso {
    width: 65.21px;
    height: 65.17px;
  }

  #footer .about .imgs .hippa {
    width: 120.5px;
    height: 53.12px;
  }

  /* #footer .links {
    margin-bottom: 40px;
  } */

  #footer .links h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  #footer .links a {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (min-width: 1240px) {
  footer{
    padding: 100px 0;
  }
  #footer .wrapper{
    width: 100%;
    padding: 0 135px;
  }
  #footer .grid .col-1{
    /* justify-content: space-between; */
    grid-gap: 40px;
    gap: 40px;
  }
  
  #footer .grid .col-2{
    /* padding: 0 0 80px; */
    grid-gap: 40px;
    gap: 40px;
  }
}
.section-1 {
  padding: 50px 30px;
  background: url(/static/media/img_2.521c1c3c.webp) no-repeat 0% 52%;
  background-size: cover !important;
}

.section-1 .wrapper {
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  width: 100%;
}

/* heading */
.section-1 .grid-1 {
  max-width: 320px;
  margin-bottom: 30px;
}

.section-1__heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  color: var(--text-primary);
}

/* img */
.section-1 .grid-2 {
  display: flex;
  text-align: center;
  position: relative;
  align-items: center;
}

.section-1__img {
  margin-bottom: 12px;
  width: 100%;
  height: auto;
}

.section-1 .small-img{
  max-width: 300px;
  max-height: 200px;
  z-index: 6;
  position: absolute;
  top: 395px;
  right: 25px;
}

/* content */

.section-1__content {
  text-align: left;
}

.section-1__content h2 {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #050302;
}

.section-1__content p {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #474a54;
}

.section-1-btns{
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.section-1 .btn {
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14.4px;
  line-height: 16px;
  border-radius: 6.4px;
  height: 40px;
}

/* .section-1__content .btn--dark {
  margin-right: 1rem;
  margin-bottom: 1rem;
} */

@media screen and (min-width: 540px) {
  /* .section-1 .wrapper {
    margin: 0 40px;
  } */
}

@media screen and (min-width: 630px) {
  .section-1 .wrapper {
    /* max-width: 765px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "heading img" "content img";
  }

  .section-1 .grid-1 {
    grid-area: heading;
    display: inline-flex;
    align-items: flex-end;
  }

  .section-1__heading {
    margin-bottom: 24px;
  }

  .section-1 .grid-2 {
    grid-area: img;
  }

  .section-1 .grid-3 {
    grid-area: content;
  }

  .section-1__img {
    width: 100%;
    height: auto;
    max-height: 677px;
    padding-left: 12%;
    /* object-position: right bottom; */
  }

  .section-1-btns{
    justify-content: flex-start;
  }
}

@media screen and (min-width: 700px) {
  .section-1 {
    background-size: 50% 60%;
  }
  /* .section-1 .wrapper {
    margin: 0 auto;
  } */
}

@media screen and (min-width: 992px) {
  .section-1 {
    padding: 70px 0;
    background-size: 40% 50%;
  }
  .section-1 .wrapper {
    width: 100%;
  }

  .section-1 .grid-1 {
    max-width: 100%;
  }

  .section-1__heading {
    font-size: 50px;
    line-height: 130%;
    margin-bottom: 40px;
  }

  .section-1__content h2 {
    font-size: 28px;
    line-height: 130%;
  }

  .section-1__content p {
    max-width: 560px;
    font-size: 20px;
    line-height: 160%;
    margin-bottom: 30px;
  }

  .section-1__content .btn {
    padding: 1.5rem 40px;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 125%;
    height: 52px;
  }
}

@media screen and (min-width: 1050px) {
  .section-1 {
    padding: 50px 0;
  }

  .section-1 .wrapper{
    padding: 0 135px;
    padding-right: 75px;
  }

  /* .section-1__img{
    margin-bottom: 120px;
  } */
}

.section-2 {
  padding: 50px 30px;
}

.section-2 .wrapper {
  /* max-width: 362px; */
  /* margin: 0 auto; */
  width: 100%;
}

.section-2__heading {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  color: #050302;
}

.section-2 .img-container {
  position: relative;
  overflow: hidden;
}

.section-2__img {
  width: 100%;
}

.section-2__img.mob {
  max-height: 260px;
}

.section-2__img.desk {
  display: block;
}

.section-2 .fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    #fff,
    hsla(0, 0%, 100%, 0) 20% 80%,
    #fff
  );
}

@media screen and (min-width: 576px) {
  .section-2 .wrapper {
    /* max-width: 765px; */
    max-height: 558px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 30px;
    column-gap: 30px;
  }

  .section-2__heading,
  .section-2 .img-container {
    flex: 1 1;
  }

  .section-2__img.mob {
    display: none;
  }

  .section-2__img.desk {
    display: block;
  }

  .section-2 .fade {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .section-2 .wrapper {
    /* max-width: 1260px; */
    padding: 0 130px;
  }

  .section-2__heading {
    max-width: 500px;
    font-size: 40px;
    line-height: 130%;
    margin-bottom: 30px;
  }

  .section-2__img.mob {
    display: none;
  }

  .section-2__img.desk {
    display: block;
  }

  .section-2 .img-container {
    max-width: 550px;
  }
}

.section-3 {
  padding: 40px 0 50px;
  background-color: var(--vl-blue);
}

.section-3 .wrapper {
  margin: 0 auto;
}

.section-3__heading {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: var(--text-dark);
}

.section-3 .underline {
  margin: 0 auto 8px;
  width: 30px;
  height: 2px;
  background: #050302;
  opacity: 0.5;
  border-radius: 5px;
}

.section-3 .img-container {
  text-align: center;
}

.section-3__img {
  width: 71.5px;
  height: 55px;
  margin: 24px 10px 0;
}

@media screen and (min-width: 780px) {
  .section-3 {
    padding: 60px 30px;
  }

  .section-3__heading {
    margin-bottom: 24px;
    font-size: 36px;
  }

  .section-3 .underline {
    width: 56px;
    margin-bottom: 40px;
  }

  .section-3 .img-container {
    /* max-width: 1050px; */
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap;
  }

  .section-3__img {
    margin: 0;
    /* width: 130px;
    height: 100px; */
    width: 142.60px;
    height: 115px;
  }
}

@media screen and (min-width: 1024px) {
  .section-3 .wrapper{
    padding: 0 135px;
  }

  .section-3 .img-container{
    grid-gap: 20px;
    gap: 20px;
  }

}

@media screen and (min-width: 1240px) {
  .section-3 .wrapper{
    padding: 0 135px;
  }
  .section-3 .img-container {
    grid-gap: 20px;
    gap: 20px;
  }
}

.section-4 {
  padding: 50px 30px;
  text-align: center;
}
.imgh {
  height: 46rem;
}
.section-4 .wrapper {
  position: relative;
  /* max-width: 500px; */
}

.section-4 .heading-1,
.section-4 .heading-2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
}

.section-4 .heading-1 {
  margin-bottom: 10px;
}

.section-4 .heading-2 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}

.section-4 hr {
  background-color: var(--primary);
  margin-bottom: 48px;
}

.section-4 .img {
  width: 100%;
  height: auto;
  max-width: 1000px;
  margin-bottom: 20px;
}

@media screen and (max-width : 1100px) {
  .imgh {
    height: 38rem;
  }
}
@media screen and (max-width : 750px) {
  .imgh {
    height: 32rem;
  }
}
@media screen and (max-width : 620px) {
  .imgh {
    height: 26rem;
  }
}
@media screen and (max-width : 500px) {
  .imgh {
    height: 21rem;
  }
}
@media screen and (max-width : 400px) {
  .imgh {
    height: 17rem;
  }
}
@media screen and (max-width : 320px) {
  .imgh {
    height: 14rem;
  }
}
@media screen and (max-width : 270px) {
  .imgh {
    height: 12rem;
  }
}

@media screen and (min-width: 768px) {
  .section-4 {
    padding: 60px 0;
  }

  .section-4 .wrapper {
    /* max-width: 1260px; */
    padding: 0 40px;
  }

  .section-4 .heading-1,
  .section-4 .heading-2 {
    font-size: 40px;
  }

  .section-4 .heading-1 {
    margin-bottom: 24px;
  }

  .section-4 .heading-2 {
    margin-bottom: 40px;
  }

  .section-4 .hr {
    margin-bottom: 50px;
  }

  .section-4 .btn {
    width: 186px;
    height: 52px;
    font-size: 18px;
  }

  .company-subheading{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .company-subheading p{
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    color: #474A54;
    text-align: center;
    max-width: 82%;
  }
}

@media screen and (min-width: 1024px) {
  .section-4 .wrapper{
    padding: 0 135px;
  }
}

@media screen and (min-width:1240px) {
  .section-4 .wrapper{
    padding: 0 135px;
    width: 100%;
  }
}
.section-5 {
  padding: 50px 30px;
  background-color: var(--vl-blue);
}

.section-5 .wrapper {
  /* max-width: 360px; */
  width: 100%;
}

.section-5__content {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  margin-bottom: 32px;
}

.section-5__content h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 125%;
  margin-bottom: 8px;
}

.section-5__content .hr-underline {
  margin-left: 0;
  margin-bottom: 16px;
  background-color: var(--primary);
  opacity: 1;
}

.section-5__content p {
  margin-bottom: 5px;
}

.section-5__content li {
  margin-bottom: 5px;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
}

.section-5__content li::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-image: url(/static/media/green-tick.3c902f30.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-5 .img-container {
  position: relative;
  height: 72px;
  overflow: hidden;
}

.section-5 .img-container .fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    var(--vl-blue),
    transparent 20% 80%,
    var(--vl-blue)
  );
}

.section-5__img.mob {
  height: 100%;
  width: auto;
  transform: translate(-10%, 0);
}

.section-5__img.desk {
  display: none;
}

.integrations-image-row-1{
  display: flex;
  /* flex-direction: column; */
  grid-gap: 10px;
  gap: 10px;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
}

@media screen and (min-width: 700px) {
  .section-5 {
    padding: 30px 30px;
  }

  .section-5 .wrapper {
    display: flex;
    align-items: center;
    /* max-width: 700px; */
    grid-column-gap: 32px;
    column-gap: 32px;
  }

  .section-5__content,
  .section-5 .img-container {
    flex: 1 1;
  }

  .section-5__content {
    margin-bottom: 0;
  }

  .section-5 .img-container {
    height: auto;
  }

  .section-5__img.mob {
    display: none;
  }

  .section-5__img.desk {
    display: block;
    width: 100%;
    height: auto;
    max-height: 495px;
  }

  .section-5 .img-container .fade {
    background-image: linear-gradient(
      var(--vl-blue),
      transparent 20% 80%,
      var(--vl-blue)
    );
  }

  .integration-img-width{
    max-width: 70px !important;
  }
}

@media screen and (min-width: 1024px) {
  .section-5 .wrapper {
    padding: 0 135px;
  }

  .section-5__content {
    font-size: 18px;
    line-height: 167%;
    padding: 100px 0;
  }

  .section-5__content h1 {
    font-size: 40px;
    margin-bottom: 16px;
  }

  .section-5__content p {
    margin-bottom: 16px;
  }

  .section-5__content li {
    margin-bottom: 8px;
  }

  .section-5__content li::before {
    width: 20px;
    height: 20px;
  }

  .integrations-image-row-1{
  grid-gap: 20px;
  gap: 20px;
  flex-flow: row wrap;
  width: 100%;
  max-height: 500px;
}

.integration-img-width{
    max-width: 80px !important;
  }
}

@media screen and (min-width:1240px) {
  .section-5 .wrapper{
    padding: 0 135px;
    width: 100%;
  }

  .integration-img-width{
    max-width: 90px !important;
  }
}

@media screen and (min-width: 1400px) {
  .integration-img-width{
    max-width: 120px !important;
  }
}
.card {
  position: relative;
  width: 160px;
  background-color: var(--gray-100);
  border-radius: 5px;
  overflow: hidden;
}
.aic {
  align-items: center;
}

.card__img {
  display: block;
  width: 100%;
  height: 84px;
}

.card__info {
  padding: 10px 12px;
  text-align: left;
}

.card__tag {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 2.2px;
  background-color: var(--gray-300);
  padding: 2px 4px;
  font-size: 8px;
  line-height: 1.2;
  color: var(--gray-500);
  vertical-align: top;
}
.card.contact {
  width: 220px;
  background: #ffffff;
  box-shadow: 0px 0px 17px 4px rgba(71, 74, 84, 0.1);
  border-radius: 20px;
  margin: 15px;
  margin-left: 1% !important;
}
.card__title {
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-700);
}

.card__text {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: var(--gray-500);
}

.card__btn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 4px;
  background-color: transparent;
  font-weight: 600;
  font-size: 8px;
  line-height: 7px;
  color: var(--primary);
  cursor: pointer;
}

.card__btn::after {
  content: "";
  display: block;
  width: 8px;
  height: 5px;
  margin-left: 4px;
  background-image: url(/static/media/green-arrow-right.88ef685a.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.card__hover {
  display: none;
}
.card__contactdetails .row {
  margin-top: 15px;
}
.card__contactdetails .row .icon {
  margin-right: 9px;
  height: 10px;
}
.card__contactdetails .row .contact-label {
  font-size: 0.7rem;
  line-height: 8px;
  width: 80%;
  word-wrap: break-word;
}

.video{
  box-shadow: 0px 0px 13px 4px rgba(71, 74, 84, 0.1);
border-radius: 8px;
}
.tage {
  color: #fff !important;
  background-color: #5745c6 !important;
  border-radius: 7px !important;
  padding: 5px 10px !important;
  font-size: 0.8rem !important;
}
@media screen and (min-width: 992px) {
  .card {
    width: 270px !important;
    min-height: 100px;
    border-radius: 8px;
  }
  .card.contact {
    width: 370px;
  }
  .card__img {
    height: 140px;
  }

  .card__info {
    min-height: 141px;
    padding: 14px 24px;
  }

  .card__tag {
    padding: 4px;
    font-size: 12px;
    line-height: 15px;
  }

  .card__title {
    font-size: 15px;
    line-height: 18px;
  }

  .card__text {
    font-size: 0.7rem;
    line-height: 15px;
  }

  /* card with type hover */
  .card.hover .card__text {
    display: none;
  }

  .card__hover {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    background-size: cover;
    background-position: center center;
    padding: 24px;
    transition: transform 500ms ease-in-out;
  }

  .card__hover__title {
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: var(--gray-100);
  }

  .card__hover__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray-100);
  }

  .card:hover .card__hover {
    transform: translate(0, -100%);
  }

  .card__btn {
    
    font-size: 16px;
    line-height: 28px;
  }

  .card__btn::after {
    width: 14px;
    height: 9px;
    margin-left: 8px;
  }
}

.carousel-1 {
    position: relative;
    overflow: hidden;
}

.carousel-1__container {
    position: relative;
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
}

.carousel-1__container > * {
    flex: none;
    margin-right: 30px;
}

.carousel-1__container > .card:first-of-type {
    margin-left: 20px;
}

.carousel-1__container .card__img {
    height: 100%;
    max-height: 290px;
}

.carousel-1__container .card {
    height: 100%;
    max-height: 290px;
}

.carousel-1__container .card:hover {
    height: 100%;
    max-height: 290px;
}

.carousel-1__control-prev,
.carousel-1__control-next {
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.carousel-1__control-prev::after,
.carousel-1__control-next::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
}

.carousel-1__control-prev {
    left: 0;
    padding-left: 6px;
    justify-content: flex-start;
    /*background-image: linear-gradient(to left, transparent, var(--primary) 50%);*/
}

.carousel-1__control-prev::after {
    background-image: url(/static/media/white-arrow-left.675361d8.svg);
}

.carousel-1__control-next {
    right: 0;
    padding-right: 6px;
    justify-content: flex-end;
    /*background-image: linear-gradient(to right, transparent, var(--primary) 50%);*/
}

.carousel-1__control-next::after {
    background-image: url(/static/media/white-arrow-right.cce23244.svg);
}

.carousel-1__control-prev.greenicon::after {
    background-image: url(/static/media/green-chevron-left.08101e61.svg);
}

.backgroundnone {
    background: none !important;
}

.carousel-1__control-next.greenicon::after {
    background-image: url(/static/media/green-chevron-right.8b3eb72d.svg) !important;
}

.margin-left-none {
    margin-left: 0px !important;
}

.prod-extra-pad {
    padding-bottom: 10px;
}

.prod-btn-nav {
    background: linear-gradient(270deg, #FFFFFF 22.21%, rgba(255, 255, 255, 0) 95.32%);
    /* padding-left: 20px; */
}

.carousel-1_dots {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 32px;
}

@media screen and (min-width: 576px) {
    .carousel-1 {
        margin-left: 20px;
    }

    .prod-btn-nav {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .carousel-1 {
        margin-left: 0;
        padding: 0 0 0 80px;
    }

    .carousel-1__container > .card:first-of-type {
        margin-left: 0;
    }

    .carousel-1__control-prev,
    .carousel-1__control-next {
        width: 140px;
    }

    .carousel-1__control-next {
        padding-right: 20px;
        /*background-image: linear-gradient(*/
        /*  to right,*/
        /*  transparent,*/
        /*  hsl(147, 69%, 43%, 0.8) 50%*/
        /*);*/
    }

    .carousel-1__control-prev {
        padding-left: 20px;
        /*background-image: linear-gradient(*/
        /*  to left,*/
        /*  transparent,*/
        /*  hsl(147, 69%, 43%, 0.8) 40%*/
        /*);*/
    }

    .vid-carousel-btns {
        display: none;
    }

    .carousel-1_dots {
        margin-top: 40px;
    }
}

.section-6 {
  padding: 50px 0;
  background-color: var(--primary);
  overflow: hidden;
}

.section-6__heading {
  max-width: 300px;
  margin: 0 auto 16px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: var(--text-light);
}

.section-6__underline {
  width: 50px;
  height: 2px;
  margin: 0 auto 28px;
  background-color: white;
}

.section-6__carousel-container {
  margin-left: 30px;
}

@media screen and (min-width: 992px) {
  .section-6 {
    padding: 100px 0;
  }

  .section-6__heading {
    margin-bottom: 24px;
    max-width: 770px;
    font-size: 40px;
  }

  .section-6__underline {
    margin-bottom: 64px;
  }
}

.section-7 {
  padding: 50px 30px;
  overflow: hidden;
}

.custom_box {
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
}

.section-7 .wrapper {
  /* max-width: 500px; */
  width: 100%;
}

.section-7 .heading {
  margin-bottom: 68px;
}

.section-7 .heading h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  color: var(--text-dark);
}

.section-7 .grid-card {
  display: none;
  position: relative;
  z-index: 2;
  max-width: 264px;
  margin-bottom: 20px;
  padding: 22.84px 22.83px;
  background-color: white;
  box-shadow: 0px 0px 9.26365px 2.85036px rgba(71, 74, 84, 0.1);
  border-radius: 5.70071px;
}

.section-7 .grid-card.mob {
  display: block;
  width: 45%;
}

.section-7 .grid-card img {
  margin-bottom: 6.42px;
  width: 34.25px;
  height: 34.26px;
}

.section-7 .grid-card h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 17.1021px;
  line-height: 110%;
  color: var(--text-dark);
}

.section-7 .grid-card p {
  margin-bottom: 8.57px;
  font-weight: normal;
  font-size: 11.4014px;
  line-height: 175%;
  color: var(--text-lightGray);
}

.section-7 .grid-card a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 11.4014px;
  line-height: 175%;
  color: var(--primary);
  background-color: transparent;
}

.section-7 .grid-card a::after {
  content: "";
  display: block;
  width: 10.09px;
  height: 6.42px;
  margin-left: 6px;
  background-image: url(/static/media/green-arrow-right.88ef685a.svg);
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section-7 .grid-card:nth-child(odd) {
  /* margin-left: auto; */
  margin-right: 30px;
}

.section-7 .wrapper .dot-grid-1,
.section-7 .wrapper .dot-grid-2 {
  width: 94px;
  height: 82px;
  position: absolute;
  z-index: 1;
  background-image: url(/static/media/dot-grid.a3891925.svg);
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section-7 .wrapper .dot-grid-1 {
  top: 148px;
  right: 30px;
}

.section-7 .wrapper .dot-grid-2 {
  bottom: -30px;
  left: 30px;
}

@media screen and ( max-width : 1240px) {
  .section-7 .heading h1 {
    font-size: 25px !important;
  }
}

@media screen and (min-width: 768px) {
  .section-7 {
    display: flex;
    padding: 80px 30px;
    justify-content: center;
  }

  .section-7 .wrapper {
    /* max-width: 1260px; */
    width: 100%;
    display: flex;
 
  }

  .section-7 .grid-card {
    display: block;
    margin: 0;
  }

  .section-7 .heading {
    display: inline-flex;
    /* align-items: center; */
    margin-bottom: 0;
  }

  .section-7 .wrapper .dot-grid-1 {
    top: -40px;
    left: 280px;
  }

  .section-7 .wrapper .dot-grid-2 {
    bottom: -30px;
    left: auto;
    right: 0;
  }
}
@media screen and (max-width:820px) {
  .wrapper {
    flex-direction: column;
  }
  .custom_box {
    margin-top: 4vh;
    width: 100vw;
  }
}
@media screen and (max-width:500px) {
  .custom_box {
    flex-direction: column;
  }
  .section-7 .grid-card.mob {
    width: 88vw;
    max-width: 88vw;
  }
}

@media screen and (min-width: 1050px) {
  .section-7 {
    padding: 100px 30px;
  }

  

  .section-7 .heading h1 {
    font-size: 40px;
    line-height: 130%;
  }

  .section-7 .grid-card {
    max-width: 370px;
    height: 297px;
    padding: 32px;
    margin-bottom: 30px;
  }

  .section-7 .grid-card img {
    width: 48px;
    height: 48px;
    margin-bottom: 9px;
  }

  .section-7 .grid-card h2 {
    font-size: 24px;
    line-height: 110%;
    margin-bottom: 8px;
  }

  .section-7 .grid-card p {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .section-7 .grid-card a {
    font-size: 16px;
  }

  .section-7 .grid-card a::after {
    width: 14.14px;
    height: 9px;
    margin-left: 8px;
  }

  .section-7 .wrapper .dot-grid-1,
  .section-7 .wrapper .dot-grid-2 {
    width: 131.58px;
    height: 115px;
  }

  .section-7 .wrapper .dot-grid-1 {
    top: -70px;
    left: 380px;
  }

  .section-7 .wrapper .dot-grid-2 {
    bottom: -50px;
    right: -20px;
  }
}
@media screen and (min-width: 1240px) {
  .section-7 .wrapper{
    padding: 0 80px;
    padding-right: 0px;
    width: 100%;
    display: flex;
  }
  .section-7 .wrapper .dot-grid-1 {
    top: -70px;
    left: 390px;
  }

  .section-7 .wrapper .dot-grid-2 {
    bottom: -40px;
    right: 0px;
  }
}
/* my code  */
@media screen and (max-width:596px) {
  .section-7 .grid-card:nth-child(odd) {
    /* margin-left: auto; */
    margin-right: 0px;
  }
}
.carousel {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "slide slide slide" "prev nav next";
  grid-row-gap: 20px;
  row-gap: 20px;
}

.carousel__container {
  grid-area: slide;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  overflow: hidden;
  scroll-behavior: smooth;
}

.product-carousel{
  padding: 60px 0;
}

.carousel__container > * {
  flex: none;
}

.carousel__control-prev,
.carousel__control-next {
  display: flex;
  align-items: center;
}

.carousel__control-prev {
  grid-area: prev;
  left: 0;
  justify-content: flex-start;
}

.carousel__control-next {
  grid-area: next;
  right: 0;
  justify-content: flex-end;
}

.carousel__control-prev-svg,
.carousel__control-next-svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.carousel__nav {
  grid-area: nav;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 10.67px;
  column-gap: 10.67px;
}

.carousel__nav-btn {
  width: 5.33px;
  height: 5.33px;
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
}

.carousel__nav-btn.select {
  width: 8px;
  height: 8px;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  display: flex;
  align-items: center;
}

.card-carousel-container{
  display: block !important;
  position: relative;
  overflow: hidden;
}

.card-carousel{
  position: relative;
  display: flex !important;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 40px;
}

.card-carousel .card:first-of-type{
  margin-left: 22px;
}

.card-carousel .card:last-of-type{
  margin-right: 22px;
}

.card-carousel-btns{
  width: 60px;
  position: absolute;
  padding: 0;
  top: 0;
  height: 100%;
}

.card-nav-prev-btn{
  left: 0;
  
}

.card-nav-next-btn{
  right: 0;
}

@media screen and (min-width: 992px) {
  .carousel {
    grid-template-columns: auto;
    grid-template-areas: "prev slide next" "nav nav nav";
  }

  .carousel__container {
    grid-area: slide;
  }

  .carousel__control-prev {
    grid-area: prev;
  }

  .carousel__control-next {
    grid-area: next;
  }

  .carousel__control-prev,
  .carousel__control-next {
    width: 50px;
  }

  .carousel__control-prev-svg,
  .carousel__control-next-svg {
    width: 32px;
    height: 32px;
  }

  .carousel__nav {
    grid-area: nav;
    grid-column-gap: 16px;
    column-gap: 16px;
  }

  .carousel__nav-btn {
    width: 8px;
    height: 8px;
  }

  .carousel__nav-btn.select {
    width: 12px;
    height: 12px;
  }

  .card-carousel{
    grid-gap: 30px;
    gap: 30px;
  }

  .card-carousel-btns{
    width: 140px !important;
    padding-right: 20px;
  }

  .partner-carousel{
    display: grid !important;
  }
}

.product-slide {
  padding: 30px 0;
  background-color: rgba(34, 187, 102, 0.1);
}

.product-slide__cover-img {
  /* margin-bottom: 8px; */
  width: 100%;
  max-height: 100px;
  object-fit: cover;
  object-position: center center;
  /* max-height: 500px; */
}

.product-slide__text h5 {
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  color: var(--primary);
}

.product-slide__text h3 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: var(--text-dark);
}

.product-slide__text p {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: var(--text-lightGray);
}

.product-slide__more-btn {
  margin-bottom: 24px;
  /* width: 150px;
  height: 30px; */
  padding: 0.25rem 0.781rem;
  border-radius: 4px;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.product-slide__more-btn::after {
  display: block;
  /* width: 12px; */
  /* height: 8px; */
  margin-left: 0.5rem;
  line-height: 16px;
  font-size: 12px;
  content: url(/static/media/white-arrow-right-mob.05f5ccd6.svg);
}

.product-slide__related-btns {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 16px;
  column-gap: 16px;
  grid-row-gap: 12px;
  row-gap: 12px;
  margin-bottom: 32px;
}

.product-slide__related-btns .btn {
  flex: none;
  padding: 2px 10px;
  max-height: 24px;
  border: 1px solid var(--text-dark);
  border-radius: 4px;
  background-color: transparent;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-dark);
}

.product-slide__related-resources__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}

@media screen and (min-width: 460px) {
  .product-slide {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 720px) {
  .product-slide {
    padding: 60px 0;
  }

  .product-slide__details {
    display: flex;
    grid-column-gap: 30px;
    column-gap: 30px;
    align-items: flex-start;
  }
  .product-slide__cover-img,
  .product-slide__text {
    flex-basis: 100%;
  }

  .product-slide__cover-img {
    order: 2;
    min-width: 300px;
    max-width: 570px;
    /* height: 368px; */
    max-height: 100%;
    border-radius: 8px;
  }

  .product-slide__text {
    order: 1;
    margin-bottom: 24px;
  }

  .product-slide__text h5 {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .product-slide__text h3 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .product-slide__text p {
    font-size: 16px;
  }

  .product-slide__more-btn {
    /* width: 200px;
    height: 40px; */
    padding: 0.625rem 1.6rem;
    font-size: 16px;
    margin-bottom: 32px;
  }

  .product-slide__more-btn::after{
    margin-left: 0.68rem;
  }

  .product-slide__related-btns {
    margin-bottom: 49px;
  }

  .product-slide__related-btns .btn {
    padding: 5px 10px;
    max-height: 40px;
    font-size: 14px;
  }

  .product-slide__related-resources h3 {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .product-slide {
    padding: 100px 0;
  }

  .product-slide__text h5 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .product-slide__text h3 {
    font-size: 40px;
    margin-bottom: 24px;
  }

  .product-slide__text p {
    font-size: 20px;
  }

  .product-slide__more-btn {
    /* width: 256px;
    height: 52px; */
    padding: 1rem 2.5rem;
    font-size: 1.125rem !important;
    margin-bottom: 1.25rem;
    line-height: 20px;
  }

  .product-slide__more-btn::after{
    margin-left: 0.875rem;
    content: url(/static/media/white-arrow-right-new.6eb05b3e.svg);
  }

  .product-slide__related-btns {
    margin-bottom: 49px;
  }

  .product-slide__related-btns .btn {
    padding: 10px 20px;
    max-height: 40px;
    font-size: 16px;
  }

  .product-slide__related-resources h3 {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

/* carousel */
.product-slide__carousel {
  display: block;
}

.product-slide__carousel__container > * {
  margin-right: 30px;
}

.product-slide__carousel__prev-control,
.product-slide__carousel__next-control {
  position: absolute;
  width: 60px;
  height: 100%;
  top: 0;
}

.product-slide__carousel__prev-control {
  left: 0;
}

.product-slide__carousel__next-control {
  right: 0;
}

.section-8 {
  padding: 50px 0 0;
}

.section-8 .wrapper {
  position: relative;
  padding: 0 30px;
}

.section-8 .heading {
  display: inline;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  padding: 0;
}

.section-8 .demo-btn {
  position: absolute;
  top: 0;
  right: 30px;
  width: 100px;
}

.section-8__nav {
  margin-top: 20px;
  margin-bottom: 16px;
  background-color: transparent;
  overflow: hidden;
  padding-left: 30px;
  display: flex;
}

.section-8 .btn-nav {
  opacity: 1;
  /* margin-right: 14px; */
  /* margin-bottom: 8px; */
  padding: 4px 16px;
  border: 0;
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  color: #474a54;
  opacity: 0.6;
  border-radius: 4px;
}

.section-8 .btn-nav:hover {
  background-color: var(--primary);
  color: var(--gray-100);
}

.section-8 .btn-nav.select {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.section-8 .btn-nav.select:hover {
  color: var(--gray-100);
}

.product-slide .wrapper{
  padding: 0 30px;
}

.section_8__carousel{
  order: 2;
  width: 100%;
  margin: 20px 0;
}

.section_8__carousel__container{
  grid-gap: 2px;
  gap: 2px;
}

.section_8__carousel .section_8__carousel__prev-control, .section_8__carousel .section_8__carousel__next-control{
  transform: translateY(-25px);
  /* background: none !important ; */
  position: absolute;
}

.section_8__carousel__prev-control{
  left: -10px;
}

.section_8__carousel__next-control{
  right: -10px;
}

/* .section_8__carousel__container{
  align-items: center;
} */

@media screen and (min-width: 768px) {
  
  .section-8 .wrapper {
    /* max-width: 1260px;
    margin: 0 auto; */
    padding: 50px 30px;
    width: 100%;
  }

  .section-8 > .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .section-8 .heading {
    order: 1;
    font-size: 32px;
    padding: 0;
  }

  .section-8 .demo-btn {
    position: relative;
    right: 0;

    width: 175px;
    height: 52px;

    order: 3;
    font-weight: 600;
    font-size: 18px;
  }

  .section-8__nav {
    margin: 0;
    order: 2;
  }

  .section-8 .btn-nav {
    border-radius: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }

  .section-8 .btn-nav.select {
    font-weight: 500;
    border: 2px solid var(--primary);
  }
}

@media screen and (min-width: 1033px) {
  .section-8 > .wrapper {
    align-items: center;
  }

  .section-8 .heading{
    padding: 0;
  }

  .section_8__carousel__container{
  grid-gap: 20px;
  gap: 20px;
}
.section_8__carousel .section_8__carousel__prev-control, .section_8__carousel .section_8__carousel__next-control{
  transform: translateY(0);
}

.section_8__carousel__prev-control{
  left: -35px;
}

.section_8__carousel__next-control{
  right: -35px;
}
.section_8__carousel{
  width: 50%;
  margin: 20px 40px 20px 35px;
}
}

@media screen and (min-width: 1240px) {
  .section-8 .wrapper{
    padding: 0 135px;
    width: 100%;
  }

}

@media screen and (min-width: 1400px) {
  .section-8 .heading{
    padding: 0 30px 0 0;
  }

  .section_8__carousel{
    margin: 20px 20px;
  }
  
}
.section-9 {
  padding: 50px 0;
  background-color: var(--primary);
  overflow: hidden;
}

.white-back{
  background-color: #FFF;
}

.section-9-wrapper{
  padding: 0 8px !important;
}

.section-9__heading {
  max-width: 300px;
  margin: 0 auto 16px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: var(--text-light);
}

.vid-heading{
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  color: #050302;
  margin-bottom: 36px;
}

.section-9__underline {
  width: 50px;
  height: 2px;
  margin: 0 auto 28px;
  background-color: white;
}

.section-9__carousel-container {
  margin-left: 30px;
}

@media screen and (min-width: 992px) {
  .section-9 {
    padding: 100px 0;
  }

  .section-9__heading {
    margin-bottom: 24px;
    max-width: 770px;
    font-size: 40px;
  }

  .section-9__underline {
    margin-bottom: 64px;
  }
}
@media screen and (min-width: 1240px) {
  .section-9-wrapper{
    padding: 0 55px !important;
  }
}
.feature-section {
  padding: 50px 30px;
}

.feature-section .wrapper {
  /* max-width: 700px; */
  width: 100%;
}

.feature-section .heading {
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  color: var(--text-dark);
  margin-bottom: 16px;
  text-align: center;
}

.feature-section .hr-underline {
  background-color: var(--primary);
  margin-bottom: 24px;
}

.feature-section p {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: var(--text-lightGray);
}

.feature-section .para {
  margin-bottom: 40px;
  text-align: center;
}

.feature-section .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 40px;
  row-gap: 40px;
}

.feature-section .cell img {
  width: 60px;
  height: 60px;
  margin-bottom: 16px;
}

.feature-section .cell h3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  color: var(--text-dark);
  margin-bottom: 8px;
}

@media screen and (min-width: 500px) {
  .feature-section .grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media screen and (min-width: 1000px) {
  .feature-section {
    padding: 60px 0;
  }

  .feature-section .wrapper {
    /* max-width: 1260px; */
    width: 100%;
  }

  .feature-section .heading {
    font-size: 36px;
    margin-bottom: 24px;
  }

  .feature-section .hr-underline {
    margin-bottom: 32px;
  }

  .feature-section p {
    font-size: 18px;
  }

  .feature-section .para {
    margin: 0 auto 64px;
    max-width: 928px;
  }

  .feature-section .grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 30px;
    column-gap: 30px;
    grid-row-gap: 56px;
    row-gap: 56px;
  }

  .feature-section .cell img {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }

  .feature-section .cell h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 1240px) {
  .feature-section .wrapper{
    padding: 0 135px;
    width: 100%;
  }
  .feature-section .heading{
    width: 100%;
    padding: 0 280px;
  }
}
.section-11 {
  padding: 50px 30px;
  background-color: var(--vl-blue);
}

.section-11 .wrapper {
  /* max-width: 360px; */
  width: 100%;
}

.sec11spanreadmore {
  font-weight: bold;
  margin-left: 2px;
  text-decoration: underline;
}

.section-11__content {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  margin-bottom: 32px;
}

.section-11__content h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 125%;
  margin-bottom: 8px;
}

.section-11__content .hr-underline {
  margin-left: 0;
  margin-bottom: 16px;
  background-color: var(--primary);
  opacity: 1;
}

.section-11__content p {
  margin-bottom: 5px;
}

.section-11__content p span a {
  cursor: pointer;
  color: var(--primary);
}

.section-11__content li {
  margin-bottom: 5px;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
}

.section-11__content li::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-image: url(/static/media/green-tick.3c902f30.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-11 .img-container {
  position: relative;
  max-height: 140px;
  overflow: hidden;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  justify-content: center;
}

.client-image-row-1,
.client-image-row-2,
.client-image-row-3 {
  display: flex;
  /* flex-direction: column; */
  grid-gap: 10px;
  gap: 10px;
  flex-flow: row wrap;
  width: 100%;
}

.section-11 .img-container .fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right,
      var(--vl-blue),
      transparent 20% 80%,
      var(--vl-blue));
}

.section-11__img.mob {
  height: 100%;
  width: auto;
  transform: translate(-10%, 0);
}

.section-11__img.desk {
  display: none;
}

.client_image {
  max-width: 90px;
  max-height: 40px;
}

.mx-height-30 {
  max-height: 30px;
}

.client_image img {
  width: 100%;
  height: 100%;
}

/* added */
.section-11 .img-container-new-added {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  max-height: -webkit-max-content;
  max-height: max-content;
  grid-gap: 25px;
  gap: 25px;
  width: 45%;
  height: -webkit-max-content;
  height: max-content;
  align-items: center;
  justify-content: center;
}

.client-image-mywrap {
  max-height: 180px;
  height: 180px;
  width: 180px;
  padding: 16px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clientRectImg {
  height: 48%;
}
.sbiclient {
  height: 35%;
}
.clientSquareImg {
  height: 85%;
}

/* .client-image-mywrap img {
  height: 40%;
  width: 82%;
} */
@media screen and (max-width: 826px) {
  .section-11 .img-container-new-added {
    width: 100%;
  }
}

@media screen and (min-width: 650px) {
  .section-11 {
    padding: 30px 30px;
  }

  .section-11 .wrapper {
    display: flex;
    align-items: center;
    /* max-width: 700px; */
    grid-column-gap: 32px;
    column-gap: 32px;
  }

  .section-11__content,
  .section-11 .img-container {
    flex: 1 1;
  }

  .section-11__content {
    margin-bottom: 0;
  }

  .section-11__img.mob {
    display: none;
  }

  .section-11__img.desk {
    display: block;
    width: 100%;
    height: auto;
    max-height: 495px;
  }

  .section-11 .img-container .fade {
    background-image: linear-gradient(var(--vl-blue),
        transparent 20% 80%,
        var(--vl-blue));
  }

  .client-image-row-1 {
    justify-content: center;
  }

  .mx-width-50 {
    max-width: 80px;
  }
}

@media screen and (min-width: 992px) {
  .section-11 .wrapper {
    /* max-width: 1260px; */
    width: 100%;
  }

  .section-11__content {
    font-size: 18px;
    line-height: 167%;
    padding: 100px 0;
  }

  .section-11__content h1 {
    font-size: 40px;
    margin-bottom: 16px;
  }

  .section-11__content p {
    margin-bottom: 16px;
  }

  .section-11__content li {
    margin-bottom: 8px;
  }

  .section-11__content li::before {
    width: 20px;
    height: 20px;
  }

  .section-11 .img-container {
    max-height: -webkit-max-content;
    max-height: max-content;
    grid-gap: 50px;
    gap: 50px;
  }

  .client-image-row-1,
  .client-image-row-2,
  .client-image-row-3 {
    grid-gap: 20px;
    gap: 20px;
    flex-flow: column wrap;
    width: 100%;
    max-height: 500px;
  }

  .client_image {
    max-width: 200px;
    max-height: 150px;
  }

  /* .client_image:nth-child(n + 11){
  display: none;
} */

  .mx-width-80 {
    max-width: 80px;
  }

  .mx-width-100 {
    max-width: 100px;
  }



  .mx-width-175 {
    max-width: 175px;
  }
}

@media screen and (min-width: 1240px) {
  .section-11 .wrapper {
    padding: 0 135px;
    padding-right: 0px;
  }

  .mx-width-90 {
    max-width: 90px;
  }
}

@media screen and (min-width: 1400px) {
  .client_image:nth-child(n + 11) {
    display: block;
  }

  .mx-width-120 {
    max-width: 120px;
  }

  .mx-width-150 {
    max-width: 150px;
  }
}
.testimonial-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 200px));
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 16px;
  row-gap: 16px;
  grid-template-areas: "img details" "text text";
}

.testimonial-card__img {
  grid-area: img;
  display: block;
  width: 100%;
  min-width: 100px;
  height: auto;
  min-height: 100px;
  border-radius: 30px;
  object-fit: cover;
}

.testimonial-card__auth {
  grid-area: details;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial-card__auth h5 {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #212429;
  margin-bottom: 8px;
}

.testimonial-card__auth p {
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #495057;
  width: 7rem;
  word-break: break-word;
}

.testimonial-card__text {
  grid-area: text;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #495057;
  width: 27rem;
  word-break: break-word;
}

.testimonial-card__text::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  background-image: url(/static/media/greyquotes.ea144947.svg);
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media screen and (max-width: 600px) {
  .testimonial-card__text {
    width: 275px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 800px) {
  .testimonial-card {
    grid-column-gap: 30px;
    column-gap: 30px;
    grid-row-gap: 32px;
    row-gap: 32px;
    grid-template-areas: "img text" "img details";
    grid-template-columns: 2fr 3fr;
    margin-bottom: 42px;
  }

  .testimonial-card__img {
    grid-area: img;
  }

  .testimonial-card__auth {
    grid-area: details;
  }

  .testimonial-card__auth h5 {
    font-size: 28px;
    line-height: 30px;
  }

  .testimonial-card__auth p {
    font-size: 16px;
    line-height: 30px;
    width: 15rem;
    word-break: break-word;
  }

  .testimonial-card__text {
    grid-area: text;
    font-size: 18px;
    line-height: 30px;
  }

  .testimonial-card__-text::before {
    width: 30px;
    height: 30px;
  }
}

.testimonial-section {
  padding: 50px 30px;
  overflow: hidden;
}

.testimonial-section .heading {
  /* max-width: 640px; */
  width: 100%;
  margin: 0 auto 16px;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: var(--text-dark);
}

.testimonial-section h3 {
  font-weight: 700;
  font-size: 19.5px;
  line-height: 25px;
  color: var(--text-dark);
  text-align: center;
  margin-bottom: 16px;
}

.testimonial-section .hr-underline {
  background-color: var(--primary);
  margin-bottom: 24px;
}

.testimonial-section .container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonial-section .container .green-grid {
  display: none;
}

.testimonial-section .content {
  display: flex;
  justify-content: center;
  margin-bottom: 29px;
  max-width: 420px;
}

.testimonial-section .test-card {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 200px));
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 16px;
  row-gap: 16px;
  grid-template-areas: "img details" "text text";
}

.testimonial-section .test-card img {
  grid-area: img;
  display: block;
  width: 100%;
  min-width: 100px;
  height: auto;
  min-height: 100px;
  border-radius: 30px;
  object-fit: cover;
}

.testimonial-section .auth-details {
  grid-area: details;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial-section .auth-details h5 {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #212429;
  margin-bottom: 8px;
}

.testimonial-section .auth-details p {
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #495057;
}

.testimonial-section .auth-text {
  grid-area: text;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #495057;
}

.testimonial-section .auth-text::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  background-image: url(/static/media/greyquotes.cf52df97.webp);
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.testimonial-section .container-nav {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 420px;
}

.testimonial-section .container-nav .prev,
.testimonial-section .container-nav .next {
  width: 10px;
  height: 15px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}

.testimonial-section .container-nav .prev {
  background-image: url(/static/media/green-chevron-left.1b7fbab6.webp);
}

.testimonial-section .container-nav .next {
  background-image: url(/static/media/green-chevron-right.0f326636.webp);
}

.testimonial-section .container-nav .dots {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.testimonial-section .container-nav .dot-nav {
  width: 5.33px;
  height: 5.33px;
  border-radius: 50%;
  background: #e7dfdd;
}

.testimonial-section .container-nav .dot-nav.select {
  width: 8px;
  height: 8px;
  background: var(--primary);
  opacity: 1;
}

@media screen and (min-width: 800px) {
  .testimonial-section {
    padding: 100px 0;
  }

  .testimonial-section .heading{
    padding: 0 40px;
  }

  .testimonial-section .heading {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 16px;
    /* max-width: 770px; */
    width: 100%;
  }

  .testimonial-section h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 30px;
  }

  .testimonial-section .hr-underline {
    width: 56px;
    margin-bottom: 32px;
  }

  .testimonial-section .container{
    padding:  0 20px;
  }

  .testimonial-section .container .green-grid {
    display: block;
    position: absolute;
    z-index: 1;
    top: -40px;
    left: 115px;
  }

  .testimonial-section .content {
    position: relative;
    z-index: 2;
    max-width: 1000px;
  }

  .testimonial-section .content .test-card {
    max-width: 1000px;
  }

  .testimonial-section .test-card {
    grid-column-gap: 30px;
    column-gap: 30px;
    grid-row-gap: 32px;
    row-gap: 32px;
    grid-template-areas: "img text" "img details";
    grid-template-columns: 2fr 3fr;
    margin-bottom: 42px;
  }

  .testimonial-section .test-card img {
    grid-area: img;
  }

  .testimonial-section .auth-details {
    grid-area: details;
  }

  .testimonial-section .auth-details h5 {
    font-size: 28px;
    line-height: 30px;
  }

  .testimonial-section .auth-details p {
    font-size: 18px;
    line-height: 30px;
  }

  .testimonial-section .auth-text {
    grid-area: text;
    font-size: 18px;
    line-height: 30px;
  }

  .testimonial-section .auth-text::before {
    width: 30px;
    height: 30px;
  }

  .testimonial-section .container-nav {
    justify-content: center;
  }

  .testimonial-section .container-nav .dots {
    grid-column-gap: 16px;
    column-gap: 16px;
  }

  .testimonial-section .container-nav .dot-nav {
    width: 8px;
    height: 8px;
  }

  .testimonial-section .container-nav .dot-nav.select {
    width: 12px;
    height: 12px;
  }

  .testimonial-section .container-nav .prev,
  .testimonial-section .container-nav .next {
    position: absolute;
    top: 0;
    width: 15px;
    height: 100%;
  }

  .testimonial-section .container-nav .prev {
    left: 0;
  }

  .testimonial-section .container-nav .next {
    right: 0;
  }
}
@media screen and (min-width: 1240px) {
  .testimonial-section .wrapper{
    padding: 0 135px;
  }
  .testimonial-section .heading{
    width: 100%;
    padding: 0 230px;
  }
}
.resource-section {
  padding: 50px 30px;
  background-color: rgba(34, 187, 102, 0.1);
  overflow: hidden;
}

.resource-section .wrapper {
  /* max-width: 700px; */
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resource-section .heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  color: var(--text-dark);
  margin-bottom: 16px;
}

.resource-section .hr-underline {
  background-color: var(--text-dark);
  margin-bottom: 32px;
}

.resource-section .main-card {
  /* max-width: 300px; */
  width: 100%;
  text-align: left;
  margin-bottom: 28px;
  border-radius: 8px;
  overflow: hidden;
}

.resource-section .main-card .main-card-img {
  display: block;
  width: 100%;
  height: 330px;
}

.resource-section .main-card .text {
  padding: 14px 16px 12px;
  background-color: white;
  border-radius: 0 0 8px 8px;
}

.resource-section .main-card .tag {
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary);
  margin-bottom: 8px;
}

.resource-section .main-card h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: var(--text-dark);
  margin-bottom: 12px;
}

.resource-section .main-card .details {
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: var(--text-lightGray);
  margin-bottom: 16px;
}

.resource-section .main-card .date {
  font-weight: normal;
  font-size: 8px;
  line-height: 30px;
  color: va(--text-lightGray);
}

.resource-section__carousel {
  margin-bottom: 28px;
}

.resource-section .btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
}

.resource-section__view-more-btn {
  display: none !important;
  margin-top: 30px;
}

@media screen and (min-width: 576px) {
  .resource-section .main-card {
    display: flex;
    /* max-width: 1100px; */
  }

  .resource-section .main-card .img-container,
  .resource-section .main-card .text {
    flex-basis: 100%;
  }

  .resource-section .main-card .main-card-img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }

  .resource-section .main-card .img-container {
    order: 2;
  }

  .resource-section .main-card .text {
    order: 1;
    padding: 32px 40px;
  }
}

@media screen and (min-width: 992px) {
  .resource-section {
    padding: 100px 0;
  }

 

  .resource-section .heading {
    font-size: 36px;
    margin-bottom: 24px;
  }

  .resource-section .hr-underline {
    margin-bottom: 32px;
  }

  .resource-section .main-card {
    margin-bottom: 50px;
  }

  .resource-section .main-card .img-container {
    order: 2;
    border-radius: 0 8px 8px 0;
    overflow: hidden;
  }

  .resource-section .main-card .text {
    border-radius: 8px 0 0 8px;
    order: 1;
    padding: 32px 40px;
  }

  .resource-section .main-card .tag {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .resource-section .main-card h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 10px;
  }

  .resource-section .main-card .details {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .resource-section .main-card .date {
    font-size: 15px;
    line-height: 30px;
  }

  .resource-section__view-more-btn {
    display: inline-flex !important;
    margin-top: 40px;
  }
}

/* carousel */
#resource-section__carousel {
  display: block;
}

.resource-section__carousel__container > * {
  margin-right: 30px;
}

.resource-section__carousel__prev-control,
.resource-section__carousel__next-control {
  position: absolute;
  width: 60px;
  height: 100%;
  top: 0;
}

.resource-section__carousel__prev-control {
  left: 0;
}

.resource-section__carousel__next-control {
  right: 0;
}

@media screen and (min-width: 1240px){
  .resource-section .wrapper{
    width: 100%;
    padding: 0 135px;
  }
}
.news-letter {
  padding: 50px 30px;
  background-image: url(/static/media/news-letter.fdefd1ef.webp);
  background-size: cover;
  background-position: center center;
  text-align: center;
}
.marginTop {
  margin-top: 50px;
}

.product-tour{
  background-image: url(/static/media/product-banner.5af16d44.png);
}

.news-letter .wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.news-letter .heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  color: white;
  margin-bottom: 16px;
  max-width: 320px;
}

.news-letter .hr-underline {
  background-color: white;
  margin-bottom: 24px;
}

.news-letter .btn--dark {
  background-color: white;
  color: var(--text-dark);
  padding: 12px 17px;
  border-radius: 6.4px;
  font-size: 14.4px;
  line-height: 16px;
}

.news-letter .btn--toggle {
  border-color: white;
  color: white;
  padding: 12px 17px;
  border-radius: 6.4px;
  background-color: transparent;
  font-size: 14.4px;
  line-height: 16px;
}

.news-letter .btn--toggle:hover {
  background-color: white;
  color: var(--text-dark);
}

.news-letter .flex {
  margin-bottom: 32px;
  grid-gap: 16px;
  gap: 16px;
}

.news-letter .hr {
  width: 100%;
  max-width: 450px;
  height: 2px;
  background: #c4c4c4;
  opacity: 0.5;
  margin-bottom: 24px;
}

.news-letter h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  color: white;
  margin-bottom: 24px;
}

.news-letter .container{
  width: 100%;
}

.news-letter form {
  width: 100%;
  max-width: 450px;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(71, 74, 84, 0.3);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.news-letter form input {
  flex-grow: 1;
}

.news-letter form .icon {
  width: 21px;
  height: 21px;
  background-image: url(/static/media/green-plane.2ed98eca.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  outline: none;
  border: none;
  filter: contrast(200);
}

.news-letter form .icon:disabled{
  filter: contrast(0);
}

.news-letter form input::placeholder {
  font-weight: 600;
  font-size: 12px;
  line-height: 165%;
  color: rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px) {
  .news-letter .heading {
    font-size: 36px;
    margin-bottom: 24px;
    max-width: 570px;
  }

  .news-letter .hr-underline {
    width: 50px;
    margin-bottom: 32px;
  }

  .news-letter .btn--dark {
    padding: 16px 40px;
    font-size: 18px;
    margin-right: 30px;
    border-radius: 8px;
    line-height: 20px;
  }

  .news-letter .btn--toggle {
    padding: 16px 40px;
    font-size: 18px;
    border-radius: 8px;
  }

  .news-letter .flex {
    margin-bottom: 50px;
  }

  .news-letter .hr {
    margin-bottom: 50px;
    max-width: 100%;
  }

  .news-letter .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 20px;
    column-gap: 20px;
  }

  .news-letter h2 {
    flex: none;
    font-size: 28px;
    margin: 0;
  }
}

@media screen and (min-width: 1000px) {
  .news-letter h2 {
    font-size: 36px;
  }
}

@media screen and (min-width: 1240px) {
  .news-letter .wrapper{
    padding: 0 135px;
    width: 100%;
  }
}

.section-6 {
    padding: 50px 0;
    background-color: var(--gray-100);
    overflow: hidden;
}

.section-6__heading {
    max-width: 300px;
    margin: 0 auto 16px;
    margin-bottom: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: var(--primary);
}

.section-6__underline {
    width: 50px;
    height: 2px;
    margin: 0 auto 28px;
    background-color: white;
}

.section-6__carousel-container {
    margin-left: 30px;
}

.section_8__carousel .section_8__carousel__prev-control, .section_8__carousel .section_8__carousel__next-control {
    transform: translateY(-25px);
    /* background: none !important ; */
    position: absolute;
}

.section_8__carousel__prev-control {
    left: -10px;
}

.section_8__carousel__next-control {
    right: -10px;
}

@media screen and (min-width: 992px) {
    .section-6 {
        padding: 100px 0;
    }

    .section-6__heading {
        margin-bottom: 24px;
        max-width: 770px;
        font-size: 40px;
    }

    .section-6__underline {
        margin-bottom: 64px;
    }
}

.boxcard .grid-card {
  display: none;
  position: relative;
  z-index: 2;
  max-width: 350px;
  margin-bottom: 20px;
  padding: 22.84px 22.83px;
  background-color: white;
  box-shadow: 0px 0px 9.26365px 2.85036px rgba(71, 74, 84, 0.1);
  border-radius: 5.70071px;
}

.boxcard .grid-card.mob {
  display: block;
}

.boxcard .grid-card img {
  margin-bottom: 6.42px;
  width: 34.25px;
  height: 34.26px;
}

.boxcard .grid-card h2 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 17.1021px;
  line-height: 110%;
  color: var(--text-dark);
}

.boxcard .grid-card p {
  margin-bottom: 8.57px;
  font-weight: normal;
  font-size: 11.4014px;
  line-height: 175%;
  color: var(--text-lightGray);
}

.boxcard .grid-card button {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 11.4014px;
  line-height: 175%;
  color: var(--primary);
  background-color: transparent;
}
.boxcard .contactno-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.boxcard .contactno {
  display: flex;
  width: 100%;
  font-size: 11px;
  line-height: 20px;
  align-items: center;

  color: #495057;

  mix-blend-mode: normal;
}
.boxcard .grid-card button::after {
  content: "";
  display: block;
  width: 10.09px;
  height: 6.42px;
  margin-left: 6px;
  background-image: url(/static/media/green-arrow-right.88ef685a.svg);
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 500px) {
  .boxcard .marginleft {
    margin-left: 60px !important;
  }
  .boxcard .marginright {
    margin-right: 60px !important;
  }
}
@media screen and (min-width: 768px) {
  .boxcard {
    display: flex;
    justify-content: center;
  }

  .boxcard .grid-card {
    display: block;
    margin: 0;
  }
}

@media screen and (min-width: 1050px) {
  .boxcard .grid-card {
    max-width: 570px;
    height: 390px;
    padding: 32px;
  }

  .boxcard .grid-card img {
    width: 48px;
    height: 48px;
    margin-bottom: 9px;
  }

  .boxcard .grid-card h2 {
    font-size: 24px;
    line-height: 110%;
    margin-bottom: 8px;
  }

  .boxcard .grid-card p {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .boxcard .grid-card button {
    font-size: 16px;
    margin-top: 24px;
  }

  .boxcard .grid-card button::after {
    width: 14.14px;
    height: 9px;
    margin-left: 8px;
  }
  .boxcard .contactno {
    width: 50%;
    margin-top: 15px;
    font-size: 16px;
    line-height: 28px;
  }
}

.getintouch .banner {
  padding: 50px 30px;
  width: 100%;
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.contactdetails {
  padding: 50px 0px;
  overflow: hidden;
}
.wp {
  width: 86%;
  margin: auto;
}

.contactdetails .wrapper {
  max-width: 500px;
}

.contactdetails .heading {
  margin-bottom: 68px;
}

.contactdetails .heading h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  color: var(--text-dark);
}

.contactdetails .wrapper .dot-grid-1,
.contactdetails .wrapper .dot-grid-2 {
  width: 94px;
  height: 82px;
  position: absolute;
  z-index: 1;
  background-image: url(/static/media/dot-grid.a3891925.svg);
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.contactdetails .wrapper .dot-grid-1 {
  top: -35px;
  left: 48px;
}

.contactdetails .wrapper .dot-grid-2 {
  bottom: -35px;
  right: 48px;
}
.getintouch .head1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  /* identical to box height, or 78px */
  width: 100%;
  color: #22bb66;
}
.getintouch .description {
  font-style: normal;
  font-size: 12px;
  line-height: 160%;
  font-weight: 600;
  color: #474a54;
  margin-top: 20px;
}
.getintouch .green-right-shade {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%);
  z-index: -10;
  width: 100%;
}
.getintouch .service-img {
  width: 100%;
  margin-top: 20px;
  object-fit: cover;
}
.getintouch .leftdiv {
  width: 100%;
}
.desktop {
  display: none !important;
}
.mobile {
  display: block !important;
}
.globalOffices {
  padding: 30px 0px;
}
.globalOffices .wrapper {
  text-align: center;
}
.globalOffices .title {
  width: 100%;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 52px */
  color: #22bb66;
  text-align: center;
}
.globalOffices .map {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}


.row {
  display: flex;
  flex-wrap: wrap;
}
.col-sm-6 {
  width: 100%;
}
.col-md-12 {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .col-sm-6 {
    width: 50%;
  }
  .desktop {
    display: block !important;
  }
  .mobile {
    display: none !important;
  }
  .getintouch .banner {
    max-width: 1800px;
    padding: 50px 30px;
    justify-content: right;
  }
  .getintouch .leftdiv {
    width: calc(100% - 300px);
  }
  .getintouch .head1 {
    width: 80%;
    font-size: 60px;
    line-height: 130%;
  }
  .getintouch .description {
    width: 70%;
    margin-top: 25px;
    font-size: 20px;
    line-height: 160%;
  }
  .getintouch .green-right-shade {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -10;
    width: unset;
    left: auto;
  }
  .getintouch .service-img {
    width: 300px;
    margin-right: auto;
  }
  .contactdetails {
    display: flex;
    padding: 80px 0px;
    justify-content: center;
  }

  .banner {
    padding: 80px 50px;
    width: 100%;
    display: flex;
    flex: 1 1;
  }
  .contactdetails .wrapper {
    max-width: 1800px;
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 400px));
    grid-column-gap: 30px;
    column-gap: 30px;
    grid-row-gap: 40px;
    row-gap: 40px;
  }

  .contactdetails .heading {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
  }

  .contactdetails .wrapper .dot-grid-1 {
    top: -40px;
    left: 280px;
  }

  .contactdetails .wrapper .dot-grid-2 {
    bottom: -30px;
    left: auto;
    right: 0;
  }
  .globalOffices .title {
    width: 100%;
    font-size: 40px;
    line-height: 130%;
    /* identical to box height, or 52px */
    color: #22bb66;
    text-align: center;
  }
  .globalOffices .map {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  
}

@media screen and (min-width: 1050px) {
  .getintouch .leftdiv {
    width: calc(100% - 400px);
  }

  .getintouch .service-img{
    width: 400px;
  }

  .getintouch .banner {
    padding: 50px 100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .contactdetails {
    padding: 100px 0px;
  }
  .contactdetails .wrapper {
    grid-template-columns: repeat(2, minmax(240px, 570px));
  }

  .contactdetails .heading h1 {
    font-size: 50px;
    line-height: 130%;
  }

  .contactdetails .wrapper .dot-grid-1,
  .contactdetails .wrapper .dot-grid-2 {
    width: 131.58px;
    height: 115px;
  }

  .contactdetails .wrapper .dot-grid-1 {
    top: -50px;
    left: -30px;
  }

  .contactdetails .wrapper .dot-grid-2 {
    bottom: -50px;
    right: -20px;
  }
}

@media screen and (min-width: 1400px) {
  .getintouch .banner {
    padding: 50px 320px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.demo .contactdetails {
    width: 100%;
    margin-top: 50px;
    padding: 30px;
}

.demo .contactdetails .row .inputField {
    background: #ffffff;
    border: 1px solid rgba(71, 74, 84, 0.3);
    box-sizing: border-box;
    border-radius: 12px;
    width: calc(100% - 24px);
    line-height: 20px;
    padding: 15px 20px;
    font-size: 16px;
    margin: 12px;
}

.demo .contactdetails .row .err {
    margin-left: 20px;
    color: red;
}

.demo .contactdetails .row .heading {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 130%;
    color: #050302;
    margin: 12px;
    text-align: center;
}

.demo .contactdetails .row .greenbutton {
    padding: 16px 40px;
    width: 136px;
    height: 52px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    background: #22bb66;
    border-radius: 8px;
    margin: 12px;
}

.demo {
    background: #f4f5fa;
}

.demo .banner {
    padding: 0px;
}

.demo .contact-detail-img {
    width: 100%;
}

.w-25ch {
    width: 25ch;
}

@media screen and (min-width: 768px) {
    .demo .contact-detail-img {
        width: 40%;
        object-fit: cover;

    }

    .demo .contactdetails {
        width: 60%;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 60px;
    }

    .demo .contactdetails .row {
        width: 100%;
    }

    .demo .contactdetails .row .heading {
        font-size: 28px;
        text-align: left;
    }
}

@media screen and (min-width: 1024px) {

    .demo .contactdetails {
        padding: 20px;
        padding-left: 30px;
        padding-right: 60px;
        margin-top: 0px;
    }

    .demo .contactdetails .row .heading {
        font-size: 32px;
    }

    .demo .contactdetails .row .heading {
        margin: 0 0 10px 12px;
    }

    .demo .contactdetails .row .inputField {
        margin: 10px;
    }

    .demo .contactdetails .row .greenbutton {
        margin: 0px 10px 10px;
    }
}

@media screen and (min-width: 1400px) {

    .demo .contactdetails {
        padding-left: 60px;
        padding-right: 135px;
    }

    .demo .contactdetails .row .heading {
        font-size: 40px;
    }
}

.ShowResource_box__3jPSF {
    height: 155vh;
    padding: 10rem;
    padding-top: 2rem;
}
.ShowResource_name__RNuG7 {
    background-color: rgba(34, 187, 102, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-bottom: 4rem;
    padding: 2rem;
    grid-gap: 1rem;
    gap: 1rem;
}
.ShowResource_name__RNuG7 img {
    height: 34px;
    width: 34px;
}
.ShowResource_name__RNuG7 p {
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    color: #000;
}
@media screen and (max-width:1240px) {
   .ShowResource_box__3jPSF {
       padding: 4rem;
       height: 200vh;
   }
}
@media screen and (max-width:469px) {
    .ShowResource_box__3jPSF {
        height: 188vh;
    }
    .ShowResource_name__RNuG7 p {
        font-size: 1rem;
    }
}
@media screen and (max-width:750px) {
    .ShowResource_name__RNuG7 p {
        font-size: 1rem;
    }
}
@media screen and (max-width:350px) {
    .ShowResource_box__3jPSF {
       height: 195vh;
    }
}
@media screen and (max-width:420px) {
    .ShowResource_box__3jPSF {
       padding: 2rem;
    }
}
/* #region  Product Section */
.product {
  padding: 50px 30px 0px 30px;
}
.product .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
}
.my_width {
  width: 36% !important;
}
.product .wrapper .leftdiv {
  width: 100%;
}
.product .wrapper .leftdiv .head {
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #22bb66;
}
.product .wrapper .leftdiv .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #050302;
  margin-top: 20px;
}
.my_flex {
  display: flex;
  flex-wrap: wrap;
}
.li_width_sales {
  width: 50%;
}
.product .wrapper .leftdiv .description {
  font-size: 12px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #474a54;
  margin-top: 12px;
}
.product .wrapper .leftdiv .demo {
  padding: 12px 20px;
  background: #22bb66;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14.4px;
  line-height: 16px;
  color: white;
  margin-top: 40px;
  margin-bottom: 24px;
  border: 2px solid #22bb66;
}
.detail-demo{
  display: flex;
  align-items: center;
}

.detail-demo::after{
  content: url(/static/media/white-arrow-right-mob.05f5ccd6.svg);
    display: block;
    margin-left: 0.5rem;
    line-height: 16px;
    font-size: 12px;
}
.product .wrapper .leftdiv .learnMore {
  padding: 12px 20px;
  border: 2px solid #22bb66;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  margin-left: 24px;
  font-weight: 600;
  font-size: 14.4px;
  line-height: 16px;
  color: #22bb66;
  margin-bottom: 24px;
}
.casestudy{
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  align-items: center;
}
.casestudy p{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  line-height: 160%;
  color: #474a54;
  font-weight: 700;
}

.casestudy .arrow-icon{
  transform: scale(0.7);
}
.casestudy
.product .wrapper .leftdiv .casestudy p{
  font-weight: bold;
  font-size: 12px;
  line-height: 160%;
  width: 100%;
  color: #474a54;
}
.product .wrapper .leftdiv .rectangle {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}
/* #endregion */
/* #region  Partners Section */
.partners {
  padding: 50px 30px 0px 30px;
}
.partners .wrapper {
  padding-left: 0px;
  padding-right: 0px;
}
.carousel {
  grid-row-gap: 0;
  row-gap: 0;
}
/* my addition  */
.product_sub_category_listing {
  padding-left: 2vw;
  padding-top: 2vh;
}
.product_sub_category_listing li {
  margin-bottom: 0.5rem;
}
/* my addition end  */
.partners__carousel__container > * {
  margin-right: 10px;
}

.partners__carousel__prev-control,
.partners__carousel__next-control {
  color: #050302;
  background: none !important;
  transform: translateY(-45px);
}

.partners__carousel__prev-control {
  left: 0;
}

.partners__carousel__next-control {
  right: 0;
}
/* .partners .services {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.partners .services .medicalLabel,
.partners .services .alternativeLabel,
.partners .services .lifeLabel {
  display: flex;
  align-items: center;
  color: #474a54;
  padding: 2px 16px;
  font-size: 18px;
  line-height: 28px;
  background-color: white;
  height: 30px;
}
.partners .services .lifeLabel {
  border: 2px solid #22bb66;
  border-radius: 8px;
  color: #22bb66;
}

.partners .services .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 20px;
  color: #050302;
}
.partners .services .demo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 20px;
  background: #22bb66;
  border-radius: 6.4px;
  color: white;
  margin-left: auto;
  margin-bottom: 20px;
  font-size: 14.4px;
  line-height: 16px;
}
.partners .services .carousel {
  width: 100%;
  height: 30px;
  display: block;
} */

.partners__carousel__prev-control,
.partners__carousel__next-control {
  color: #050302;
  background: none !important;
  transform: translateY(-45px);
}

.partners__carousel__prev-control {
  left: 0;
}

.partners__carousel__next-control {
  right: 0;
}
.services__carousel__container {
  height: 30px;
}
.services__carousel__container > * {
  margin-right: 40px;
}
.services__carousel__prev-control,
.services__carousel__next-control {
  color: #050302;
  background: none !important;
}
.services__carousel__prev-control {
  left: 0;
}

.services__carousel__next-control {
  right: 0;
}
/* #endregion */
/* #region  Product Details Section */
.product-detail {
  padding: 30px 0;
  background-color: #e9f8f0;
}
.product-detail .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  padding: 0 30px;
}
.product-detail .wrapper .rectangle {
  height: 290px;
  width: 100%;
}
.product-detail .wrapper .leftdiv {
  width: 100%;
}
.product-detail .wrapper .leftdiv .head {
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #22bb66;
}
.product-detail .wrapper .leftdiv .title {
  font-weight: bold;
  font-size: 10px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #050302;
  margin-top: 20px;
}
.product-detail .wrapper .leftdiv .description {
  font-size: 8px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #474a54;
  margin-top: 12px;
}
.product-detail .wrapper .leftdiv .demo {
  padding: 5px 15px;
  background: #22bb66;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: white;
  margin-top: 15px;
  border: 2px solid #22bb66;
}

.product-detail .wrapper .leftdiv .rectangle {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}
/* #endregion */
@media screen and (min-width: 768px) {
  /* #region  Product Section */
  .product {
    padding: 80px 30px 0px;
  }
  .product .wrapper .leftdiv {
    width: 50%;
    /* padding-right: 50px; */
  }
  .product .wrapper .rectangle {
    width: 30%;
    height: 55%;
  }
  .product .wrapper .leftdiv .head {
    font-size: 50px;
    line-height: 130%;
  }
  .product .wrapper .leftdiv .title {
    font-size: 22px;
    line-height: 130%;
  }
  .product .wrapper .leftdiv .description {
    font-size: 15px;
  }
  .product .wrapper .leftdiv .demo,
  .product .wrapper .leftdiv .learnMore {
    padding: 16px 40px;
  }
  .product .wrapper .leftdiv .casestudy p{
    font-size: 16px;
  }
  /* #endregion */
  /* #region  Partners Section */
  .partners .services .carousel {
    width: 100%;
  }
  .partners .services .title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0px;
  }
  .partners .services .demo {
    padding: 16px 40px;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0px;
  }
  .partners__carousel__prev-control,
  .partners__carousel__next-control {
    color: #050302;
    background: none !important;
    transform: translateY(0);
  }
  /* #endregion */

  /* #region  Product Details Section */
  .product-detail .wrapper {
    align-items: center;
  }
  .product-detail .wrapper .leftdiv {
    width: 60%;
    padding-left: 100px;
  }
  .product-detail .wrapper .rectangle {
    width: 25%;
    height: 58vh;
  }
  .product-detail .wrapper .leftdiv .head {
    font-size: 50px;
    line-height: 130%;
  }
  .product-detail .wrapper .leftdiv .title {
    font-size: 30px;
    line-height: 130%;
  }
  .product-detail .wrapper .leftdiv .description {
    font-size: 16px;
    line-height: 160%;
  }
  .product-detail .wrapper .leftdiv .demo {
    font-size: 18px;
    line-height: 20px;
    padding: 16px 40px;
  }
  /* #endregion */
}

@media screen and (max-width : 1100px) {
  .product-detail .wrapper .leftdiv {
    width: 70%;
    padding-left: 100px;
  }
}
@media screen and (max-width : 760px) {
  .product-detail .wrapper .leftdiv {
    width: 100%;
    padding-left: 60px;
  }
}
@media screen and (max-width : 600px) {
  .product-detail .wrapper .leftdiv {
    width: 100%;
    padding-left: 30px;
  }
}

@media screen and (max-width : 570px) {
  .my_flex {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .li_width_sales {
    width: 100%;
  }
}

@media screen and (min-width: 1050px) {
  /* #region  Product Section */
  .product {
    padding: 100px 0px;
  }

  .product .wrapper{
    padding: 0 135px;
    flex-wrap: nowrap;
  }

  .product .wrapper .leftdiv .casestudy p{
    font-size: 18px;
  }
  /* #endregion */
  /* #region  Partners Section */
  .partners .services .carousel {
    width: 50%;
  }
  /* #endregion */

  .resource-section .wrapper {
    max-width: 1280 !important;
  }
}

@media screen and (min-width: 1240px) {
  .product .wrapper .leftdiv .demo{
    font-size: 18px;
    line-height: 20px;
  }

  .product-detail .wrapper{
    padding: 0 135px;
  }
  .detail-demo::after{
    content: url(/static/media/white-arrow-right-new.6eb05b3e.svg);
    margin-left: 0.875rem;
    line-height: 18px;
    font-size: 20px;
  }

  .casestudy{
    grid-gap: 16px;
    gap: 16px;
  }

  .casestudy .arrow-icon{
    transform: scale(1);
  }
}

@media screen and (max-width:767px) {
  .product-detail .wrapper .rectangle {
    width: 45% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1176px) {
  .product .wrapper .rectangle {
    width: 40% !important;
  }
} 
@media only screen and (max-width:330px) {
  .product .wrapper .leftdiv .demo {
    padding: 7px 8px !important;
    font-size: 10px !important;
    line-height: 10px !important;
  }
  .product .wrapper .leftdiv .learnMore {
    font-size: 10px !important;
    line-height: 10px !important;
    padding: 7px 8px !important;
  }
}
@media only screen and (max-width: 950px) {
  .section-8 > .wrapper {
    flex-wrap: wrap;
  }
  .section-8 .demo-btn {
    position: static !important;
    margin-left: 30px !important;
  }
}
@media only screen and (max-width:768px) {
  .product-detail .wrapper .rectangle {
    width: 60% !important;
    margin : auto;
    height: 64vh;
  }
 
  .section13 .section-13 {
    padding-top: 6px !important;
    padding-bottom: 14px !important;
  }
  .product-detail .wrapper .leftdiv .title {
    font-size: 25px !important;
  }
  .product-detail .wrapper .leftdiv .description {
    font-size: 18px !important;
    line-height: 135% !important;
    margin-bottom: 20px !important;
  }
  .section-13 .wrapper .headDiv h1 {
    font-size: 25px !important;
  }
  .section-13 .wrapper .headDiv p {
    margin-top: 20px !important;
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1165px) {
  .in {
    margin-bottom: 40px;
  }
}
.section-13 {
  padding: 50px 0;
  background-color: #F4F5FA;
  overflow: hidden;
}
/* my addition  */
.product_list_key_feature_data {
  list-style: square inside url(/static/media/green-tick.3c902f30.svg);
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.in {
  display: flex;
  margin-bottom: 20px;
}
.product_page_product_name {
  margin-left: 20px;
}
@media only screen and (max-width : 768px) {
  .product_page_product_name {
    margin-left: 8px !important;
  }
}
.in p {
  line-height: 4vh;
}
.green_tick_used_for_listing_img {
  height: 27px;
  width: 20px;
  margin-right: 5px;
}
.green-back{
  background-color: #E9F8F0 !important;
}

.grey-back{
  background-color: #F4F5FA !important;
}

.section-13 .wrapper {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 135px;
}

.section-13 .wrapper .headDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 450px;
}
.section-13 .wrapper .headDiv h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #050302;
  text-align: left;
  white-space: pre-line;
  /* tex-align: ; */
}
.section-13 .wrapper .headDiv span {
  color: #22bb66;
}
.section-13 .wrapper .headDiv p {
  margin-top: 16px;
  font-size: 16px;
  line-height: 160%;

  display: flex;
  text-align: left;
  color: #474a54;
}
.section-13 .wrapper .row {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 60px;
}
.section-13 .wrapper .row2{
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
  grid-gap: 25px;
}
.row3 {
  margin-top: 20px !important;
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
  grid-gap: 25px;
}
.mar3 {
  margin-right: 3px;
}
.fwb {
  font-weight: 900;
}
.section-13 .wrapper .row .leftDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  grid-gap: 20px;
  padding-right: 70px;
  order: 1;
  padding-left: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.section-13 .wrapper .row .leftDiv .card {
  background: #ffffff;
  box-shadow: 0px 4px 13px 4px rgba(71, 74, 84, 0.1);
  border-radius: 8px;
  padding: 30px;
  width: 100% !important;
}
.section-13 .wrapper .row .leftDiv .card h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  color: #050302;
}
.section-13 .wrapper .row .leftDiv .card p {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #474a54;
  margin-top: 15px;
}
.section-13 .wrapper .row .rightDiv {
  width: 50%;
  display: flex;
  order: 2;
  justify-content: center;
 
}

.section-13 .wrapper .row .rightDiv .sec-13-image-desk {
  display: block;
  height: 55vh;
}
.eh {
  height: 100vh !important;
  justify-content: flex-start !important;
}

.section-13 .wrapper .row .rightDiv .sec-13-image-mob{
  display: none;
}

.section-13 .wrapper .row2 .button-fill {
  padding: 16px 40px;
  background: #22bb66;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #22bb66;
  color: white;
}
.section-13 .wrapper .row2 .button-border {
  padding: 16px 40px;
  background: white;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #22bb66;
  border: 2px solid #22bb66;
}

.extra-info{
  display: flex;
  align-items: center;
}

.extra-info p{
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #474A54;
  margin-right: 16px;
}

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1023px) {
  .section-13 .wrapper{
    padding: 30px;
  }
  .section-13 .wrapper .headDiv {
    padding-right: 0px;
  }

}

@media screen and (max-width: 767px) {
  .section-13 .wrapper{
    padding: 30px;
  }
.section-13 .wrapper .row .leftDiv .card h1{
  font-size: 12px;
  line-height: 230%;
  text-align: left;
}

.section-13 .wrapper .row .leftDiv .card p{
  font-size: 8px;
  line-height: 160%;
  text-align: left;
}

.section-13 .wrapper .row .leftDiv .card{
  padding: 12px;
}
.section-13 .wrapper .row2 {
    margin-top: 40px;
    grid-gap: 15px;
  }
  .section-13 .wrapper .row2 .button-border,
  .section-13 .wrapper .row2 .button-fill {
    padding: 12px 12px;
    font-size: 14.4px;
    line-height: 16px;
  }

  .section-13 .wrapper .headDiv {
    padding-right: 0px;
  }
  .section-13 .wrapper .headDiv h1 {
    font-size: 18px;
    line-height: 23px;
  }

  .section-13 .wrapper .headDiv p {
    margin-top: 10px;
    font-size: 8px;
    line-height: 160%;
  }
  .section-13 .wrapper .row {
    flex-direction: column;
    margin: 0;
  }
  .section-13 .wrapper .row .leftDiv {
    width: 100%;
    padding-right: 0px;
    order: 2;
  }
  .section-13 .wrapper .row .rightDiv {
    margin: 20px 0;
    width: 100%;
    order: 1;
    height: 45vh;
  }

  .section-13 .wrapper .row .rightDiv .sec-13-image-desk{
  display: none;
}

  .section-13 .wrapper .row .rightDiv .sec-13-image-mob{
    display: block;
    width: 80%;
    margin: auto;
    height: 95%;
  }

  .extra-info p{
    font-size: 12px;
    line-height: 160%;
    margin-right: 8px;
  }

  .extra-info .arrow-icon{
    transform: scale(0.7);
  }
}

@media screen and (max-width: 479px) {
}

.btn-transparent{
  background-color: transparent !important;
}



.intro-banner {
  padding: 50px 30px;
  height: 310px;
  background-image: url(/static/media/product-banner.5af16d44.png);
  background-size: cover;
}
.intro-banner .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0px;
  padding-right: 0px;
}
.intro-banner .wrapper .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 0px 40px;
}
.intro-banner .wrapper .hr {
  width: 56px;
  height: 2px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 30px;
}
.intro-banner .wrapper .button {
  padding: 16px 40px;
  background-color: #ffffff;
  border-radius: 8px;

  font-size: 14.4px;
  line-height: 16px;
  color: #000000;
  font-weight: bold;
}

.lh-50{
  line-height: 50px !important;
}

.w-33ch{
  width: 33ch;
}

@media screen and (min-width: 768px) {
  .intro-banner .wrapper .title {
    font-weight: 600;
    font-size: 38px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
  .intro-banner .wrapper .hr {
    width: 56px;
    height: 2px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 25px;
    margin-bottom: 30px;
  }
  .intro-banner .wrapper .button {
    font-size: 18px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1050px) {
}

.logo-marquee-container{
  width: 100%;
  /* height: 300px; */
  overflow: hidden;
  /* margin: 40px 0 0; */
}
.logo-marquee-wrapper{
  display: flex;
  flex-flow: row;
  /* position: relative;
  left: -700px;
  right: -700px;
  height: 200px;
  width: 150%; */
  align-items: center;
}

.logo-box{
  /* position: absolute; */
  /* height: 200px; */
  /* z-index: 0; */
  height: 80px;
  width: 200px;
}
.marquee-container {
  overflow-x: unset !important;
}

.logo-box img{
  width: 100%;
  height: 100%;
}

.logo-boxes{
  position: relative;
  left: -700px;
  height: 200px;
  width: 0%;
  display: flex;
  justify-content: center;
  grid-gap: 0;
  gap: 0;
}

.logo-marquee .marquee{
  grid-gap: 100px;
  gap: 100px;
}

.horizontal-logo{
  flex-direction: row;
}

.vertical-logo{
  flex-direction: column;
}

.marquee-margin-top-40{
  margin: 40px 0 0;
}

.marquee-margin-bottom-40{
  margin: 0 0 40px;
}
/* #region  Last Section */

.last-section {
  padding: 100px 0;
  display: flex;
}
.last-section .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 135px;
}
.last-section .wrapper .col-md-6 {
  width: 50%;
  padding: 15px;
  text-align: center;
}

.last-section .wrapper .box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding: 60px 50px;
  border-radius: 10px;
}

.last-section .wrapper .box.img1 {
  background-image: url(/static/media/Check_out_our_Work_culture_1.a02f4a2f.png);
  background-position: center;
}

.last-section .wrapper .box.img2 {
  background-image: url(/static/media/Know_more_about_artivatic_1.0f5d0dcd.png);
  background-position: center;
}

.last-section .box h2 {
  font-size: 38px;
  font-weight: 700;
  line-height: 49px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
}

.black-text{
  color: #000 !important;
}

.last-section .box a {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  border: 2px solid #fff;
  padding: 15px 25px;
  color: #000;
  border-radius: 8px;
  text-decoration: none !important;
  background-color: #fff;
  display: inline-block;
  margin-top: 20px;
}

.last-section .box .line {
  width: 55px;
  height: 2px;
  background-color: #fff;
  margin: 20px auto;
}
/* #endregion */

@media screen and (max-width: 1024px) {
  .last-section .wrapper{
    padding: 0 20px;
  }
  .last-section .wrapper .col-md-6{
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .last-section {
    padding: 0px 20px 45px;
  }

  .inv-sec{
    padding: 0px 20px 90px !important;
  }
  .last-section .wrapper {
    padding: 0px;
    grid-gap: 20px;
  }
  .last-section .wrapper .box.img1, .last-section .wrapper .box.img2{
    background-position: center;
  }
  .last-section .wrapper .box.img2{
    margin: 0;
  }
  .last-section .box a {
    font-size: 14.4px;
    line-height: 16px;
    padding: 12px 12px;
  }
  .last-section .wrapper .col-md-6 {
    padding: 0px;
  }

  .inv-img{
    margin-top: 60px !important;
  }
}

@media screen and (max-width: 574px) {
  
  .last-section .box h2 {
    font-size: 24px;
    line-height: 26px;
  }
  .last-section .wrapper .col-md-6 {
    width: 100%;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
}

/* #region  Core Team Section */
.core-team {
  padding: 80px 0;
  display: flex;
  background: #e9f8f0;
}
.core-team .wrapper {
  display: flex;
  padding-left: 6rem;
  width: 100%;
}
.core-team .wrapper h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  text-align: center;
  color: #050302;
  margin-bottom: 60px;
}
.left-md.desktop {
  display: block ;
}
.desktop {
  display: flex ;
}
.mobile {
  display: none ;
}
.core-team .wrapper .card {
  background: #ffffff;
  box-shadow: 0px 0px 13px 4px rgba(71, 74, 84, 0.1);
  border-radius: 8px;
  margin: 10px;
}
.core-team .wrapper .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.core-team .wrapper .col-md-4 {
  width: 18%;
}
.core-team .wrapper .card .profilePic {
  width: 100%;
}
.core-team .wrapper .card .pad24 {
  padding: 24px;
}
.core-team .wrapper .card .pad24 .title {
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #050302;
  width: 100%;
}
.core-team .wrapper .card .pad24 .designation {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;

  color: #22bb66;
}

.images_wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team_image{
  max-height: 200px;
  overflow: hidden;
}
/* #endregion */

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1023px) {
  .core-team .wrapper{
    padding:0 20px;
  }
  .mobile{
    display: flex ;
    margin-bottom: 20px;
  }
  .desktop{
    display: none ;
  }

  .core-team .wrapper .col-md-4{
    width: 33%;
  }

  .core-team .wrapper .card{
    width: 210px;
  }
}

@media screen and (max-width: 767px) {
  .core-team .wrapper h1{
    font-size: 30px;
    line-height: 39px;
  }
}

@media screen and (max-width: 574px) {
  .core-team .wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  .core-team__carousel__container > * {
    margin-right: 0px;
  }

  .core-team__carousel__prev-control,
  .core-team__carousel__next-control {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
    transform: translateY(-150px);
  }

  .core-team__carousel__prev-control {
    left: 0;
  }

  .core-team__carousel__next-control {
    right: 0;
  }

  .core-team .wrapper .col-md-4 {
    width: 80%;
  }

  .team_cards{
    width: calc(100% - 20px) !important; 
  }
}

@media screen and (max-width: 479px) {
}

.work-value {
  padding: 100px 0;
  background-color: #f4f5fa;
}

.work-value .wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 135px;
}

.work-value .wrapper .dot-grid-1,
.work-value .wrapper .dot-grid-2 {
  width: 94px;
  height: 82px;
  position: absolute;
  z-index: 1;
  background-image: url(/static/media/dot-grid.a3891925.svg);
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.work-value .wrapper:nth-child(6) {
  margin-left: auto;
}
.work-value .wrapper .dot-grid-1 {
  left: 520px;
  top: -20px;
}

.work-value .wrapper .dot-grid-2 {
  bottom: -20px;
  right: 113px;
}

.work-value .col-4 {
  max-width: 33.33%;
  padding: 15px;
  z-index: 1;
}

.work-value .box {
  background-color: #fff;
  padding: 30px 25px;
  box-shadow: 0px 0px 13px 4px rgba(144, 144, 144, 0.1);
  border-radius: 8px;
  margin-top: 0px;
  border-bottom: 10px solid rgba(34, 187, 102, 1);
}

.work-value h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  margin-bottom: 20px;
}

.work-value .line {
  width: 55px;
  height: 2px;
  background-color: rgba(34, 187, 102, 1);
  margin: 0px;
  margin-bottom: 24px;
}

.work-value .col-4 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}

.work-value h1 span {
  color: rgba(34, 187, 102, 1);
  display: block;
}

.work-value .box span {
  display: inline-block;
  width: 48px;
  height: 48px;
  background: #474a54;
  border-radius: 50%;
}

.work-value .box h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 10px 0;
}

.work-value .box p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(73, 80, 87, 1);
  margin-bottom: 10px;
}

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
  .work-value h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .work-value .box h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .work-value .box p,
  .work-value p {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1023px) {
  .work-value h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .work-value {
    padding: 50px 20px;
  }
  .work-value .line {
    margin-bottom: 15px;
  }
  .work-value .col-4 {
    max-width: 50%;
  }
  .work-value .wrapper {
    padding: 0;
  }
  .work-value .wrapper .dot-img1 {
    left: calc(50% - 20px);
    width: 100px;
    top: -15px;
  }
  .work-value .wrapper .dot-img2 {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .work-value p {
    display: none;
  }
  .work-value .box p {
    display: block;
  }
  .work-value .col-4 {
    max-width: 100%;
  }
  .work-value {
    padding: 50px 100px;
  }
  .work-value .box {
    width: 90%;
  }
  .work-value .col-4:nth-child(odd) .box {
    margin-left: auto;
  }
  .work-value .wrapper .dot-img1 {
    left: auto;
    right: 0;
    top: 100px;
  }
  .wrapper.third-section {
    padding: 50px 100px;
  }
}

@media screen and (max-width: 574px) {
  .work-value {
    padding: 40px 30px;
  }

  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
  .work-value .wrapper .dot-grid-1 {
    left: -25px;
    top: 115px;
  }
}

@media screen and (max-width: 479px) {
}

/* #region  Core Team Section */
.section16 {
  padding: 40px 0;
  display: flex;
  /* overflow: hidden;
  white-space: nowrap; */
  align-items: center;
  justify-content: center;
}
.section16 .wrapper {
  display: flex;
  padding: 0 0px;
  width: 100%;
}
.section16 .investor_img {
  width: 20%;
  height: 25%;
  margin: auto;
}

.section16 .wrapper .row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.section16 .wrapper .row h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  display: flex;
  align-items: center;
  color: #050302;
}
.section16 .wrapper .row .hr {
  width: 56px;
  height: 2px;

  background: #22bb66;
  margin-top: 16px;
  margin-bottom: 25px;
}
.section16 .wrapper .row p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  white-space: pre-line;
  width: 50%;
  /* or 167% */

  text-align: justify;

  color: #474a54;
}
.section16 .wrapper .row .row1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.section16 .wrapper .row .row1 .col-md-2 {
  /* width: 16%; */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 215px;
  max-height: 200px;
}

.padding-t-60{
  padding-top: 60px;
}

.section16 .wrapper .row .row1 .col-md-2 img {
  width: 100%;
}

.investor-carousel{
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  width: 100%;
}

.padding-lr-88{
  padding: 0 88px;
}

.gap-20{
  grid-gap: 20px;
  gap: 20px;
}

.gap-30{
  grid-gap: 30px;
  gap: 30px;
}

.fl-justify-center{
  justify-content: center !important;
}

/* #endregion */

@media screen and (max-width: 1599px) {
  .investor-link{
    margin-top: 100px;
    background: #22BB66;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    border-radius: 8px;
    padding: 16px 40px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1023px) {
  .section16 .wrapper{
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .section16 .wrapper .row .row1 .col-md-2 {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scroll-wrapper{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .padding-lr-88{
  padding: 0;
}
}

@media screen and (max-width: 574px) {
  .section16 {
    padding: 40px 20px;
  }
  .section16 .wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  .section16 .wrapper .row h1 {
    font-size: 30px;
  }
  .section16 .wrapper .row p {
    font-size: 12px;
  }
  .section16 .wrapper .row .row1 .col-md-2 img {
    margin-top: 20px;
  }
  .investor-link{
    background: #22BB66;
    color: #FFFFFF;
    font-size: 14.4px;
    line-height: 16px;
    font-weight: 600;
   border-radius: 6.4px;
    padding: 12px 22px;
    cursor: pointer;
  }

}

@media screen and (max-width: 479px) {
}

/* #region  First Section */
.first-section {
  padding: 100px 20px;
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
}
.first-section .wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.first-section .wrapper .row {
  width: 100%;
}
.first-section .wrapper .left-md {
  max-width: 50%;
}

.first-section .wrapper .left-md h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0em;
  margin-bottom: 20px;
}

.first-section .wrapper .left-md h1 span {
  color: rgba(34, 187, 102, 1);
}

.first-section .wrapper .left-md p {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  color: rgba(71, 74, 84, 1);
  margin-bottom: 25px;
}

.first-section .wrapper .left-md .links {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.first-section .wrapper .left-md .links a {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  border: 2px solid #22bb66;
  padding: 15px 25px;
  color: rgba(34, 187, 102, 1);
  border-radius: 8px;
  text-decoration: none !important;
}

.first-section .wrapper .left-md .links a.fill {
  background-color: #22bb66;
  color: #fff;
}

.first-section .wrapper .right-md {
  max-width: 50%;
  padding-left: 75px;
}
/* #endregion */

/* #region  Third Section */
.third-section {
  padding: 100px 0px;
}

.third-section .row {
  display: flex;
  flex-wrap: wrap;
}

.third-section .wrapper{
  padding: 0 135px;
}

.third-section .col {
  padding: 15px;
}

.third-section .col.md-5 {
  width: 40%;
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
  gap: 50px;
  justify-content: flex-end;
}

.third-section .col.md-7 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.third-section .col.md-7 .height {
  height: 30px;
  width: 100%;
}

.third-section h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 80px;
}

.third-section .col img {
  width: 100%;
}

.third-section h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}

.third-section h3 span {
  color: rgba(34, 187, 102, 1);
}

.third-section .col.md-12 {
  width: 100%;
  padding-top: 80px;
}
/* #endregion */

/* #region  Benifits Section */
.benefits {
  padding: 100px 0px;
  background-color: #f4f5fa;
}

.benefits .wrapper{
  padding: 0 135px;
}

.benefits .row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  justify-content: center;
}

.benefits h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  margin-bottom: 20px;
  text-align: center;
}

.benefits .line {
  width: 55px;
  height: 2px;
  background-color: rgba(34, 187, 102, 1);
  margin: auto;
  margin-bottom: 30px;
}

.benefits p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: rgba(71, 74, 84, 1);
  max-width: 800px;
  margin: auto;
}

.benefits .col.md-4 {
  width: 33.33%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  text-align: left;
}

.benefits .col.md-4 span {
  background: #22bb66;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.benefits .col.md-4 h3 {
  color: rgba(5, 3, 2, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.benefits .col.md-4 p {
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  color: rgba(71, 74, 84, 1);
}

.core-team .wrapper .col-md-4{
  display: inline-flex;
}

.core-team .wrapper .card{
  min-height: 356px;
}

.work-value .box{
  min-height: 395px;
}
/* #endregion */

/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
  .first-section .left-md h1,
  .work-value h1,
  .third-section h1,
  .benefits h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .first-section .left-md p {
    font-size: 16px;
    line-height: 25px;
  }
  .first-section .left-md .links a {
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 4px;
  }
  .first-section {
    align-items: center;
  }

  .third-section h3 {
    font-size: 25px;
    line-height: 34px;
  }
  .benefits .col.md-4 span {
    width: 70px;
    height: 70px;
  }
  .benefits .col.md-4 p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .first-section .left-md h1,
  .work-value h1,
  .third-section h1,
  .benefits h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .first-section.wrapper {
    padding: 50px 20px;
  }
  .first-section .right-md img {
    width: 100%;
  }
  .first-section .left-md p {
    font-size: 14px;
    line-height: 20px;
  }
  .first-section .left-md .links {
    flex-direction: row;
    align-items: flex-start;
  }
  .work-value {
    padding: 50px 20px;
  }
  .work-value .line {
    margin-bottom: 15px;
  }
  .work-value .col-4 {
    max-width: 50%;
  }
  .work-value .wrapper {
    padding: 0;
  }
  .work-value .wrapper .dot-img1 {
    left: calc(50% - 20px);
    width: 100px;
    top: -15px;
  }
  .work-value .wrapper .dot-img2 {
    width: 100px;
  }
  .third-section .wrapper{
    padding: 0 20px;
  }
  .third-section h3 {
    font-size: 20px;
    line-height: 27px;
  }
  .third-section .col.md-12 {
    padding-top: 40px;
  }
  .benefits .wrapper{
    padding: 0 20px;
  }
  .benefits .col.md-4 {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .first-section .wrapper {
    flex-direction: column;
    padding: 50px 100px;
    grid-gap: 60px;
    gap: 60px;
  }
  .first-section .wrapper .left-md,
  .first-section .wrapper .right-md {
    max-width: 100%;
  }
  .work-value p {
    display: none;
  }
  .work-value .box p {
    display: block;
  }
  .work-value .col-4 {
    max-width: 100%;
  }
  .work-value {
    padding: 50px 100px;
  }
  .work-value .box {
    width: 90%;
  }
  .work-value .col-4:nth-child(odd) .box {
    margin-left: auto;
  }
  .work-value .wrapper .dot-img1 {
    left: auto;
    right: 0;
    top: 100px;
  }
  .wrapper.third-section {
    padding: 50px 100px;
  }
  .third-section .row {
    flex-direction: column;
  }
  .third-section .col.md-5,
  .third-section .col.md-7 {
    width: 100%;
  }
  .third-section .col.md-7 .height {
    height: 0;
  }
  .benefits {
    padding: 50px 100px;
  }
  .benefits .col.md-4 {
    width: 100%;
  }
  .benefits p {
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (max-width: 574px) {
  .first-section {
    padding: 30px;
  }
  .first-section .wrapper {
    padding: 10px 0px;
  }
  .first-section .wrapper .left-md h1 {
    font-size: 30px;
    line-height: 130%;
  }
  .first-section .wrapper .right-md {
    padding-left: 0px;
    margin-bottom: 20px;
  }
  .work-value {
    padding: 40px 30px;
  }
  .wrapper.third-section {
    padding: 20px 30px;
  }
  .benefits {
    padding: 20px 30px;
  }

  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
  .work-value .wrapper .dot-grid-1 {
    left: -25px;
    top: 115px;
  }
  .core-team .wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  .core-team__carousel__container > * {
    margin-right: 0px;
  }

  .core-team__carousel__prev-control,
  .core-team__carousel__next-control {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
    transform: translateY(-150px);
  }

  .core-team__carousel__prev-control {
    left: 0;
  }

  .core-team__carousel__next-control {
    right: 0;
  }

  .core-team .wrapper .col-md-4 {
    width: 80%;
  }
  .core-team .wrapper .col-md-4 .card {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 479px) {
}

/* First Section Start  */

.first-section {
  padding: 100px 135px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.highme {
  font-weight : 900;
}
.fontc {
  font-size: 22px !important;
  line-height: 130% !important;
}
.fontd {
  font-size: 15px !important;
  line-height: 160% !important;
}
.primary-section{
  display: flex;
  justify-content: space-between;
  grid-gap: 95px;
  gap: 95px;
}

.prod-detial-pad{
  padding-bottom: 20px !important;
}

.first-section .primary-section .left-md {
  max-width: 50%;
}

.first-section .center-md{
  max-width: 100%;
}

.first-section .primary-section .left-md h1, .first-section .center-md h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0em;
  margin-bottom: 20px;
}
.first-section .primary-section .left-md h2, .first-section .center-md h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  margin-bottom: 10px;
}
.first-section .primary-section .left-md h1 span, .first-section .center-md h1 span {
  color: rgba(34, 187, 102, 1);
}

.first-section .primary-section .left-md p, .first-section .center-md p {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  color: rgba(71, 74, 84, 1);
  margin-bottom: 25px;
  white-space: pre-line;
}
.first-section .primary-section .left-md .casestudy, .first-section .center-md .casestudy {
  font-weight: bold;
  font-size: 18px;
  line-height: 160%;
  margin-top: 20px;
  color: #474a54;
}

.first-section .primary-section .left-md .links, .first-section .center-md .links {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.first-section .primary-section .left-md .links a, .first-section .center-md .links a {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  border: 2px solid #22bb66;
  padding: 15px 25px;
  color: rgba(34, 187, 102, 1);
  border-radius: 8px;
  text-decoration: none !important;
}

.first-section .primary-section .left-md .links a.fill, .first-section .center-md .links a.fill {
  background-color: #22bb66;
  color: #fff;
}

.first-section .primary-section .right-md {
  max-width: 50%;
}
.first-section .primary-section .left-md img {
  width: 100%;
}
.first-section .primary-section .right-md img {
  width: 100%;
}

.quotes{
  position: relative;
}

.quotes::before{
  content: url(/static/media/black-quotes.01f6af61.svg);
  position: absolute;
  top: -50px;
  left: 0;
}

.videoClass{
  height: 450px;
  width: 560px;
}

.community-image{
  display: flex !important;
  /* justify-content: center; */
  align-items: center;
}

.w-22ch{
  width: 22ch;
}

.w-15ch{
  width: 15ch;
}

.m-bottom-40{
  margin-bottom: 40px !important;
}

@media screen and (max-width: 1365px) {
  .first-section .primary-section .left-md .links a, .first-section .center-md .links a {
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 1023px) {
  /* .first-section .left-md img {
    margin-bottom: 30px;
  } */
  .first-section{
    padding: 100px 20px;
  }
  .prod-width{
    max-width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .mt-60{
    margin-top: 60px;
  }
  .order-1{
    order: 1;
  }
  .order-2{
    order: 2;
  }
  .order-3{
    order: 3;
  }
  .order-4{
    order: 4;
  }
  .order-5{
    order: 5;
  }
  .order-6{
    order: 6;
  }
  .first-section .primary-section .left-md h1, .first-section .center-md h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .first-section .primary-section .left-md p, .first-section .center-md p {
    font-size: 14px;
    line-height: 20px;
  }
  .first-section .primary-section .left-md,
  .first-section .primary-section .right-md,
  .first-section .center-md
  {
    max-width: 100%;
  }
  .first-section .primary-section .left-md h1, .first-section .center-md h1 {
    font-size: 25px;
    line-height: 35px;
  }
  .videoClass{
    height: 400px;
    width: 400px;
  }
}

@media screen and (max-width: 767px) {
  .first-section{
    padding: 100px 20px;
  }

  .first-section .primary-section .left-md, .first-section .center-md{
    display: flex;
    flex-direction: column;
  }
  

  .first-section .primary-section .left-md .links, .first-section .center-md .links{
    display: flex;
    flex-direction: row;
    order: 4;
  }

  .first-section .primary-section .left-md .links a, .first-section .center-md .links a{
    font-size: 14.4px;
    line-height: 16px;
    border-radius: 6.4px;
  }

  .quotes::before{
  content: url(/static/media/black-quotes-down.13ead986.svg);
  position: absolute;
  top: -50px;
  left: 0;
}

  .partners-heading{
    font-size: 20px !important;
    line-height: 32px !important;
    order: 2;
    margin-top: 50px;
  }

  .partners-image{
    order: 1;
  }

  .partners-desc{
    order: 3;
  }

  .videoClass{
    width: 100%;
    height: 100%;
  }
  .community-image{
    display: none !important;
  }

  .w-22ch{
  width: 100%;
}
}

/* Fonts */ /* Mulish */ /* Asap */

.Login_page__2KgHG {
    padding: 4rem 2rem 0 0;
}
.Login_page2__3Mr3q {
    padding: 2rem 2rem 0 0;
}
.Login_heading__2neix {
    width: 80%;
    margin: auto;
    margin-bottom: 2rem;
}
.Login_content__U2Gv6 {
   width: 80%;
   margin : auto;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}
.Login_card__1uu_p {
    background-color: white;
    box-shadow: 0px 0px 9.26365px 2.85036px rgb(71 74 84 / 10%);
    border-radius: 5.70071px;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 45%;
    margin-right: 3rem;
    display: flex;
    flex-direction: row;
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}
.Login_card__1uu_p:hover {
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}
.Login_box__2-IWd {
    display: flex;
    flex-direction: column;
}
.Login_logobox__25pcA {
    /* width: 60%; */
    margin-right: 2vw;
    height: 100%;
    display: flex;
    align-items: center;
}
.Login_carddes__2SJlo {
    margin-bottom: 1rem;
    font-size: 1rem;
}
.Login_cardtitle__2yxuo {
    margin-bottom: 1rem;
    font-size: 1.2rem;
}
.Login_cardlink__1o-bJ {
    height: 0.6rem;
    margin-left: 1rem;
}
.Login_logo__1LkMl {
    height: 17vh;
}
@media screen and (max-width : 1200px){
    .Login_content__U2Gv6 {
        display: flex;
        flex-direction: column;
    }
    .Login_card__1uu_p {
        width: 100% !important;
    }
}
@media screen and (max-width:714px) {
    .Login_card__1uu_p {
        display: flex;
        flex-direction: column;
    }
    .Login_logobox__25pcA {
        margin-right: 0vw;
        margin-bottom: 4vh;
    }
}
.row {
  display: flex;
  flex-wrap: wrap;
}

/* Carosel Style  */

#about-page__carousel {
  margin-bottom: 50px;
}

/* Our Story Section Start  */

.ourstory {
  background-color: #f4f5fa;
}

.ourstory .wrapper {
  padding: 100px 150px;
  text-align: center;
}

.ourstory h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 30px;
}

.line {
  width: 56px;
  height: 2px;
  background: #22bb66;
  border-radius: 5px;
  margin: auto;
}

.our-Story-Image{
  margin: 50px 0;
}

.our-Story-Image img {
  width: 100%;
  border-radius: 10px;
}

.ourstory p {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 20px;
  text-align: justify;
}

/* Our Journey Section Start  */

.our-journey {
  padding: 100px 135px 100px 135px;
}

.our-journey h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 30px;
}

.our-journey .left-md {
  /* padding: 0 15px; */
  /* width: 100%; */
  padding-top: 10px;
}

.our-journey .left-md{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.our-journey .right-md {
  width: 40%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.our-journey .right-md img {
  width: 100%;
}

.journey-row {
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 2px dashed #c4c4c4;
  padding: 40px 0px 40px 0; /*padding: 40px 65px 40px 0*/
}

.journey-row:not(:first-of-type){
  padding: 40px 0px; /*padding: 40px 65px*/
}

.journey-row .year {
  /* width: 130px; */
  position: relative;
  margin-bottom: 16px;
}

.journey-row .year::before{
  content: "";
  width: 20px;
  height: 20px;
  background: #22bb66;
  border-radius: 5px;
  transform: rotate(45deg);
  position: absolute;
  top: -52px;
}

.journey-row .year h6 {
  font-size: 30px;
  font-weight: 700;
  line-height: 25px;
}

.journey-row .journey-image{
  max-width: 370px;
  max-height: 230px;
}

.journey-row .journey-image img{
  width: 100%;
  height: 100%;
}

/* .journey-row .year::after {
  content: "";
  border-right: 2px dashed #c4c4c4;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
} */

/* .journey-row:last-child .year::after {
  content: none;
} */

.journey-row .desc {
  position: relative;
  flex: 1 1;
  margin-bottom: 66px;
  max-width: 345px;
}

.journey-row .desc p {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: rgba(71, 74, 84, 1);
}

/* .journey-row .desc::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #22bb66;
  border-radius: 5px;
  transform: rotate(45deg);
  position: absolute;
  left: -11px;
} */

/* What We Do Section Start */

.what-we-do .first-section .left-md {
  order: 2;
}

.what-we-do .first-section .right-md {
  order: 1;
}

/* Changing the way Section */

.change-the-way {
  width: 100%;
  padding: 0px 0;
  background-color: #e9f8f0;
}

/* Join our Community Section */

/* Background Banner Section */

.background-img-section {
  padding: 40px 20px;
  background-image: url(/static/media/contact-us-footer.c1cd9cf7.png);
  background-size: cover;
  text-align: center;
}

.background-img-section h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  text-align: center;
  color: #fff;
  max-width: 590px;
  margin: auto;
  margin-bottom: 20px;
}

.background-img-section a {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  border: 2px solid #fff;
  padding: 15px 25px;
  color: #fff;
  border-radius: 8px;
  text-decoration: none !important;
  display: inline-block;
  margin: 0 15px;
}

.background-img-section a.fill {
  background-color: #fff;
  color: rgba(5, 3, 2, 1);
}

/* Our customers Section Start */

.our-customer {
  background-color: #f4f5fa;
}

.our-customer .wrapper {
  display: flex;
  align-items: center;
  padding: 0 135px;
}

.our-customer .left-md {
  width: 56%;
  padding-right: 70px;
}

.our-customer .left-md h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
}

.our-customer .left-md h1 span {
  color: rgba(34, 187, 102, 1);
}

.our-customer .left-md .line {
  margin: 20px 0;
}

.our-customer .left-md p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.our-customer .right-md {
  width: 40%;
}
.our-customer .right-md .fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    var(--vl-blue),
    transparent 20% 80%,
    var(--vl-blue)
  );
}
.our-customer .right-md img {
  width: 100%;
  min-height: 520px;
}

/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
  
  .our-customer .left-md h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .our-customer .left-md {
    padding: 70px 0;
    padding-right: 50px;
  }
}

@media screen and (max-width: 1023px) {
  .our-journey{
    padding: 100px 0 100px 20px ;
  }
  .ourstory .wrapper {
    padding: 70px 80px;}
  .ourstory h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .ourstory p {
    font-size: 17px;
    line-height: 24px;
  }
  .our-journey h1 {
    font-size: 30px;
    line-height: 35px;
  }
  /* .journey-row {
    padding: 40px 20px 40px 0;
  }
  .journey-row:not(:first-of-type){
    padding: 40px 20px;
  } */
  .journey-row .year h6 {
    font-size: 25px;
    line-height: 22px;
  }
  .journey-row .year {
    width: 100px;
  }
  .journey-row .desc p,
  .our-customer .left-md p {
    font-size: 16px;
    line-height: 20px;
  }
  .our-journey .row{
    justify-content: center;
  }
  .our-journey .left-md{
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .journey-row{
    padding: 0 20px 40px;
    border-top: none;
    border-left: 2px dashed #c4c4c4;
  }

  .journey-row:not(:first-of-type){
    /* display: none; */
    padding: 40px 20px;
  }

  .journey-row .year::before{
    top: 0;
    left: -30px;
  }
}

@media screen and (max-width: 767px) {
  .our-journey.wrapper {
    padding: 60px 0px 60px 30px;
  }
  .our-journey .wrapper{
    overflow: hidden;
  }
  .our-journey .left-md {
    width: 100%;
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .our-journey .right-md {
    width: 70%;
    margin: auto;
  }

  .our-customer .right-md .fade{
    background-image: none !important;
  }

  .journey-row{
    padding: 0 20px 40px;
    border-top: none;
    border-left: 2px dashed #c4c4c4;
  }

  .journey-row:not(:first-of-type){
    /* display: none; */
    padding: 40px 20px;
  }

  .journey-row .year::before{
    top: 0;
    left: -30px;
  }
  
  .journey-row .desc{
    margin-bottom: 24px;
    
  }
  .journey-row .desc p {
    font-size: 16px;
    line-height: 20.8px;
  }
  .our-customer {
    padding: 50px 50px;
  }
  .our-customer .wrapper {
    flex-wrap: wrap;
  }
  .our-customer .left-md {
    width: 100%;
  }
  .our-customer .right-md {
    width: 100%;
    text-align: center;
  }
  .our-customer .right-md img {
    width: 80%;
    margin: auto;
    height: 400px;
  }
}

@media screen and (max-width: 574px) {
  .ourstory .wrapper {
    padding: 70px 40px;
  }
  .our-journey .right-md {
    width: 90%;
  }
  .our-customer .left-md {
    padding: 0;
    padding-bottom: 50px;
  }
  .our-customer {
    padding: 40px 15px;
  }
}

@media screen and (max-width: 479px) {
  .ourstory .wrapper {
    padding: 40px 20px;
  }
  .ourstory h1 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .ourstory img {
    margin: 30px 0;
  }
  .ourstory p {
    font-size: 14px;
  }

  .our-journey h1 {
    font-size: 25px;
    line-height: 30px;
  }
  /* .journey-row .desc::before {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    left: -9px;
  } */
  .journey-row .year h6 {
    font-size: 18px;
    line-height: 17px;
  }
  .journey-row .year {
    width: 65px;
  }
  
  .our-customer .left-md h1 {
    font-size: 23px;
    line-height: 26px;
  }
  .our-customer .wrapper {
    padding: 0 10px;
  }
}

#purchaseorder {
    width: 80%;
    margin: auto;
    margin-top: 8rem;
    padding-bottom: 3rem;
}
.pagehead {
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
}
.subhead {
    margin-top: 2rem;
    line-height: 1.4rem;
}
.subhead p {
    color: black;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 2rem;
}
.mainsec {
    padding-left: 3%;
}
.fwbold {
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
}
.first {
    font-size: 0.9rem;
    margin-bottom: 20px;
    line-height: 1.4rem;
}
ol {
    padding-left: 7%;
}
li {
    margin-bottom: 10px;
}



/* 
Job row Start */
.job-row {width: 100%; background-color: #fff; box-shadow: 0px 6px 13px 4px rgba(71, 74, 84, 0.1); border-radius: 10px; padding: 30px; margin-bottom: 20px;}
.job-row span {font-size: 16px; font-weight: 700; line-height: 28px; color: rgba(34, 187, 102, 1);}
.job-row h3 {font-size: 20px; font-weight: 700; line-height: 25px; color: rgba(5, 3, 2, 1); margin-bottom: 20px;}
.job-row p {font-size: 16px; font-weight: 400; line-height: 28px; color: rgba(73, 80, 87, 1);}


/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width:767px) {
    .job-row h3 {margin-bottom: 10px;}
}
@media screen and (max-width:479px) {
    .job-row {padding: 15px; border-radius: 5px;}
    .job-row span, .job-row p {font-size: 14px; line-height: 18px;}
    .job-row h3 {font-size: 16px; line-height: 20px;}
}
.row {
    display: flex;
    flex-wrap: wrap;
}
a {text-decoration: none;}

/* First Section Start  */
.career-section {
  padding: 100px 135px;
}
.career-section .row {grid-gap: 30px;gap: 30px; margin-bottom: 50px;}
.career-section h1 {font-size: 40px; font-weight: 700; line-height: 52px; margin-bottom: 30px;}
.search {width: 50%;background: #FFFFFF; border: 1px solid rgba(71, 74, 84, 0.3); border-radius: 12px; padding: 10px 15px; display: flex; align-items: center; grid-gap: 15px; gap: 15px;}
.search input {flex: 1 1;}
.search img {cursor: pointer;}
.career-section select {flex: 1 1; background: #FFFFFF; border: 1px solid rgba(71, 74, 84, 0.3); border-radius: 12px; padding: 10px 15px; }

.load-more {font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(5, 3, 2, 1); text-decoration: none;; display: block; text-align: center; margin-top: 40px;}



/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width:1365px) {
    .career-section {
    padding:50px;
}
}

@media screen and (max-width:1023px) {
    .career-section h1 {font-size: 35px; line-height: 45px;}
    .search, .career-section select {border-radius: 8px;}
}

@media screen and (max-width:767px) {
    .search {width: 100%;}
}

@media screen and (max-width:574px) {
    .career-section {padding: 30px 20px;}
    .career-section h1 {font-size: 30px; line-height: 35px;}
    .load-more {font-size: 16px;}
}

@media screen and (max-width:479px) {
    .career-section .row {grid-gap: 10px;gap: 10px; margin-bottom: 20px;}
    .search, .career-section select {border-radius: 4px;}
}
.row {
    display: flex;
    flex-wrap: wrap;
}
a {text-decoration: none;}


.job-details {width: 100%; margin: auto; padding: 80px 20px;}
.job-details h1 {font-size: 40px; font-weight: 700; line-height: 52px; margin-bottom: 20px; text-align: center;}
.job-details .line {width: 60px; height: 2px; background: rgba(172, 181, 189, 1); margin: auto;}
.first-line {margin-top: 40px; display: flex; justify-content: space-between; align-items: center;}
.first-line h2 {font-size: 32px; font-weight: 600; line-height: 40px; margin-bottom: 10px;}
.first-line span {font-size: 16px; font-weight: 700; line-height: 28px; color: rgba(34, 187, 102, 1);}
.job-details span {font-size: 13px; line-height: 16px; color: rgba(42, 42, 42, 1); display: inline-block; margin-bottom: 40px;}
.job-details p {font-size: 15px; line-height: 23px; color: #2A2A2A;  margin-bottom: 20px;}
.job-details h6 {font-size: 16px; font-weight: 700; line-height: 23px; color: #2A2A2A;  margin-bottom: 5px;}
.job-details ul {margin-left: 15px; margin-bottom: 20px;}
.job-details li {font-size: 15px; line-height: 23px; color: #2A2A2A;}
.applyjob{text-align: center;}
.applyjob span a {color: white}
.applyjob span {font-size: 18px; font-weight: 600; line-height: 20px; letter-spacing: 0em; border: 2px solid #22BB66; padding: 15px 25px; background-color: rgba(34, 187, 102, 1); color: #fff; border-radius: 8px;    text-decoration: none !important; display: inline-block;  margin-top: 30px;}
/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width:1365px) {}

@media screen and (max-width:1023px) {
    .job-details h1 {font-size: 35px; line-height: 45px;}
    .job-details {padding: 40px;}
}

@media screen and (max-width:767px) {
    .first-line h2 {font-size: 26px; line-height: 30px;}
    .job-details span {margin-bottom: 20px;}
}

@media screen and (max-width:574px) {
    .job-details h1  {font-size: 30px; line-height: 35px;}
    .applyjob span {font-size: 16px; padding: 12px 20px;}
}

@media screen and (max-width:479px) {
    .job-details {padding: 20px;}
    .first-line {margin-top: 20px;}
    .first-line {flex-direction: column-reverse; align-items: flex-start;}
    .first-line span {margin-left: auto;}
}
.row {
    display: flex;
    flex-wrap: wrap;
}
a {text-decoration: none;}


.job-details {width: 100%; padding: 100px 335px;}
.job-details h1 {font-size: 40px; font-weight: 700; line-height: 52px; margin-bottom: 20px; text-align: center;}
.job-details .line {width: 60px; height: 2px; background: rgba(172, 181, 189, 1);}

.box {padding: 40px; margin-top: 50px; border: 1px solid #ACB5BD; border-radius: 12px;}
.box h6 {font-size: 20px; font-weight: 700; line-height: 33px; color: rgba(0, 0, 0, 1); margin-top: 20px;}
.box .col-md {padding: 15px 0;}
.box label {font-size: 16px; font-weight: 700; line-height: 26px; color: rgba(0, 0, 0, 1); display: block; margin-bottom: 10px;}
.box input {padding: 15px;border: 1px solid #C4C4C4; border-radius: 12px; width: 100%;}
.box textarea {padding: 15px; border: 1px solid #C4C4C4; border-radius: 12px; width: 100%;}

.upload-btn-wrapper {position: relative; overflow: hidden; display: inline-block;}
.upload-btn-wrapper button {padding: 10px 15px;border: 1px solid #C4C4C4; border-radius: 12px; width: 100%; color: rgba(73, 80, 87, 1); display: flex; grid-gap: 10px; gap: 10px;  align-items: center;}
.upload-btn-wrapper input[type=file] {font-size: 100px; position: absolute; left: 0; top: 0; opacity: 0;}

.applyjob2{text-align: center;}
.applyjob2 a {font-size: 18px; font-weight: 600; line-height: 20px; letter-spacing: 0em; border: 2px solid #22BB66; padding: 15px 25px; background-color: rgba(34, 187, 102, 1); color: #fff; border-radius: 8px;    text-decoration: none !important; display: inline-block;  margin-top: 30px;}
/*************************
	-	Responsive	-
	**************************/

@media screen and (max-width:1365px) {}

@media screen and (max-width:1023px) {
    .job-details h1 {font-size: 35px; line-height: 45px;}
    .job-details {padding: 40px;}
}

@media screen and (max-width:767px) {
    .box {padding: 30px;}
    .box input {padding: 8px; border-radius: 8px;}
}

@media screen and (max-width:574px) {
    .job-details h1  {font-size: 30px; line-height: 35px;}
    .applyjob2 a {font-size: 16px; padding: 12px 20px;}
}

@media screen and (max-width:479px) {
    .job-details {padding: 20px;}
    .box { padding: 20px;}
    .box h6 {margin-top: 10px;}
    .box .col-md { padding: 8px 0;}
}
.reactangle99 {
  height: 470px;
  width: 470px !important;
}
.vission {
  padding: 100px 135px;
  background-color: #f4f5fa;
}
.vission .wrapper h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  /* or 52px */
  text-align: center;
  color: #050302;
}
.vission .wrapper h1 span {
  color: #22bb66;
}
@media screen and (max-width: 767px) {
  .first-section .left-md h1{
    font-size: 20px;
    line-height: 32px;
  }
  .vission {
    max-width: 100%;
  }
  .vission {
    padding: 50px 0px;
    background-color: #f4f5fa;
  }
  .vission .wrapper{
    padding: 0 30px;
  }
  .vission .wrapper h1 {
    font-size: 30px;
  }
  .reactangle99 {
    height: 470px;
    width: 100% !important;
  }
}

@media screen and (max-width: 479px) {
  .first-section .left-md h1 {
    font-size: 25px;
    line-height: 35px;
  }
}

.services {
  padding: 40px 135px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  align-items: center;
  justify-content: space-between;
}

.services__nav{
  display: flex;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
}

.services .lifeLabel {
  display: flex;
  align-items: center;
  color: #474a54;
  padding: 2px 16px;
  font-size: 18px;
  line-height: 28px;
  background-color: white;
  height: 30px;
}
.services .selected {
  border: 2px solid #22bb66;
  border-radius: 8px;
  color: #22bb66;
}

.services .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 20px;
  color: #050302;
}
.services .demo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 20px;
  background: #22bb66;
  border-radius: 6.4px;
  color: white;
  margin-left: auto;
  margin-bottom: 20px;
  font-size: 14.4px;
  line-height: 16px;
}
.services .carousel {
  width: 100%;
  height: 30px;
  display: block;
}
.services__carousel__container {
  height: 30px;
}
.services__carousel__container > * {
  margin-right: 40px;
}
.services__carousel__prev-control,
.services__carousel__next-control {
  color: #050302;
  background: none !important;
}
.services__carousel__prev-control {
  display: none;
}

.services__carousel__next-control {
  display: none;
}

.services .title {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 0px;
}
.services .demo {
  padding: 16px 40px;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0px;
}
.services .carousel {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .services {
    padding: 50px 30px 0;
  }

  .vission {
    max-width: 100%;
  }

  .services .title {
    font-size: 18px;
    line-height: 23px;
    margin-right: 130px;
  }
  .services .demo {
    padding: 12px 20px;
    font-size: 14.4px;
    line-height: 16px;
  }
  .services .carousel {
    width: 100%;
    margin-top: 15px;
  }

  .services .lifeLabel{
    font-size: 12px;
    line-height: 15px;
  }

  .services__carousel__container > * {
    margin-right: 14px;
  }
}

@media screen and (max-width: 767px) {
  .vission {
    max-width: 100%;
  }

  .services .title {
    font-size: 18px;
    line-height: 23px;
    margin-right: 8px;
  }
  .services .demo {
    padding: 12px 20px;
    font-size: 14.4px;
    line-height: 16px;
  }
  .services .carousel {
    width: 100%;
    margin-top: 15px;
  }

  .services .lifeLabel{
    font-size: 12px;
    line-height: 15px;
  }

  .services__carousel__container > * {
    margin-right: 14px;
  }
}

@media screen and (max-width: 479px) {
  .first-section .left-md h1 {
    font-size: 25px;
    line-height: 35px;
  }
}

.margin-left {
  margin-left: auto !important;
}
.productExperience .news-letter {
  background-image: url(/static/media/product-banner.5af16d44.png);
}
@media screen and (max-width: 1599px) {
}

@media screen and (max-width: 1365px) {
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 574px) {
}

@media screen and (max-width: 479px) {
}

.balls {
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: space-between;
    margin: 70px auto;
    text-align: center;
}
.ball {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--color-green);
    opacity: .4;
    border-radius: 50%;
}
.ball-active {
    width: 15px;
    height: 15px;
    background-color: var(--color-green);
    opacity: 1;
}
.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}

.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}

.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}

.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}

.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}
.st {
    font-weight: bold;
    color: black;
}
.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}

.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}

.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}

.newsandmedia .home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.newsandmedia .home-img {
    min-width: 600px;
    margin-right: 50px;
}
.ch {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.nav-link {
    margin-right: 40px;
    text-decoration: none;
    color: var(--color-grey-light);
    font-size: 16px;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid transparent;
    outline: none;
    background-color: transparent;
}
.nav-link--active,
.nav-link:hover {
    border: 1px solid var(--color-green);
    color: var(--color-green);
}   

.news {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
    margin: 50px 0;
}
.newsandmedia .card {
    width: 100% !important;
    text-align: left;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0,0,0, .3);
    overflow: hidden;
    transition: transform .3s cubic-bezier(.155,1.105,.295,1.12),box-shadow .3s;
}
.newsandmedia .card:hover {
    transform: scale3d(1.2,1.2,1.2);
}
.card-info {
    padding: 20px 30px;
}
.newsandmedia .card-info .heading-tertiary {
    margin-top: 20px;
    margin-bottom: 30px;
}
.newsandmedia .card img {
    width: 100%;
    background-size: cover;
    background-position: center;
}
.tag {
    display: inline-block;
    font-size: 14px;
    color: #777;
    background-color: #eee;
    border-radius: 7px;
    padding: 5px 10px;
}
.switch-page {
    display: flex;
    max-width: 100%;
}
.pages {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 0 auto;
}
.page {
    font-size: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid #aaa;
    color: #aaa;
    margin: 0 10px;
}
.page-active {
    border: 3px solid var(--color-green);
    color: var(--color-green);
}

:root {
    --color-grey: #555;
    --color-grey-light: #bbb;
    --color-green: #22bb66;
}

/*Base*/
*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: all 0.2s ease-in;
}

body {
    font-family: Mulish;
    font-weight: 600;
}

.main {
    padding: 100px 135px;
}

/*Typography*/
.newsandmedia .heading-primary {
    font-size: 25px;
    font-weight: bold;
}
.newsandmedia .heading-secondary {
    font-size: 30px;
    font-weight: 600;
}
.newsandmedia .heading-tertiary {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 20px;
}
.newsandmedia .heading-tertiary--green {
    text-transform: uppercase;
    font-weight: 700;
    color: var(--color-green);
}

.text-primary {
    font-size: 18px;
    color: var(--color-grey);
    margin: 20px 0;
    line-height: 1.8;
    font-weight: 600;
}
.text-secondary {
    font-size: 16px;
    color: var(--color-grey);
    line-height: 1.8;
    font-weight: 600;
}

/** BUTTONS */
.btn {
    border: none;
    outline: none;
    color: var(--color-green);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    font-weight: inherit;
    font-family: Mulish;
}
.btn svg, .btn svg path {
    width: 30px;
    fill: currentColor;
    margin: 0 10px;
}
.btn-outlined {
    margin: 0;
    border-radius: 7px;
    padding: 10px 15px;
    border: 2px solid var(--color-green);
    width: 150px;
}
.btn-outlined:hover {
    background-color: var(--color-green);
    color: #fff;
}
.btn-outlined-white {
    border: 2px solid #fff;
    color: #fff;
    width: auto;
}
.btn-outlined-white:hover {
    background-color: #fff;
    color: #000;
}
.btn-filled {
    display: inline-block;
    border-radius: 7px;
    padding: 10px 15px;
    background-color: #fff;
    color: #000;
    border: 2px solid #fff;
}

.only-mobile {
    display: none;
}

/** MEDIA QUERIES */

@media (max-width: 1500px) {
    .main {
        padding: 50px;
    }
}
@media (max-width: 1300px) {
    .news {
        grid-template-columns: repeat(3, 1fr);
    }
    .newsandmedia .home-img {
        min-width: 500px;
    }
    .main {
        padding: 100px 80px;
    }
}

@media (max-width: 1200px) {
    .newsandmedia .home {
        flex-direction: column;
    }
    .newsandmedia .home-img {
        min-width: 100%;
        margin: 0;
        margin-bottom: 50px;
        width: 500px;
    }
    .only-mobile {
        display: block;
        text-align: center;
        margin: 30px 0;
    }

    .switch-page {
        display: none;
    }
    .cta-input {
        flex-direction: column;
    }
    .input-group {
        min-width: 100%;
        margin-top: 30px;
    }
}
@media (max-width: 1100px) {
    .main {
        padding: 70px 40px;
    }
}

@media (max-width: 1000px) {
    .news {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    .main {
        padding: 50px;
    }
    .newsandmedia .home {
        padding: 0 50px;
    }
}

@media (max-width: 900px) {
    .newsandmedia .heading-primary {
        font-size: 22px;
    }
    .newsandmedia .heading-secondary {
        font-size: 20px;
        margin: 0;
    }
    .newsandmedia .heading-tertiary {
        font-size: 18px;
        margin: 10px 0;
    }
    .text-primary {
        font-size: 15px;
        margin: 10px 0;
    }
    .text-secondary {
        font-size: 13px;
    }
}

@media (max-width: 750px) {
    .newsandmedia .home {
        padding: 0 20px;
    }
    .main {
        padding: 20px 50px;
    }
    .news {
        margin: 30px 0;
        grid-template-columns: repeat(2, 1fr);
    }
    .nav {
        width: 100%;
        justify-content: space-between;
    }
    .nav-link {
        margin: 0;
    }

    .cta {
        padding: 30px;
    }

    .card {
        border-radius: 7px;
    }
    .card-info {
        padding: 15px;
    }
    .newsandmedia .card-info .heading-tertiary {
        margin-bottom: 25px;
    }
    .balls {
        margin: 40px auto;
    }
    .btn svg{
      transform: scale(0.6);
      margin: 0 0 0 8px;
    }
}

@media (max-width: 500px) {
    .newsandmedia .home {
        padding: 0 10px;
    }
    .newsandmedia .home-img {
        width: 100%;
    }
    .news {
        grid-template-columns: repeat(1, 1fr);
    }
    .nav {
        overflow-x: scroll;
    }
    .nav::-webkit-scrollbar {
        display: none;
    }
    .nav-link {
        font-size: 11px;
    }
    .cta-buttons {
        width: 100%;
    }
    .cta-buttons button {
        font-size: 14px;
    }
}
@media (max-width: 525px) {
    .newsandmedia .home-img {
        width: 450px;
    }
}
@media (max-width: 475px) {
    .newsandmedia .home-img {
        width: 400px;
        margin-bottom: 25px;
    }
}
@media (max-width: 425px) {
    .newsandmedia .home-img {
        width: 370px;
    }
}
@media (max-width: 388px) {
    .newsandmedia .home-img {
        width: 295px;
    }
}
@media (max-width: 306px) {
    .newsandmedia .home-img {
        width: 235px;
    }
    .main {
        padding: 20px 30px;
    }
}
.dataandsecurity .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 235px;
    text-align: center;
}
.dataandsecurity .home-img {
    width: 100%;
    height: 500px;
    /* height: 680px; */
    /* background-color: var(--color-grey-light); */
    border-radius: 20px;
    margin: 60px 0;
}
.dataandsecurity .home-img img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.dataandsecurity .home-logos {
    width: 500px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0;
}
.dataandsecurity .home-logo {
    height: 100%;
}
.nav--bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    padding: 0 300px;
    box-shadow: 0 5px 20px rgba(0,0,0,.1);
}
.nav--item {
    font-size: 16px;
    color: var(--color-grey-light);
    text-align: center;
    text-decoration: none;
    padding: 20px 0;
    cursor: pointer;
}

.active,
.nav--item:hover {
    color: var(--color-grey);
}
.note {
    padding: 100px 235px;
    text-align: center;
}
.info {
    padding: 60px 135px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-justify-between {
    justify-content: space-between !important;
}

.flex-align-start{
  align-items: flex-start !important;
}

.info-odd,
.info:nth-child(odd) {
    background-color: #f4f5fa;
}

.info-primary {
    text-align: center;
    padding: 0 100px;
}

.padding-0{
  padding: 0;
}

.margin-tb-0{
  margin: 0;
}

.margin-t-20{
  margin: 20px 0 0;
}

.info-sec-primary {
    margin-right: 150px;
    width: 45%;
}

.info-grid {
    display: grid;
    width: 50%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}

.info-img {
    width: 50px;
    height: 50px;
    background-color: var(--color-grey-light);
    border-radius: 50%;
}
:root {
    --color-grey: #555;
    --color-grey-light: #bbb;
    --color-green: #22BB66;
}

/*Base*/
*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: all .2s ease-in;
}
 
body {
    font-family: Mulish;
    font-weight: 500;
}

/*Typography*/
.dataandsecurity .heading-primary {
    font-size: 40px;
    font-weight: 600;
}
.dataandsecurity .heading-secondary {
    font-size: 30px;
    font-weight: 600;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.dataandsecurity .heading-tertiary {
    font-size: 20px;
    margin: 20px 0;
    font-weight: 600;
}

.text-primary {
    font-size: 18px;
    color: var(--color-grey);
    /* margin-top: 30px; */
    line-height: 1.8;
    font-weight: 600;
}

.margin-t-40{
  margin: 40px 0 0;
}

.text-secondary {
    font-size: 16px;
    color: var(--color-grey);
    line-height: 1.8;
    font-weight: 600;
}

/*HR*/
hr {
    width: 70px;
    height: 4px;
    background-color: var(--color-green);
    border: none;
    outline: none;
    margin: 30px 0;
}

.text-date{
      display: block;
    }

/*** MEDIA QUERIES ***/
@media (max-width: 1400px) {
  .text-date{
      display: block;
    }
    .info {
        padding: 150px 120px;
    }
    .info-primary {
        margin-right: 150px;
    }
}

@media (max-width: 1300px) {
    .dataandsecurity .home{
        padding: 0 150px;
    }
    .nav--bottom {
        padding: 0 150px;
    }
    .info {
        padding: 120px 100px;
    }
    .info-primary {
        margin-right: 100px;
    }
    
    .dataandsecurity .heading-primary {
        font-size: 30px;
    }
    .dataandsecurity .heading-secondary {
        font-size: 20px;
    }
    .dataandsecurity .heading-tertiary {
        font-size: 18px;
    }
    .text-primary {
        font-size: 16px;
    }
    .text-secondary {
        font-size: 14px;
    }
}

@media (max-width: 1100px) {
    .note, .info {
        padding: 50px;
    }
}
@media (max-width: 1000px) {
    .dataandsecurity .home{
        padding: 0 70px;
    }
    .nav--bottom {
        padding: 0 70px;
    }
    .info {
        padding: 80px 30px;
    }
}

@media (max-width: 900px) {
    hr {
        margin: 20px;
    }
    .dataandsecurity .home{
        padding: 0 30px;
    }
    .dataandsecurity .home-img {
        width: 90%;
        height: 50vw;
    }
    .nav--bottom {
        padding: 0 10px;
    }
    .info {
        flex-direction: column;
        padding: 80px 30px;
    }
    .info-primary, .info-grid {
        width: 100%;
        margin: 20px 0;
    }
    .note {
        padding: 50px 20px;
    }
    .dataandsecurity .home-logos {
        width: 400px;
        height: 70px;
    }
}

@media (max-width: 700px) {
    .dataandsecurity .heading-primary {
        font-size: 22px;
    }
    .dataandsecurity .heading-secondary {
        font-size: 20px;
        margin: 0;
    }
    .dataandsecurity .heading-tertiary {
        font-size: 18px;
        margin: 10px 0;
    }
    .text-primary {
        font-size: 12px;
        line-height: 19.2px;
        margin: 10px 0;
    }
    .text-date{
      display: none;
    }
    .news-view-btn{
      font-size: 12px;
      line-height: 19.2px;
    }
    .text-secondary {
        font-size: 13px;
    }
    .nav--item {
        font-size: 13px;
    }
    .note {
        padding: 10px 20px;
    }
}

@media (max-width: 600px) {
    hr {
        height: 2px;
    }
    .dataandsecurity .home {
        padding: 0 10px;
    }
    .dataandsecurity .home-img {
        width: 95%; 
        margin: 20px;   
    }
    .nav--bottom {
        margin-top: 0;
        overflow-x: scroll;
    }
    .nav--bottom::-webkit-scrollbar {
        display: none;
    }

    .nav--item {
        font-size: 11px;
        margin: 0 10px;
    }
    .info{
        padding: 10px 20px;
    }
}

@media (max-width: 500px) {
    .dataandsecurity .home-logos {
        width: 90%;
        height: 50px;
    }
    
}

/* Fonts */ /* Mulish */ /* Asap */

.APIs_wrap__2iUjL {
    padding: 4rem 2rem 1rem 6rem;
}
.APIs_sec__14hUr {
    margin-bottom: 3rem !important;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 85%;
    margin: auto;
}
.APIs_box__3sIWq {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}
.APIs_card__1tC2E {
    height: 18vh;
    width: 16vw;
    padding: 1.25rem;
    margin-right: 12px;
    margin-bottom: 4px;
    border-radius: 12px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border-bottom: 5px solid rgba(34, 187, 102, 1);
}
.APIs_catname__14Lqj {
    font-size: 1.5rem;
    font-weight: 700;
    color: rgba(34, 187, 102, 1);
    text-align: center;
}
.APIs_name__2qDew {
    font-size: .9rem;
    font-weight: 600;
    color: #1956b4;
    margin-bottom: 12px;
    font-family: 'Poppins',sans-serif;
}
.APIs_des__3a1vx {
    font-size: .8rem;
    white-space: pre-wrap;
    margin-bottom: 0;
    line-height: 16px;
    font-family: 'Poppins',sans-serif;
}
.APIs_line__1hcuI {
    width: 55px;
    height: 2px;
    background-color: rgba(34, 187, 102, 1);
    margin : auto;
    margin-bottom: 24px !important;
}
.APIs_line2__3GSOw{
    width: 73%;
    height: 2px;
    background-color: rgba(34, 187, 102, 1);
    margin: auto;
    margin-bottom: 2px;
}
.APIs_exploretext__Qe2AJ {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 12px;
}
.APIs_topsec__1sABd {
    margin: auto;
    width: 80%;
    text-align: center;
}
.APIs_line3__OZ8w_ {
    width: 94px;
    height: 5px;
    background-color: rgba(34, 187, 102, 1);
    margin: auto;
    margin-bottom: 80px;
}
.APIs_btnbox__1N_J5 {
    width: 13%;
    margin: auto;
}
.APIs_brbtn__g_9ey {
    margin-bottom: 3rem;
    transition: all .2s ease-in;
}
.APIs_brbtn__g_9ey {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    border: 2px solid #22bb66;
    padding: 15px 25px;
    background-color: white;
    border-radius: 8px;
    text-decoration: none !important;
}
.APIs_brbtn__g_9ey:hover {
    background-color: #22bb66;
}
.APIs_brbtn__g_9ey:hover.APIs_brbtn__g_9ey a {
    color: white;
}
.APIs_brbtn__g_9ey a {
    color: rgba(34, 187, 102, 1);
}
@media screen and (max-width:1292px) {
    .APIs_card__1tC2E {
        width: 22vw;
    }
}
@media screen and (max-width:1260px) {
    .APIs_card__1tC2E {
        width: 27vw;
    }
}
@media screen and (max-width:1170px) {
    .APIs_card__1tC2E {
        width: 25vw;
    }
}
@media screen and (max-width:1170px) {
    .APIs_card__1tC2E {
        height: 20vh;
    }
}
@media screen and (max-width:808px) {
    .APIs_card__1tC2E {
        width: 38vw;
    }
}
@media screen and (max-width:710px) {
    .APIs_card__1tC2E {
        width: 72vw;
    }
}
@media screen and (max-width:1390px) {
    .APIs_btnbox__1N_J5 {
        width: 20%;
    }
}
@media screen and (max-width:950px) {
    .APIs_btnbox__1N_J5 {
        width: 28%;
    }
}
@media screen and (max-width:720px) {
    .APIs_btnbox__1N_J5 {
        width: 45%;
    }
}
@media screen and (max-width:500px) {
    .APIs_btnbox__1N_J5 {
        width: 80%;
    }
}
.section13 .section-13 {
  background-color: #e9f8f0;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 574px) {
  .section13 .section-13 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section13 .section-13 {
  background-color: #e9f8f0;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 574px) {
  .section13 .section-13 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

#solutions_solutions__3dCml {
    overflow: hidden;
    width: 100%;
}
.solutions_my_flex__2B0HQ {
    display: flex;
    flex-wrap: wrap;
}
.solutions_my_width__3O-43 {
   width: -webkit-max-content;
   width: max-content;
   margin-right: 1rem;
   align-items: center !important;
}
.solutions_boldme__3SAZG {
    font-weight: bold;
}
.solutions_mar6__3lvag {
    margin-right: 6px;
}
.solutions_introbox__1VhzR {
    padding: 4rem;
    background-color: hsl(147, 69%, 43%);
    padding-top: 1rem;
}
.solutions_wrapper__2KuN8 {
    display:flex;
    justify-content: space-between;
    grid-gap: 2rem;
    gap: 2rem;
}
.solutions_left__m7ulY {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 4vw;
    justify-content: center;
}
.solutions_introimgmob__2PyNh {
    display: none;
}
.solutions_right__2zBzv {
    width: 44%;
    display: flex;
    justify-content: center;
}
.solutions_right__2zBzv img {
    /* height : 64vh; */
    width: 75%;
}
.solutions_p1__3pSNl {
    color: white;
    font-family: 'Poppins',sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 4rem;
    font-weight: 400;
}
.solutions_p2__3_ZJY {
    color: white;
    font-family: 'Poppins',sans-serif;
    font-size: 1.2rem;
    margin-bottom: 3rem;
}
.solutions_introbox__1VhzR button {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    color: hsl(147, 69%, 43%);
    font-weight: 700;
    font-size: 1.2rem;
    font-family: Poppins;
    width: 45%;
}
/* introbox ends  */

.solutions_productsol__3ds7F {
    padding: 4rem;
    width: 94%;
    margin: auto;
}
.solutions_productsol__3ds7F .solutions_head__3H41b {
    color: #5745c6;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.solutions_line3__nn-iJ {
    width: 138px;
    height: 5px;
    background-color: #22bb66;
    margin: auto;
    margin-bottom: 10px;
}
.solutions_box1__2vVFU {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0rem;
}
.solutions_leftbox__30Mky {
    display: flex;
    flex-direction: column;
    width: 65%;
    justify-content: center;
}
.solutions_pimgmob__1Euwe {
    display : none;
}
.solutions_boxp1__31kHA {
    color: #000;
    font-size: 1rem;
    font-family: 'Poppins',sans-serif;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 2rem;
}
.solutions_rightbox__2TxYD{
    width: 80%;
    padding-left: 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.solutions_pimg__2eevN {
    height: 90vh;
    margin-left: auto;
}
.solutions_pimg2__2q7lT {
    height: 80vh;
    width: 80%;
    margin-left: auto;
}
.solutions_in__19pT1 {
    display: flex;
    margin-bottom: 8px;
    align-items: flex-start;
}
.solutions_in__19pT1 p {
    line-height: 4vh;
    color: #000;
    font-size: 0.9rem;
    font-family: 'Poppins',sans-serif;
    line-height: 1.4;
}
.solutions_green_tick_used_for_listing_img__2KIej {
    height: 27px;
    width: 20px;
    margin-right: 5px;
}
.solutions_special_section___cO_l {
    padding-top: 5rem;
}
.solutions_sechead__1aigx,
.solutions_special_section___cO_l .solutions_head__3H41b {
    color: #000;
    font-size: 1.5rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.solutions_sechead__1aigx {
    margin-top: 4rem;
    color: #5745c6;
}
.solutions_ourjourney__3hAEv .solutions_subhead__pYYiq {
    width: 50%;
    margin: auto;
    font-size: 0.9rem !important;
    margin-bottom: 2rem !important;
}
.solutions_productsol__3ds7F .solutions_subhead__pYYiq {
    margin-bottom: 2rem !important;
    font-size: 1rem !important;
    margin-top: 2rem;
}
.solutions_ourjourney__3hAEv .solutions_subhead__pYYiq,
.solutions_productsol__3ds7F .solutions_subhead__pYYiq,
.solutions_special_section___cO_l .solutions_subhead__pYYiq {
    color: #000;
    font-size: 0.8rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0rem;
}
@media screen and (max-width:845px) {
  .solutions_special_section___cO_l {
      padding-top: 1.8rem;
  }
  .solutions_special_section___cO_l .solutions_subhead__pYYiq {
      margin-bottom: 2rem;
  }
}
@media screen and (max-width:992px) {
    .solutions_introbox__1VhzR button {
        width: 72%;
    }
}
@media screen and (max-width:845px) {
    .solutions_box1__2vVFU {
       flex-direction: column;
       padding-bottom: 0px;
    }
    .solutions_sechead__1aigx {
        margin-top: 0px;
        margin-bottom: 2rem;
    }
    .solutions_ourjourney__3hAEv .solutions_subhead__pYYiq {
        width: unset;
    }
    .solutions_pimg__2eevN {
        display: none;
    }
    .solutions_pimg2__2q7lT {
        display: none;
    }
    .solutions_rightbox__2TxYD {
        margin-bottom: 2rem;
    }
    .solutions_pimgmob__1Euwe {
        display: block;
        width: 65%;
        height: 500px;
        margin: auto;
    }
    .solutions_leftbox__30Mky {
        width: 100%;
    }
    .solutions_boxp1__31kHA {
        margin-bottom: 2rem;
    }
    .solutions_rightbox__2TxYD {
        width: 100%;
        padding-left: 0px;
    }
}
@media screen and (max-width:700px) {
    .solutions_wrapper__2KuN8 {
        flex-direction: column;
    }
    .solutions_introbox__1VhzR {
        padding-top: 3rem;
        padding-bottom: 0rem;
    }
    .solutions_introimg__13BPa {
        display: none;
    }
    .solutions_introimgmob__2PyNh {
        display: block;
    }
    .solutions_left__m7ulY {
        width: 100%;
        margin-bottom: 0rem;
    }
    .solutions_p1__3pSNl {
         margin-bottom: 0rem;
         font-size: 1.5rem;
         line-height: 2rem;
    }
    .solutions_introbox__1VhzR button {
        width: 40%
    }
    .solutions_right__2zBzv {
        width: 100%;
    }
    .solutions_p2__3_ZJY {
        font-size: 0.9rem;
        margin-bottom: 2rem;
    }
    .solutions_pimgmob__1Euwe {
        display: block;
        width: 65%;
        height: 400px;
        margin: auto;
    }
}
@media screen and (max-width:400px) {
   .solutions_introimgmob__2PyNh {
       height: 45vh;
   }
}
@media screen and (max-width:580px) {
    .solutions_pimgmob__1Euwe {
        display: block;
        width: 65%;
        height: 280px;
        margin: auto;
    }
}
@media screen and (max-width:380px) {
    .solutions_pimgmob__1Euwe {
        display: block;
        width: 90%;
        height: 280px;
        margin: auto;
    }
}
@media screen and (max-width:600px) {
    .solutions_introbox__1VhzR button {
        width: 55%;
    }
}
@media screen and (max-width:475px) {
    .solutions_introbox__1VhzR button {
        width: 100%;
    }
    .solutions_productsol__3ds7F {
        padding: 2rem;
    }
    .solutions_productsol__3ds7F .solutions_head__3H41b {
        font-size: 1rem;
    }
    .solutions_line3__nn-iJ {
        margin-bottom: 25px;
    }
    .solutions_boxp1__31kHA {
        font-size: 1rem;
    }
}

.solutions_impactper__62OaI {
    background-color: hsl(147, 69%, 43%);
    padding: 4rem;
    display: flex;
    flex-direction: column;
}
.solutions_ip__22NzM{
    color: white;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.solutions_cardbox__1WEzQ {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
}
.solutions_card__3qsNL {
    background-color: white;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 28vh;
    width: 32%;
    opacity: 0.8;
    padding: 2rem;
}
.solutions_line4__2j-80{
    width: 138px;
    height: 5px;
    background-color: white;
    margin: auto;
    margin-bottom: 80px;
}
.solutions_card__3qsNL p {
    color: #050589;
    font-size: 3rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
}
.solutions_card__3qsNL span {
    font-size: 1rem;
    font-family: 'Poppins';
    text-align: center;
}
.solutions_ourjourney__3hAEv {
    padding: 4rem 4rem 0rem 4rem;
}
.solutions_row__1UEAo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.solutions_ourjourney__3hAEv h1 {
    text-align: center;
    margin-bottom: 2rem;
}
.solutions_ourjourney__3hAEv .solutions_leftmd__2T_1R {
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    padding-top: 10px;
}
.solutions_journeyrow__1OPEZ {
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 2px dashed #c4c4c4;
    padding: 40px 0px 40px 0; /*padding: 40px 65px 40px 0*/
}
  
.solutions_journeyrow__1OPEZ:not(:first-of-type){
    padding: 40px 0px; /*padding: 40px 65px*/
}
  
.solutions_journeyrow__1OPEZ .solutions_year__1hF9J {
    /* width: 130px; */
    position: relative;
    margin-bottom: 16px;
}
  
.solutions_journeyrow__1OPEZ .solutions_year__1hF9J::before{
    content: "";
    width: 20px;
    height: 20px;
    background: #22bb66;
    border-radius: 5px;
    transform: rotate(45deg);
    position: absolute;
    top: -52px;
}
.solutions_head2__jeg22 {
    color: #5745c6;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.solutions_journeyrow__1OPEZ .solutions_year__1hF9J h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: #050589;
}
  
.solutions_journeyrow__1OPEZ .solutions_journey-image___45CR{
    max-width: 370px;
    max-height: 230px;
}
.solutions_journeyrow__1OPEZ .solutions_desc__1sweY {
    position: relative;
    flex: 1 1;
    margin-bottom: 66px;
    max-width: 345px;
}
.solutions_journeyrow__1OPEZ .solutions_desc__1sweY p {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(71, 74, 84, 1);
}
@media screen and (max-width: 1023px) {
    .solutions_ourjourney__3hAEv{
      padding: 100px 0 100px 20px ;
    }
    .solutions_ourjourney__3hAEv h1 {
      font-size: 30px;
      line-height: 35px;
    }
   .solutions_journeyrow__1OPEZ .solutions_year__1hF9J h6 {
      font-size: 17px;
      line-height: 20px;
    }
    .solutions_journeyrow__1OPEZ .solutions_desc__1sweY p,
    .solutions_our-customer__3unTZ .solutions_left-md__2tt01 p {
      font-size: 13px;
      line-height: 18px;
    }
    .solutions_ourjourney__3hAEv .solutions_row__1UEAo{
      justify-content: center;
    }
    .solutions_ourjourney__3hAEv .solutions_left-md__2tt01{
      flex-direction: column;
      grid-gap: 0;
      gap: 0;
    }
    .solutions_journeyrow__1OPEZ{
      padding: 0 20px 40px;
      border-top: none;
      border-left: 2px dashed #c4c4c4;
    }
  
    .solutions_journeyrow__1OPEZ:not(:first-of-type){
      /* display: none; */
      padding: 40px 20px;
    }
  
    .solutions_journeyrow__1OPEZ .solutions_year__1hF9J::before{
      top: 0;
      left: -30px;
    }
}

@media screen and (max-width: 767px) {
    .solutions_ourjourney__3hAEv {
      padding: 60px 30px 60px 30px;
      position: relative;
      flex-direction: column;
    }
    .solutions_ourjourney__3hAEv {
      overflow: hidden;
    }
    .solutions_ourjourney__3hAEv .solutions_leftmd__2T_1R {
      width: 100%;
      flex-direction: column;
      grid-gap: 0;
      gap: 0;
      padding: 2rem;
    }
    .solutions_ourjourney__3hAEv .solutions_right-md__3A_2K {
      width: 70%;
      margin: auto;
    }
    .solutions_journeyrow__1OPEZ{
      padding: 0 20px 40px;
      border-top: none;
      border-left: 2px dashed #c4c4c4;
    }
  
    .solutions_journeyrow__1OPEZ:not(:first-of-type){
      /* display: none; */
      padding: 40px 20px;
    }
  
    .solutions_journeyrow__1OPEZ .solutions_year__1hF9J::before{
      top: 0;
      left: -30px;
    }
    
    .solutions_journeyrow__1OPEZ .solutions_desc__1sweY{
      margin-bottom: 24px;
      
    }
    .solutions_journeyrow__1OPEZ .solutions_desc__1sweY p {
      font-size: 16px;
      line-height: 20.8px;
    }
}
@media screen and (max-width: 479px) {
  .solutions_ourjourney__3hAEv h1 {
      font-size: 25px;
      line-height: 30px;
    }
    .solutions_ourjourney__3hAEv .solutions_leftmd__2T_1R {
        flex-direction: column;
        grid-gap: 3px;
        gap: 3px;
    }
    .solutions_journeyrow__1OPEZ .solutions_year__1hF9J h6 {
      font-size: 18px;
      line-height: 24px;
    }
}
@media screen and (max-width:475px) {
    .solutions_ip__22NzM {
        font-size: 1rem;
    }
    .solutions_line4__2j-80 {
        width: 66px;
        height: 2px;
        margin-bottom: 25px;
    }
    .solutions_head2__jeg22 {
        font-size: 1rem;
    }
    .solutions_line3__nn-iJ {
        width: 75px;
        height: 3px;
    }
}
@media screen and (max-width: 790px) {
  .solutions_card__3qsNL {
      height: 38vh;
  }
}
@media screen and (max-width: 535px) {
    .solutions_cardbox__1WEzQ{
       flex-direction: column;
    }
    .solutions_card__3qsNL {
        width: 100%;
        margin-bottom: 2rem;
    }
}
@media screen and (max-width: 1072px) {
    .solutions_left__m7ulY {
        padding-left: 0px;
    }
}

.solutions_cardbox2__3gsVj {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
}
.solutions_card2__3Bw3m {
    background-color: white;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 8%);
    border-radius: 20px;
    box-sizing: border-box;
    border: none;
    display: flex;
    flex-direction: column;
    width: 32%;
    opacity: 1;
    padding: 2rem;
}
.solutions_impactper2__3zXzD {
    background-color: #fbf2ee;
    padding: 4rem;
    display: flex;
    flex-direction: column;
}
.solutions_plogoimg__3sFBk {
    height: 5rem;
    width: 12rem;
}
.solutions_card2p1__M700D {
    font-family: 'Poppins',sans-serif;
    font-size: 1.2rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 1rem;
    height: 4rem;
    width: 88%;
}
.solutions_card2p2__1OQwZ {
    color: #050589;
    font-size: 3rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    margin-bottom: 1rem;
}
.solutions_pboximg__272Ff {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
} 
.solutions_fdp__2a-Bb {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}
.solutions_pbox__1x6xi {
    display: flex;
    flex-direction: column;
}
.solutions_fdptext__1wlt1 {
    margin-bottom: 2rem;
}
.solutions_pbox__1x6xi span {
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
    color: #000;
}
@media screen and (max-width: 885px) {
   .solutions_cardbox2__3gsVj{
       flex-direction: column;
       grid-gap: 20px;
       gap: 20px;
   }
   .solutions_card2__3Bw3m {
       width: 100%;
   }
   .solutions_card2p1__M700D {
       height: unset;
   }

}

.solutions_resources__2GdN4 {
    background: linear-gradient(180deg,rgba(239,254,180,0.4) 0%,rgba(148,233,148,0.4) 100%);
    padding: 4rem;
    display: flex;
    flex-direction: column;
    border-top: 2px solid #22bb66;
}
.solutions_cardboxr__zQb7z {
    display: flex;
    /* justify-content: space-between; */
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
}
.solutions_card3__2J11e {
    background-color: white;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 8%);
    border-radius: 20px;
    box-sizing: border-box;
    border: none;
    display: flex;
    flex-direction: column;
    width: 32%;
    opacity: 1;
    padding: 2rem;
}
.solutions_blogimg__dBZT5 {
    width: 100%;
    background-size: cover;
    background-position: center;
}
.solutions_tag__aDm4M {
    display: inline-block;
    font-size: 0.8rem;
    color: #fff;
    background-color: #5745c6;
    border-radius: 7px;
    padding: 5px 10px;
}
.solutions_card3textdes__356AC {
    font-family: 'Poppins',sans-serif;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 30px;
}
.solutions_btn__3Ww0a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-weight: 600;
    font-family: Asap;
    transition-property: background-color, color;
    transition: 300ms ease-in-out;
    text-decoration: none;
    cursor: pointer;
    border: none;
    outline: none;
    color: #22bb66;
    font-size: 16px;
    background-color: transparent;
}
.solutions_ip2__PolMT {
    color: #000;
    font-size: 2rem;
    font-family: 'Poppins',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.8rem;
}
.solutions_line5__1oumf {
    width: 65px;
    height: 5px;
    background-color: #22bb66;
    margin: auto;
    margin-bottom: 80px;
}
.solutions_card3info__rrMdX {
    padding-top: 1rem;
}
@media screen and (max-width: 885px) {
    .solutions_cardboxr__zQb7z{
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }
    .solutions_card3__2J11e {
        width: 100%;
    }
}
/* import Fonts */ /* Mulish */ /* Asap */

/* default styles */
html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  font-family: "Mulish", sans-serif;
}
@media screen and (min-width: 1240px){
  .generalmargintop {
    margin-top: 100px;
  }
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: inherit;
  box-sizing: inherit;
}

button {
  cursor: pointer;
}

/* variables */
:root {
  /* breakpoints */
  --xs: 360px;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;

  /* gray */
  --gray-100: #ffffff;
  --gray-200: #f9fafb;
  --gray-300: #f2f2f2;
  --gray-400: #adb6bd;
  --gray-500: #474a54;
  --gray-700: #050302;

  /* primary */
  --primary: hsl(147, 69%, 43%);
  --primary-vl: hsl(146, 53%, 94%);
  --primary-dk: hsl(147, 100%, 23%);

  /* accent */
  --accent: hsl(230, 37%, 97%);

  --transparent: transparent;

  /* gradient */
  --gradient-dark: linear-gradient(#00b59c, #9cffac);
  --gradient-light: linear-gradient(#c3ffe8, #effff4);
  --gradient-nav-btn-green: hsl(147, 69%, 43%, 0.8);

  /* text colors */
  --text-primary: #22b966;
  --text-light: hsl(0, 0%, 100%);
  --text-lightGray: hsl(210, 9%, 31%);
  --text-dark: hsl(20, 43%, 1%);

  /* gray colors */
  --gray-1: hsl(0, 0%, 100%);
  --gray-3: hsl(210, 17%, 98%);
  --gray-4: hsl(208, 11%, 71%);
  --gray-5: hsl(210, 9%, 31%);
  --gray-9: hsl(218, 11%, 15%);

  /* bg colors */
  --vl-blue: hsl(230, 37%, 97%);
}

/* component classes */
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: Asap;
  transition-property: background-color, color;
  transition: 300ms ease-in-out;
  text-decoration: none;
  cursor: pointer;
}

.btn--bg {
  padding: 16px 40px;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 125%;
}

.btn--sm {
  width: 8.75rem;
  height: 2.5rem;
  border-radius: 0.4rem;
  font-size: 0.9rem;
  line-height: 111%;
}

.btn--light {
  background-color: var(--gray-100);
  color: var(--text-primary);
}

.btn--toggle:hover {
  background-color: var(--primary);
  color: white;
}

.btn--dark {
  background-color: var(--primary);
  color: var(--text-light);
}

.btn--toggle {
  border: 0.125rem solid var(--primary);
  color: var(--text-primary);
}

.hr-underline {
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height: 2px;
  border-radius: 5px;
}

@media screen and (min-width: 700px) {
  .hr-underline {
    width: 50px;
  }
}

/* helper classes */
.wrapper {
  position: relative;
  /* max-width: 1260px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* padding-left: 30px; */
  /* padding-right: 30px; */
}

.fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.none {
  display: none !important;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-jc-c {
  justify-content: center;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-ai-c {
  align-items: center;
}

.details {
    font-size: 20px;
    line-height: 1.8;
    padding: 100px 0;
}
.details .wrapper{
  padding: 0 135px;
}

.details-img {
    border-radius: 10px;
    max-width: 100%;
}
.details-info > *{
    margin: 30px 0;
}
.details .heading-primary {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 30px;
}

.details-info {
    text-align: left;
    width: 60%;
    margin: 50px auto;
}

.list-item {
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 0 20px;
    line-height: 1.8;
}
.bullet {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    margin-right: 5px;
    text-align: center;
}
.green {
    color: var(--color-green);
}
.underline {
    text-decoration: underline;
}
.text-center {
    text-align: center;
}
.details hr {
    width: 100%;
    background-color: var(--color-grey-light);
    height: 1px;
    margin: 30px 0;
}

@media (max-width: 1100px) {
    .details > * {
        margin: 50px auto 0 auto;
    }
    .details .heading-primary {
        font-size: 30px;
    }
    .details p, .list-item {
        font-size: 18px;
    }
    .details-info {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .details-info {
        width: 90%;
    }
}

@media screen and (max-width: 750px) {
  .details{
    padding: 30px 30px;
  }

  .details .wrapper{
    padding: 0;
  }
}

@media (max-width: 500px) {
    /* .details > * {
        margin: 30px auto 0 auto;
    } */
    .details-info {
        width: 100%;
        margin: 0 auto;
    }
    .details .heading-primary {
        font-size: 24px;
    }
    .details .heading-tertiary {
        font-size: 20px;
    }
    .details p {
        font-size: 16px;
    }
    .details-info {
        width: 100%;
    }
    .list-item {
        align-items: baseline;
        font-size: 16px;
        padding: 0 5px;
    }
}

.input-form {
    position: relative;
}
.input {
    border-radius: 10px;
    background-color: #fff;
    padding: 15px 20px;
    font-size: 18px;
    width: 100%;
    border: 2px solid #eee;
}
.input-form svg {
    position: absolute;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}
.input:focus {
    border: 2px solid var(--primary);
}


@media (max-width: 500px) {
    .input {
        padding: 10px;
        font-size: 16px;
    }
}
.comment {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 20px;
    padding: 25px 0;
}
.comment:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.comment--user__img {
    width: 60px;
    height: 60px;
    background-color: var(--color-grey-light);
    border-radius: 50%;
}

.comment--user__name {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}
.comment--date {
    font-size: 16px;
    font-weight: 400;
    color: #999;
    margin: 0 0 10px 0;
}
.comment--text {
    font-size: 18px;
    color: var(--color-grey);
}

@media (max-width: 500px) {
    .comment--user__img {
        width: 50px;
        height: 50px;
    }
}
.blogscontainer {
    padding-top: 2rem;
}
.blogscontainer .exl {
    font-family: 'Poppins',sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
    text-align: center;
}
.main2 {
    padding: 80px 80px 0px 80px;
}
.blogbox {
    padding: 0px 80px 40px 80px;
    background-color: rgba(34, 187, 102, 0.1);
}
.line3 {
    width: 80px;
    height: 5px;
    background-color: #22bb66;
    margin: auto;
    margin-bottom: 40px;
}
.sliderboxr {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
/* carousel */
#resource-section__carouselr {
    display: block;
  }
  
  .resource-section__carousel__containerr > * {
    margin-right: 30px;
  }
  
  .resource-section__carousel__prev-controlr,
  .resource-section__carousel__next-controlr {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
  }
  
  .resource-section__carousel__prev-controlr {
    left: 0;
  }
  
  .resource-section__carousel__next-controlr {
    right: 0;
  }
@media (max-width: 992px) {
    .resource-section__carousel__prev-controlr,
    .resource-section__carousel__next-controlr {
        position: absolute;
        width: 60px;
        height: 238px;
        top: -234px;
        z-index: 8;
    }
}
@media (max-width: 1500px) {
    .main2{
        padding: 50px;
    }
    .blogbox {
        padding: 0px 50px 40px 50px;
    }
}
@media (max-width: 1300px) {
    .main2{
        padding: 100px 80px;
    }
    .blogbox {
        padding: 0px 80px 40px 80px;
    }
}
@media (max-width: 1100px) {
    .main2{
        padding: 70px 40px;
    }
    .blogbox {
        padding: 0px 40px 40px 40px;
    }
}

@media (max-width: 1000px) {
    .main2{
        padding: 50px;
    }
    .blogbox {
        padding: 0px 30px 40px 30px;
    }
}
@media (max-width: 750px) {
    .main2{
        padding: 20px 50px;
    }
   
}
@media (max-width: 306px) {
    .main2 {
        padding: 20px 30px;
    }
   
}
.PageNotFound_box__3CnWu {
    width: 60%;
    margin: auto;
    margin-top: 10rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    padding-bottom: 4rem;
    align-items: center;
}
.PageNotFound_box__3CnWu img {
    width: 100%;
}
h1 {
    font-size: 1.5rem;
}
.PageNotFound_box__3CnWu button {
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 1rem;
    line-height: 125%;
    height: 52px;
    background-color: hsl(147, 69%, 43%);
    color: white;
}
.PageNotFound_box__3CnWu button a {
    color: white;
}
@media screen and (max-width: 465px) {
   .PageNotFound_box__3CnWu {
       margin-top: 5rem;
       width: 75%;
    }
    h1 {
        font-size: 1rem;
    }
}

