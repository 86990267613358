.carousel-1 {
    position: relative;
    overflow: hidden;
}

.carousel-1__container {
    position: relative;
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
}

.carousel-1__container > * {
    flex: none;
    margin-right: 30px;
}

.carousel-1__container > .card:first-of-type {
    margin-left: 20px;
}

.carousel-1__container .card__img {
    height: 100%;
    max-height: 290px;
}

.carousel-1__container .card {
    height: 100%;
    max-height: 290px;
}

.carousel-1__container .card:hover {
    height: 100%;
    max-height: 290px;
}

.carousel-1__control-prev,
.carousel-1__control-next {
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.carousel-1__control-prev::after,
.carousel-1__control-next::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
}

.carousel-1__control-prev {
    left: 0;
    padding-left: 6px;
    justify-content: flex-start;
    /*background-image: linear-gradient(to left, transparent, var(--primary) 50%);*/
}

.carousel-1__control-prev::after {
    background-image: url("../../assets/vector/white-arrow-left.svg");
}

.carousel-1__control-next {
    right: 0;
    padding-right: 6px;
    justify-content: flex-end;
    /*background-image: linear-gradient(to right, transparent, var(--primary) 50%);*/
}

.carousel-1__control-next::after {
    background-image: url("../../assets/vector/white-arrow-right.svg");
}

.carousel-1__control-prev.greenicon::after {
    background-image: url("../../assets/vector/green-chevron-left.svg");
}

.backgroundnone {
    background: none !important;
}

.carousel-1__control-next.greenicon::after {
    background-image: url("../../assets/vector/green-chevron-right.svg") !important;
}

.margin-left-none {
    margin-left: 0px !important;
}

.prod-extra-pad {
    padding-bottom: 10px;
}

.prod-btn-nav {
    background: linear-gradient(270deg, #FFFFFF 22.21%, rgba(255, 255, 255, 0) 95.32%);
    /* padding-left: 20px; */
}

.carousel-1_dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 32px;
}

@media screen and (min-width: 576px) {
    .carousel-1 {
        margin-left: 20px;
    }

    .prod-btn-nav {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .carousel-1 {
        margin-left: 0;
        padding: 0 0 0 80px;
    }

    .carousel-1__container > .card:first-of-type {
        margin-left: 0;
    }

    .carousel-1__control-prev,
    .carousel-1__control-next {
        width: 140px;
    }

    .carousel-1__control-next {
        padding-right: 20px;
        /*background-image: linear-gradient(*/
        /*  to right,*/
        /*  transparent,*/
        /*  hsl(147, 69%, 43%, 0.8) 50%*/
        /*);*/
    }

    .carousel-1__control-prev {
        padding-left: 20px;
        /*background-image: linear-gradient(*/
        /*  to left,*/
        /*  transparent,*/
        /*  hsl(147, 69%, 43%, 0.8) 40%*/
        /*);*/
    }

    .vid-carousel-btns {
        display: none;
    }

    .carousel-1_dots {
        margin-top: 40px;
    }
}
